import { ICountry, IRoles } from "../Interfaces/IConstants";
import { IDiscountCoupon, IOnlineSubscriptionDetails, ISubscriptionDetails, ISubscriptionPlan, IUpdateAddOnPack, IrenewSubscriptionInfo } from "../Interfaces/ISubscription";
import { IUser } from "../Interfaces/IUser";

export const roles: IRoles[] = [
  { value: '1', label: "Consultant" },
  { value: '2', label: "Admin" },
  { value: '3', label: "Cadis Admin" },
]

export const languages: IRoles[] = [
  { value: "en", label: "English" },
  { value: "es", label: "Spanish" }
]

export const selectedRole: string | number = roles[0].value;
export const maxPhoneLength: number = 10;
export const minPhoneLength: number = 8;
export const maxOrgNameLength: number = 100;
export const numberMaxTenMinOneRegex = /^\D*(\d\D*){1,10}$/;
export const otpLengthExp = /^[0-9]{6}$/;
export const UserTitles = ["None", "Dr.", "NP", "PA", "Mr.", "Ms.", "Nurse", "Prof."]
export const minPasswordLength: number = 8;
export const numberAndDashRegex = /^[0-9-]*$/;
export const numberRegex = /^[0-9.]*$/;
export const allowedMinMaxPhoneLengthRegex = /^[0-9]{8,10}$/;

export const countries: ICountry[] = [
  { code: 'USA', name: 'United States of America' },
  { code: 'AUS', name: 'Australia' },
  { code: 'CAN', name: 'Canada' },
  { code: 'CHN', name: 'China' },
  { code: 'CUB', name: 'Cuba' },
  { code: 'DEU', name: 'Germany' },
  { code: 'EGY', name: 'Egypt' },
  { code: 'GBR', name: 'United Kingdom' },
  { code: 'HKG', name: 'Hong Kong' },
  { code: 'IN', name: 'India' },
  { code: 'IRN', name: 'Iran' },
  { code: 'ISL', name: 'Iceland' },
  { code: 'JPN', name: 'Japan' },
  { code: 'JEY', name: 'Jersey' },
  { code: 'MEX', name: 'Mexico' },
  { code: 'NLD', name: 'Netherlands' },
  { code: 'NOR', name: 'Norway' },
  { code: 'PHL', name: 'Philippines' },
  { code: 'UKR', name: 'Ukraine' },
  { code: 'OT', name: 'Other' }
]

export const NotifyExpiry = ['Select Days', '1', '2', '5', '7', '10', '12', '15', '20'];
export const paymentMode = ["Select Payment Mode", "Email", "SMS"];
export const paymentModeForAddon = ["Select Payment Mode", "Email", "SMS", "Promo"];
export const Users = ["All", "Consultants", "Assistants"];
export const FormViewMode = "view";
export const FormAddMode = "add";
export const FormUpdateMode = "update";
export const FormRenewMode = "Renew";
export const GridRowsPerPageOptions = [5, 10, 15, 20, 25];
export const DefaultGridRowSize = 15;
export const ActiveStatus = "Active";
export const ActiveStatusID = 1;
export const InActiveStatusID = 0;
export const InActiveStatus = "Disabled";
export const ButtonActionAdd = "Add";
export const ButtonActionEdit = "Edit";
export const ButtonEditReminderDate = "EditReminderDate";
export const ButtonActionDelete = "Delete";
export const ButtonBuyDataAddonList = "BuyDataAddonList";
export const ButtonActionView = "View";
export const ButtonActionList = "List";
export const ButtonActionShow = "Show";
export const ButtonActionSend = "Send";
export const ButtonActionCallSession = "CallSession";
export const ButtonActionSendQRCode = "SendQRCode";
export const ButtonActionInitiateCall = "InitiateCall";
export const ButtonReactivate = "Reactivate";
export const ButtonActionRenew = "Renew";
export const ButtonActionAddonPack = "Addonpack";
export const ButtonSubscriptionDetails = "SubscriptionDetails";
export const ExpiredStatus = "Expired";
export const ButtonActionSelectPlan = "SelectPlan";
export const ButtonActionCopy = 'Copy';
export const ButtonShowRenewPlanDetails = "ShowRenewPlanDetails"

export const DefaultSubscriptionPlan: ISubscriptionPlan = {
  'sub_PlanId': 0,
  'sub_name': "",
  'sub_billing_cycle': "",
  'sub_min_user_bundle_count': 4,
  'sub_one_month_price': '',
  'sub_total_price': '',
  'sub_remind_before': NotifyExpiry[0],
  'data_limit': "",
  'is_data_unlimited': false,
  "is_plan_disable": false,
  "total_purchases": 0,
  "total_org_count": 0
}

export const DefaultCadisAdmin: IUser = {
  'org_id': 0,
  'org_name': "",
  'user_id': 0,
  'org_admin_title': UserTitles[0],
  'org_admin_fname': "",
  'org_admin_lname': "",
  'org_admin_email': "",
  'org_admin_username': "",
  'org_admin_phone_m': "",
  'org_admin_phone_nm': "",
  'org_admin_status': true,
  'orgAdminSendDetailsInEmail': true,
  'specialities': [],
  'profile_image': "",
  "country_code": countries[0].code,
  "set_password_url": "",
  "assign_role_to_user": false,
  "newAssignedRole": 0,
  "isPasswordSet": false
}

export const DefaultSubscriptionDetails: ISubscriptionDetails = {
  'user_count': 0,
  'org_id': 0,
  'plan_id': 0,
  'subscription_id': 0,
  'subscription_count': '',
  'is_disabled': false,
  'paid_amount': 0,
  'payment_mode': paymentMode[0],
  'send_invoice_flag': false,
  'start_date': '',
  'active_user_count': 0,
  'data_limit': 0,
  'is_data_unlimited': false,
  'isPlanExpired': false,
  'totalDataOver': 0,
  'billing_cycle': "",
  'plan_data_limit': ''
}

export const DefaultCouponCodeDetails: IDiscountCoupon = {
  'discount_id': 0,
  'discount_code': '',
  'discount_percentage': ''
}
export const DefaultAddOnPackDetails: IUpdateAddOnPack = {
  addon_price: "",
  addon_data: "",
  addon_id: ''
}

//Default values for Online Subscription
export const DefaultOnlineSubscriptionDetails: IOnlineSubscriptionDetails = {
  'userName': '',
  'userId': 0,
  'userEmail': '',
  'orgId': 0,
  'planId': 0,
  'planName': '',
  'planAmount': 0,
  'noOfSubscription': 0,
  'noOfUsers': 0,
  'successUrl': '',
  'cancelUrl': '',
  'subscriptionId': 0,
  'dataLimit': 0,
  'isDataUnlimited': false,
  'isPlanExpired': false,
  'totalRolloverData': 0
}
//Default values for Online Subscription
export const DefaultRenewSubscriptionDetails: IrenewSubscriptionInfo = {
  planName: '',
  startDate: '',
  endDate: '',
  totalData: 0,
  paymentStatus: ''
}

//route links
export const navigateToLogin = "/login";
export const navigateToLoginOtp = "/LoginOtp"
export const navigateToOrganizations = "/dashboard/Organizations";
export const navigateToCadisAdmin = "/dashboard/cadisAdmin";
export const navigateToOrgAdmins = "/dashboard/orgAdmins";
export const navigateToConsultants = "/dashboard/consultants";
export const navigateToAssistant = "/dashboard/assistants"
export const navigateToCallSessions = "/dashboard/callSessions";
export const navigateToDeletedOrgs = "/dashboard/deletedOrganizations";
export const navigateToDeletedUser = "/dashboard/deletedUserList";
export const navigateToSubscriptionPlans = "/dashboard/subscriptionPlans";
export const navigateToSubscriptionDetails = "/dashboard/subscriptionDetails";
export const navigateToMyPlans = "/dashboard/myPlans";
export const navigateToManageUsers = "/dashboard/manageUsers";
export const navigateToDiscountCouponCode = "/dashboard/CouponCode";
export const navigateToPurchasedPlansMangeUser = "/dashboard/myPlans/manageUsers";
export const navigateToDataAddOnList = "/dashboard/DataAddOnsList";

export const cadisAdmin = "cadisAdmin";
export const orgAdmins = "orgAdmins";
export const consultants = "consultants";
export const assistants = "assistants";

export const DefaultAssistant: IUser = {
  'org_id': 0,
  'org_name': "",
  'user_id': 0,
  'org_admin_title': UserTitles[0],
  'org_admin_fname': "",
  'org_admin_lname': "",
  'org_admin_email': "",
  'org_admin_username': "",
  'org_admin_phone_m': "",
  'org_admin_phone_nm': "",
  'org_admin_status': true,
  'orgAdminSendDetailsInEmail': true,
  'specialities': [],
  'profile_image': "",
  "country_code": countries[0].code,
  "set_password_url": "",
  "assign_role_to_user": false,
  "newAssignedRole": 0,
  "isPasswordSet": false
}
//current host URl
export const currentHost = `${window.location.origin}`;

//#region VALIDATION MESSAGES
export const phoneNumberLengthValidationMessage = `Phone number should consist of minimum ${minPasswordLength} digits and maximum ${maxPhoneLength} digits.`;
export const phoneNumberStartEndHyphenValidationMessage = `Phone number cannot start and end with 'Hyphen(-)'`;
//#endregion

// socket EVENTS
export const PING = 'PING';
export const PONG = 'PONG';
export const CALL_TO_ASSISTANT = 'CALL';
export const SESSION_DETAILS = 'SESSION_DETAILS';
export const CALL_REJECTED = 'CALL_REJECTED';
export const INCOMING_CALL = 'INCOMING_CALL';
export const ANSWER_SUCCESS = 'ANSWER_SUCCESS';
export const ANSWER_REJECT = 'ANSWER_REJECT';
export const MISSED_CALL = 'MISSED_CALL';
export const ROOM_CREATED = 'ROOM_CREATED';
export const ROOM_DESTROYED = 'ROOM_DESTROYED';
export const DRAW_FREEHAND = 'DRAW_FREEHAND';
export const DRAW_TEXT = 'DRAW_TEXT';
export const DRAW_OBJECT = 'DRAW_OBJECT';
export const REMOVE_OBJECT = 'REMOVE_OBJECT';
export const CLEAR_SCREEN = 'CLEAR_SCREEN';
export const RECORDING_STARTED = 'RECORDING_STARTED';
export const RECORDING_ENDED = 'RECORDING_ENDED';
export const END_CALL = 'END_CALL';
export const USER_STATUS = 'USER_STATUS';
export const RATE_CALL = 'RATE_CALL';
export const REMOVE_TEXT = 'REMOVE_TEXT';
export const CONNECT_ERROR = 'connect_error';
export const CONNECT = 'connect';
export const BUSY = 'BUSY';
export const CALL_USER_LEFT = 'CALL_USER_LEFT';
export const REJOIN_ROOM = 'REJOIN_ROOM';
export const RECONNECT = 'RECONNECT';
export const CONFERENCE_CALL = 'CONFERENCE_CALL';
export const PRIVATE_ROOM_CREATED = 'PRIVATE_ROOM_CREATED';
export const WEB_AST_CALL_INITIATION = 'WEB_AST_CALL_INITIATION';
export const JOIN_ROOM = 'JOIN_ROOM';
export const LEAVE_CALL = 'LEAVE_CALL';
export const WEB_AST_LEAVE_CALL = "WEB_AST_LEAVE_CALL";
export const CONSULTANT_END_CALL = "CONSULTANT_END_CALL";
export const ADD_ROKID_USER = "ADD_ROKID_USER";
export const OFFLINE = "OFFLINE";
export const USER_MUTE = "USER_MUTE";
export const USER_UNMUTE = "USER_UNMUTE";
export const SET_WEB_AST_NAME = "SET_WEB_AST_NAME";
export const AST_LEAVE_CONFERENCE_CALL = "AST_LEAVE_CONFERENCE_CALL";
export const JOIN_ALREADY_ENDED_ROOM = "JOIN_ALREADY_ENDED_ROOM";
export const JOINED_ROOM = "JOINED_ROOM";
export const INVITATION_SENT = "INVITATION_SENT";
export const CONFERENCE_CALL_REJECTED = "CONFERENCE_CALL_REJECTED";
export const USER_CON_MUTE = "USER_CON_MUTE";
export const USER_CON_UNMUTE = "USER_CON_UNMUTE";
export const WEB_ASSISTANT_MUTE = "WEB_ASSISTANT_MUTE";
export const WEB_ASSISTANT_UNMUTE = "WEB_ASSISTANT_UNMUTE";
export const ON_AUDIO_TO_WEB_AST = "ON_AUDIO_TO_WEB_AST";
export const OFF_AUDIO_TO_WEB_AST = "OFF_AUDIO_TO_WEB_AST";
export const ON_OFF_AUDIO = "ON_OFF_AUDIO";
export const WAST_CALL_TRANSFERRED = "WAST_CALL_TRANSFERRED";
export const ROKID_CURRENT_RESOLUTION = "ROKID_CURRENT_RESOLUTION";
export const RESEND_ROKID_RESOLUTION = "RESEND_ROKID_RESOLUTION";
export const CALL_END_BY_ASSISTANT = "CALL_END_BY_ASSISTANT";
export const END_OUTGOING_CALL = "END_OUTGOING_CALL";
export const WAST_MAIN_VIEW = "WAST_MAIN_VIEW";
export const ROKID_MAIN_VIEW = "ROKID_MAIN_VIEW";
export const MUTE_YOURSELF = "MUTE_YOURSELF";
export const UNMUTE_YOURSELF = "UNMUTE_YOURSELF";

// PING INTERVAL
export const pingInterval = 2000;

// CALLERTONE TIMEOUT
export const ringtoneTimeout = 30000;
export const inactiveOrganization = 'Inactive organization. Please contact your org admin / CADIS for assistance.';
export const accessRestricted = 'Access restricted, Please contact your admin / CADIS for assistance.';

export const callStatusIncoming = "Incoming";
export const callStatusOutgoing = "Outgoing";
export const callStatusMissed = "Missed";
export const callSessionsDataExportFileName = "Call Sessions";
export const NoCallSessionsMessageConsultantGrid = "No Call logs for this Consultant as yet."
export const NoCallSessionsMessageAssistantGrid = "No Call logs for this Assistant as yet."

export const allowedProfileImgExtensions = ".png, .jpeg, .jpg";
export const profileImageFileSizeLimitInMB = 2 * 1000000;//(times bytes)

export const dummyCallSessionID = 1;
export const sessionWarningTimeInMins = process.env.REACT_APP_SESSION_WARNING_TIMEOUT_IN_MINS;
export const sessionTimeoutInMins = process.env.REACT_APP_SESSION_TIMEOUT_IN_MINS;
export const userCallStatusBusy = "busy";
export const userCallStatusOnline = "online";
export const userCallStatusOffline = "offline";
