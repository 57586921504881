import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";
import paymentCancle from '../../Assets/Images/payStatus/paymentCancle.gif';
import paymentSuccess from '../../Assets/Images/payStatus/paymentSuccess.gif';
import CadisButton from "../../Components/Input/CadisButton";
import { t } from "i18next";

interface PaymentStatusDialogProps {
  open: boolean;
  onClose: () => void;
  isPaymentSuccess: boolean;
}

const PaymentStatusDialog: React.FC<PaymentStatusDialogProps> = ({ open, onClose, isPaymentSuccess }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ color: isPaymentSuccess ? "#5DC983" : "#E9394F" }}
      >
        {isPaymentSuccess ? t("lbl_paymentSuccess") : t("lbl_paymentCancelled")}
      </DialogTitle>
      <DialogContent>
        <Box textAlign={'center'}>
          {isPaymentSuccess ? (
            <img src={paymentSuccess} height="80" alt={`${t("lbl_paymentSuccess")}`} />
          ) : (
            <img src={paymentCancle} height="80" alt={`${t("lbl_paymentCancelled")}`} />
          )}
        </Box>
        <DialogContentText id="alert-dialog-description" sx={{ color: "#222222" }}>
          {isPaymentSuccess
            ? `${t("lbl_paymentSuccessMessage")}`
            : `${t("lbl_paymentCancelledMessage")}`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CadisButton color="primary" variant="contained" onClick={onClose}>
          {t("lbl_ok")}
        </CadisButton>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentStatusDialog;