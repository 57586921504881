import { Avatar, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, ListItem, ListItemAvatar, ListItemText, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { auto } from '@popperjs/core';
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import { AllUserListByRoleRoute, DeleteUserRoute, GetSpecialityList, ResetPassword } from "../../Common/api.routes";
import {
  ActiveStatus, ActiveStatusID, ButtonActionAdd, ButtonActionCallSession, ButtonActionDelete, ButtonActionEdit,
  ButtonActionSend, ButtonReactivate,
  DefaultCadisAdmin, DefaultGridRowSize, FormAddMode,
  FormUpdateMode, FormViewMode, GridRowsPerPageOptions, InActiveStatus,
  InActiveStatusID,
  countries, currentHost,
  dummyCallSessionID,
  navigateToCallSessions
} from '../../Common/helper';
import {
  GetGridNoRecordMessage,
  GetNonDeletedUser, GetNonLoggedInUser,
  GetShortDateWithLuxon,
  GetTimeWithAMPMWithLuxon,
  checkUserCreationCount,
  formatPhoneNumber,
  isAuthenticated, searchFilterFunction,
  statusSortComparator,
  stringAvatar
} from "../../Common/helperFunction";
import { GridStyling } from "../../Common/sharedStyling";
import CadisButton from "../../Components/Input/CadisButton";
import BuildGridAction from "../../Components/Input/cadisBuildGridAction";
import CadisLoader from "../../Components/Loading/CadisLoader";
import apis from "../../HttpConfig/Api";
import { ISpeciality, ROLE, ROLENAMES } from "../../Interfaces/IConstants";
import { IConsultantList } from "../../Interfaces/IConsultantList";
import { IUser } from "../../Interfaces/IUser";
import { useSocketContext } from "../../context/SocketContext";
import { useUserRoleContext } from "../../context/UserRoleContext";
import AddUpdateOrganizationAdmin from "../OrganizationAdmin/AddUpdateOrganizationAdmin";

const ConsultantList = () => {
  const navigate = useNavigate();
  const { socketRef, openIncomingCallBox, setUserDetails, openReconnectDialog } = useSocketContext();
  const { t } = useTranslation();
  //Consultant List state
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState<number>(DefaultGridRowSize);
  const [consultantCreationMode, setconsultantCreationMode] = useState(FormViewMode);
  const [currentConsultantData, setConsultantData] = useState<IUser>(DefaultCadisAdmin);
  const [addConsultantDrawer, setAddConsultantDrawer] = useState<boolean>(false);
  const [consultantSpecialities, setConsultantSpecialities] = useState<ISpeciality[]>([]);
  const [consultantListFlow, setConsultantListFlow] = useState('consultantFlow');
  const { userLoginRole, handleToggleLoader } = useUserRoleContext();
  const [openSendEmailDialog, setOpenSendEmailDialog] = useState({ 'open': false, 'user_id': '' });
  const [localRole, setLocalRole] = useState(userLoginRole);
  const [searchQuery, setSearchQuery] = useState("");
  const [consultantListData, setConsultantListData] = useState<IConsultantList[]>([]);
  const [consultantListDataCopy, setConsultantListDataCopy] = useState([]);
  const [data, setData] = useState([]);
  const [showAllUserData, setShowAllUserData] = useState([]);
  const [openReactivateUserDialog, setOpenReactivateUserDialog] = useState({
    'open': false,
    'UserID': null,
    'UserName': ""
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState({
    'open': false,
    'UserID': null,
    'ConsultantName': ""
  });
  const user = isAuthenticated();
  const userRole = ROLENAMES.Consultant;

  useEffect(() => {
    const temp: any = Number(sessionStorage.getItem("role"));
    if (temp) {
      setLocalRole(temp);
    } else {
      setLocalRole(userLoginRole);
    }
  }, [userLoginRole])

  const settingData = (data: any) => {
    const sortedDataByFullnameThenByStatus = SortDataByIntialThenBySecondaryKeys(data, "user_fname", "user_status");
    setConsultantListData(sortedDataByFullnameThenByStatus);
    setConsultantListDataCopy(sortedDataByFullnameThenByStatus);
  }

  const SortDataByIntialThenBySecondaryKeys = (data: any[], initialSortKey: string, secondarySortKey: string) => {
    const sortedData: any = data.sort(function (a, b) {
      if (a.user_fname.toLowerCase() < b.user_fname.toLowerCase()) { return -1; }
      if (a.user_fname.toLowerCase() > b.user_fname.toLowerCase()) { return 1; }
      return 0;
    }).sort(function (x, y) {
      if (x.user_status > y.user_status) { return -1; }
      if (x.user_status < y.user_status) { return 1; }
      return 0;
    }).sort(function (p, q) {
      if (p.user_is_deleted < q.user_is_deleted) { return -1; }
      if (p.user_is_deleted > q.user_is_deleted) { return 1; }
      return 0;
    }).sort(function (m, n) {
      if (n.user_password === "false" && n.user_status === 0) { return 1; }
      if (m.user_password === "false" && m.user_status === 0) { return -1; }
      return 0;
    });

    return sortedData;
  };

  // dynamic scrollbar for grid view start
  const PageTitleRef: any = useRef();
  const tableGridRef: any = useRef();
  const [tableHeight, setTableHeight] = useState<any>(
  );

  const getPageTitleHeight = () => {
    let pageTitleHeight: any = 0;
    if (PageTitleRef && PageTitleRef.current) {
      pageTitleHeight = PageTitleRef.current.clientHeight;
    }
    setTableHeight(window.innerHeight - pageTitleHeight - 82);
  };

  window.addEventListener("resize", getPageTitleHeight);

  // dynamic scrollbar for grid view end

  const columns: GridColDef[] = [
    { field: 'user_id', width: 1, hide: true },
    { field: 'user_title', width: 1, hide: true },
    {
      field: "full_name", headerName: 'Name', cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header',
      minWidth: 220, flex: 1,
      renderCell: ({ row }: any) => {
        return <>
          <ListItem disablePadding>
            <ListItemAvatar>
              <Avatar {...stringAvatar(row.full_name)} src={row.user_profile_img} />

            </ListItemAvatar>
            <Tooltip title={`${row.full_name}`} >
              <ListItemText sx={{ textTransform: 'capitalize', }} className="long-col-name-ellipsis"
                primary={`${row.full_name}`}
                secondary=""
              /></Tooltip>
          </ListItem>
        </>
      },
      filterable: false,
    },
    {
      field: 'user_email', flex: 1, headerName: 'Email', minWidth: 300, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', sortable: false,
      renderCell: ({ row }: any) => (
        <Tooltip title={row.user_email} >
          <span className="table-cell-trucate">{row.user_email}</span>
        </Tooltip>
      ),
    },
    {
      field: 'phone_number_m', flex: 1, headerName: 'Phone', minWidth: 130, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', sortable: false,
      renderCell: ({ row }: any) => {
        const phoneNumber = row.phone_number_m;
        const phoneNumberTwo = row.phone_number_nm;
        return <Stack direction="column" textAlign={'left'}>
          <Box>{formatPhoneNumber(phoneNumber)}</Box>
          <Box>{formatPhoneNumber(phoneNumberTwo)}</Box>
        </Stack>
      }
    },
    {
      field: 'specialities', flex: 1, headerName: 'Specialty', minWidth: 144, cellClassName: 'app-theme-cell',
      headerClassName: 'app-theme-header',
      renderCell: (params) => {
        const isSpecialityAvailable = params.row.speciality && params.row.speciality.length > 0;
        return isSpecialityAvailable ? <Tooltip title={params.row.speciality[0].speciality} >
          <span className="table-cell-trucate hyphenate" lang="en">{params.row.speciality[0].speciality}</span>
        </Tooltip> : null
      },
      valueGetter: (p) => getFullName(p),
      sortComparator: (v1, v2) => v1.localeCompare(v2)
    },
    {
      field: 'lastCallTime', headerName: 'Last Call', cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', flex: 1, minWidth: 140,
      valueGetter: (params: GridValueGetterParams) => {
        const call = params.row.call
        // checking the call object is exist or not
        if (call) {
          const callDate = DateTime.fromISO(params.row.call?.date);
          const callTime = DateTime.fromISO(params.row.call?.lastCallStartTime);
          const duration = params.row.call.duration;
          return { callDate, callTime, duration };
        } else return ""
      },
      sortComparator: (v1: { callDate: DateTime; callTime: DateTime }, v2: { callDate: DateTime; callTime: DateTime }) => {
        if (!v1 || !v2) return 0; // Handle null values

        const dateTime1 = v1.callDate.set({
          hour: v1.callTime.hour,
          minute: v1.callTime.minute,
          second: v1.callTime.second,
        }).toMillis();

        const dateTime2 = v2.callDate.set({
          hour: v2.callTime.hour,
          minute: v2.callTime.minute,
          second: v2.callTime.second,
        }).toMillis();
        return dateTime1 - dateTime2;
      },
      renderCell: ({ row }: any) => {
        const { call } = row;
        let isLastCallMadeIsDummy = false;
        if (call) {
          isLastCallMadeIsDummy = call.is_conference_call === 0 ? call.call_assistant_id !== dummyCallSessionID : (
            call.call_assistant_id !== dummyCallSessionID
            || (call.call_webast_name !== null || call.call_webast_name !== undefined))
        }
        return isLastCallMadeIsDummy ? <ListItem disablePadding>
          <ListItemText sx={{ textTransform: 'capitalize', }}
            primary={call ? `${GetShortDateWithLuxon(call?.date) ?? null}` : null}
            secondary={call ? `${GetTimeWithAMPMWithLuxon(call?.lastCallStartTime)} (${call?.duration ?? null})` : null}
          />
        </ListItem> : ""
      }
    },
    {
      field: 'user_status', flex: 1, headerName: 'Status', cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', minWidth: 100, hide: localRole === ROLE.Consultant, renderCell: ({ row }: any) => {
        return <>
          <Chip size="small" label={row.status} color={row.user_password == "false" && row.user_status === 0 ? 'warning' : row.user_status === 1 ? 'success' : 'error'} />
        </>
      }, valueGetter: (params) => params.row.status, sortComparator: statusSortComparator
    },
    {
      field: 'actions', headerAlign: 'center',
      cellClassName: localRole === ROLE.Consultant ? 'app-theme-cell bg-action pl-0 pr-0' : ' app-theme-cell bg-action', headerClassName: localRole === ROLE.Consultant ? 'app-theme-header bg-action pl-0 pr-0' : ' app-theme-header bg-action',
      flex: 1,
      headerName: 'Actions', align: 'center',
      minWidth: localRole === ROLE.Consultant ? 70 : 228, sortable: false, hide: localRole === ROLE.Consultant, renderCell: (params: any) => {
        const isCallDetailsAvailable = params.row.call;
        let didConsultantCalledThisAssistant = true;
        if (isCallDetailsAvailable && localRole === ROLE.OrgAdmin) {
          didConsultantCalledThisAssistant = params.row.call.is_conference_call === 0 ? params.row.call.call_assistant_id === dummyCallSessionID : (
            params.row.call.call_assistant_id === dummyCallSessionID
            && (params.row.call.call_webast_name === null || params.row.call.call_webast_name === undefined))
        }
        return <Stack direction="row" spacing={0}>
          <BuildGridAction
            title={`${!didConsultantCalledThisAssistant ? t("lbl_viewCallSessions") : t("lbl_noCallSessions")}`}
            onClickAction={(e: any) => {
              if (!didConsultantCalledThisAssistant) {
                onClick(e, params)
              }
            }
            } actionParams={params}
            placement="left" color={`${!didConsultantCalledThisAssistant ? 'primary' : 'default'}`}
            ActionId={ButtonActionCallSession} >
            {<span className='cad-call-circle'></span>}
          </BuildGridAction>
          <Box className='grid-act-btn'>
            <Tooltip title={t("lbl_resendPasswordLink")} placement="bottom-end">
              <IconButton aria-label="OrgAdminIcon" id={ButtonActionSend} onClick={(e) => {
                onClick(e, params)
              }} color="primary">
                <span className='cad-send'></span>
              </IconButton>
            </Tooltip>
          </Box>
          {/* left empty box for adding space between edit and other left actions */}
          <Box className='grid-act-btn'></Box>
          <Box className='grid-act-btn'>
            <Tooltip title="Edit Consultant" placement="bottom-end">
              <IconButton aria-label="OrgAdminIcon"
                id={ButtonActionEdit} onClick={(e) => {
                  onClick(e, params)
                }} color="primary">
                <span className='cad-edit'></span>
              </IconButton>
            </Tooltip>
          </Box>
          <Box className='grid-act-btn'>
            {
              (
                params.row.user_status === InActiveStatusID &&
                params.row.user_id !== user.userId && params.row.user_password == "true"
              ) ?
                <Tooltip title={t('lbl_hideConsultant')} placement="bottom-end">
                  <IconButton aria-label="DeleteOrgIcon"
                    id={ButtonActionDelete} onClick={(e) => {
                      onClick(e, params)
                    }} color="error">
                    <span className='cad-archive'></span>
                  </IconButton>
                </Tooltip>
                : null
            }
          </Box>
        </Stack>
      }
    }
  ];
  function getFullName(params: any) {
    const isSpecialityAvailable = params.row.speciality && params.row.speciality.length > 0;
    return isSpecialityAvailable ? params.row.speciality[0].speciality : null
  }
  const onClick = (e: any, params: any) => {
    e.stopPropagation(); // don't select this row after 
    OnActionClick(e.currentTarget.id, params?.row);
  };
  //#region Action Buttons Click
  const OnActionClick = async (actionId: string, row: any) => {
    switch (actionId) {
      case ButtonActionAdd:
        const result = await checkUserCreationCount(user.orgId, localRole);
        if (result) {
          DefaultCadisAdmin.org_id = user.orgId;
          setconsultantCreationMode(FormAddMode);
          setConsultantData(DefaultCadisAdmin);
          setAddConsultantDrawer(true);
        }
        break;
      case ButtonActionEdit:
        setconsultantCreationMode(FormUpdateMode);
        const consultantToEdit = {
          'org_id': row.org_id,
          'org_name': "",
          'user_id': row.user_id,
          'org_admin_title': row.user_title,
          'org_admin_fname': row.user_fname ?? "",
          'org_admin_lname': row.user_lname ?? "",
          'org_admin_email': row.user_email ?? "",
          'org_admin_username': row.user_name ?? "",
          'org_admin_phone_m': row.phone_number_m,
          'org_admin_phone_nm': row.phone_number_nm ?? "",
          'org_admin_status': row.user_status,
          'orgAdminSendDetailsInEmail': true,
          'specialities': row.speciality,
          'profile_image': row.user_profile_img ?? "",
          "country_code": row.country_code ?? countries[0].code,
          "set_password_url": "",
          "assign_role_to_user": row.Roles.length > 1,
          "newAssignedRole": row.Roles.length > 1 ? row.Roles[1] : ROLE.OrgAdmin,
          "isPasswordSet": row.user_password === "true" ? true : false
        }
        setConsultantData(consultantToEdit);
        setAddConsultantDrawer(true);
        break;
      case ButtonActionDelete:
        setOpenDeleteDialog({
          'open': true,
          'UserID': row.user_id,
          'ConsultantName': row.user_fname + " " + row.user_lname
        });
        break;
      case ButtonActionCallSession:
        navigate(navigateToCallSessions, {
          state: {
            username: row.user_name,
            fullname: `${row.user_fname} ${row.user_lname ?? ''}`,
            showAssistantsCallSessions: true,
            userRoleID: ROLE.Consultant
          }
        });
        break;
      case ButtonActionSend:
        handleSendPasswordPopup(row.user_name);
        break;
      case ButtonReactivate:
        setOpenReactivateUserDialog({
          'open': true,
          'UserID': row.user_id,
          'UserName': row.user_fname??'' + " " + row.user_lname ?? ''
        });
        break;
      default:
        break;
    }
  }

  //#endregion
  const DeleteConsultant = async () => {
    const consultantData = {
      'userID': openDeleteDialog.UserID
    }
    try {
      await apis.instance.put(`${DeleteUserRoute}`, consultantData)
        .then((resp: any) => {
          if (resp.data.success) {
            handleCloseDelete();
            GetAllConsultantList(user.orgId);
            toast.success(t('responseMsg.msg_hideConsultant'));
          } else {
            toast.error(resp.data.error);
          }
        })
        .finally();
    } catch (error: any) {
      toast.error("Something went wrong");
      console.log(error.message);
    }
  }
  const handleCloseDelete = () => {
    setOpenDeleteDialog({
      'open': false,
      'UserID': null,
      'ConsultantName': ""
    });
  }
  const getSpecialities = async () => {
    await apis.instance.get(`${GetSpecialityList}`)
      .then((resp: any) => setSpecialityData(resp.data))
      .catch(err => {
        if (err?.response.status !== 401) toast.error(err.response?.data?.message)
        console.log(err)
      })
      .finally(() => setLoading(false));
  }
  const setSpecialityData = ({ data }: any) => {
    setConsultantSpecialities(data);
  }
  //#region GET CONSULTANT LIST
  useEffect(() => {
    GetAllConsultantList(user.orgId);
    getSpecialities();
    const username: string | null = JSON.parse(sessionStorage.getItem('username')!);
    let userRole: number | null = parseInt(JSON.parse(sessionStorage.getItem('role')!));
    setUserDetails({ username: username, userrole: userRole });

    if (!socketRef.current && (userRole === 3) && apis.socketURL) {
      const token = sessionStorage.getItem('token');
      const socketAuth = {
        query: { token },
      };
      socketRef.current = io(apis.socketURL, socketAuth);
      socketRef.current.on('connect', () => {
        console.log('Socket connected');
      });
    }
    getPageTitleHeight();
  }, []);

  const GetAllConsultantList = async (orgId: number) => {
    const orgID = orgId === undefined ? user.orgId : orgId;
    handleToggleLoader(true);
    const params = {
      'orgID': orgID,
      'role': ROLE.Consultant
    }
    await apis.instance.get(`${AllUserListByRoleRoute}`, { params })
      .then((resp: any) => {
        // mapping status key based on condition 
        let response = resp?.data?.data.map((item: any) => {
          return {
            ...item,
            full_name: item.user_title === "None" ? `${item.user_fname} ${item.user_lname ?? ""}` : `${item.user_title} ${item.user_fname} ${item.user_lname ?? ""}`,
            status: item.user_password == "false" ? t("lbl_verificationPending") : item.user_status === ActiveStatusID ? ActiveStatus : InActiveStatus
          }
        })
        allConsultantData(response);
        const nonDeletedConsultant = GetNonDeletedUser(response, user.userId);
        settingData(nonDeletedConsultant)
        setData(response)
        handleToggleLoader(false);
      })
      .catch(err => {
        handleToggleLoader(false);
        if (err?.response.status !== 401) toast.error(err.response?.data?.message)
        console.log(err)
      })
      .finally(() => setLoading(false));
  }
  //#endregion

  const allConsultantData = (response: string[]) => {
    const allUsers = GetNonLoggedInUser(response, user.userId);
    const sortedAllDataByFullnameThenByStatus = SortDataByIntialThenBySecondaryKeys(allUsers, "userFullName", "user_status");
    setShowAllUserData(sortedAllDataByFullnameThenByStatus);
  }

  //#region SEND PASSWORD LINK
  const handleSendPasswordPopup = (userId: string) => {
    setOpenSendEmailDialog({
      'open': true,
      'user_id': userId
    });
  }
  const handleCloseSendEmailDialog = () => {
    setOpenSendEmailDialog({
      'open': false,
      'user_id': ''
    });
  }
  // for filtering the DataGrid table by search 
  const handleSearchInputChange = (event: any) => {
    let value = event.target.value;
    setSearchQuery(value);
    if (event.target.value !== "") {
      let filterData: any = searchFilterFunction(data, value)
      setConsultantListData(filterData)
    }
    else if (event.target.value == "") {
      setConsultantListData(consultantListDataCopy)
    }
  }
  const handleSendPassword = async (userId: string) => {
    setLoading(true);
    const data = {
      'user_name': userId,
      'url': currentHost + "/setNewPassword"
    }
    try {
      await apis.instance.post(`${ResetPassword}`, data)
        .then((resp: any) => {
          if (resp.data.success) {
            handleCloseSendEmailDialog();
            toast.success(t("responseMsg.msg_expireResetPasswordLink"));
            setLoading(false);
          } else {
            toast.error(resp.data.error);
          }
        })
        .finally(() => setLoading(false));
    } catch (error: any) {
      setLoading(false);
      toast.error(t("responseMsg.msg_wrongEntry"));
      console.log(error.message);
    }
  }
  //#endregion

  return (
    <>
      {openIncomingCallBox()}
      {openReconnectDialog()}
      <div>
        <Box ref={PageTitleRef} className="page-title-row pb-3">
          <Grid container alignItems={'center'} textAlign={'left'} rowSpacing={2} columnSpacing={{ xs: 2, md: 0 }}>
            <Grid item xs={12} md={auto} mr={auto} sm padding={0} className="page-title-box" >
              <Typography className="page-title mt-0 mb-0" sx={(theme) => theme.typography.h5}> {localRole === ROLE.OrgAdmin && t('lbl_activeInactive')} {t('lbl_consultants')} </Typography>
            </Grid>
            <Grid item xs={12} md={auto} padding={0}>
              {localRole === ROLE.OrgAdmin &&
                <>
                  <Button id={ButtonActionAdd}
                    onClick={(e) => { onClick(e, null) }}
                    startIcon={<span className='cad-plus'></span>}
                    variant="contained" sx={{ my: .5, mr: 1 }}>
                    {t('lbl_create')}<Typography sx={{ font: 'inherit' }} display={{ xs: 'none', md: 'inline' }}>&nbsp;{t('lbl_consultant')}</Typography>
                  </Button>
                </>
              }
              <TextField className="cadis-search"
                id="outlined-start-adornment"
                size="small"
                type="search" placeholder="Search"
                sx={{ width: '18ch', my: .5 }}
                value={searchQuery}
                onChange={handleSearchInputChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end"><span className="cad-search"></span></InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={GridStyling} ref={tableGridRef} height={tableHeight}>
          <DataGrid
            components={{
              NoRowsOverlay: GetGridNoRecordMessage,
            }}
            rows={consultantListData}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
            rowsPerPageOptions={GridRowsPerPageOptions}
            getRowId={(row: any) => row.user_id}
            autoHeight={false}
            autoPageSize={false}
            disableColumnFilter={true}
            disableColumnMenu={true}
            disableSelectionOnClick={true}

          />
        </Box>
        {consultantCreationMode !== FormViewMode ? <AddUpdateOrganizationAdmin
          isOpenAddUpdateAdmin={addConsultantDrawer}
          setIsOpenAddUpdateAdminExpandAdminData={setAddConsultantDrawer}
          mode={consultantCreationMode}
          currentOrgAdminData={currentConsultantData}
          getOrgUserList={GetAllConsultantList}
          userRole={userRole}
          consultantSpecialities={consultantSpecialities}
          userFlow={consultantListFlow}
        /> : null}
        <Dialog
          open={openDeleteDialog.open}
          onClose={() => { return null }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t('lbl_confirmation')}
          </DialogTitle>
          <DialogContent>
            {t('lbl_confirmationForHide')} {openDeleteDialog.ConsultantName}?
          </DialogContent>
          <DialogActions>
            <CadisButton onClick={DeleteConsultant} variant="contained" color='error'>
              {t('lbl_yes')}
            </CadisButton>
            <CadisButton onClick={handleCloseDelete} variant="contained" color='success'>
              {t('lbl_no')}
            </CadisButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openSendEmailDialog.open}
          onClose={() => { return null }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t('lbl_confirmation')}
          </DialogTitle>
          <DialogContent>
            {t('lbl_askForNewLinkToSetNewPassword')}
          </DialogContent>
          <DialogActions>
            <CadisButton variant="contained" color='primary' onClick={() => handleSendPassword(openSendEmailDialog.user_id)} disabled={loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
              {t('lbl_yes')}
            </CadisButton>
            <CadisButton variant="contained" color='error' onClick={handleCloseSendEmailDialog} disabled={loading}>
              {t('lbl_no')}
            </CadisButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default ConsultantList;
