import { IconButton, IconButtonProps, IconButtonPropsColorOverrides, Tooltip, TooltipProps } from '@mui/material';
import { Box } from '@mui/system';
import { OverridableStringUnion } from '@mui/types';

type iconColor = OverridableStringUnion<
  'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
  IconButtonPropsColorOverrides
>;

type cadisBuildGridAction = IconButtonProps & {
  title: string,
  onClickAction?: any,
  placement?: TooltipProps["placement"],
  color?: iconColor,
  ActionIcon?: any,
  ActionId?: string,
  actionParams?: any,
  isDisabled?: boolean
}


const BuildGridAction = (props: cadisBuildGridAction) => {
  return <Box className='grid-act-btn'>
    <Tooltip title={props.title} placement={props.placement}>
      <IconButton
        aria-label=""
        id={props.ActionId}
        onClick={props.onClickAction}
        color={props.color}
      >
        {props.children}
      </IconButton>
    </Tooltip>
  </Box>
}

export default BuildGridAction