import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, ListItem, ListItemText, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { auto } from '@popperjs/core';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GetDeletedOrgs, OrganizationUpdateRoute } from '../../Common/api.routes';
import { ButtonReactivate, DefaultGridRowSize, GridRowsPerPageOptions, InActiveStatusID } from '../../Common/helper';
import * as HelperFunction from '../../Common/helperFunction';
import { GetDeletedOrganization, GetGridNoRecordMessage, formatPhoneNumber } from "../../Common/helperFunction";
import { GridStyling } from '../../Common/sharedStyling';
import CadisButton from '../../Components/Input/CadisButton';
import apis from '../../HttpConfig/Api';
import { IOrganization } from '../../Interfaces/IOrganization';
import { useUserRoleContext } from '../../context/UserRoleContext';

const DeletedOrganizationList = () => {
  const { handleToggleLoader } = useUserRoleContext();
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState<number>(DefaultGridRowSize);
  const [orgGridData, setOrgGridData] = useState([]);
  const [data, setData] = useState([]);
  const [showAllOrgData, setShowAllOrgData] = useState([]);
  const [showNonDeletedOrgData, setShowNonDeletedOrgData] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);
  const { t } = useTranslation();
  const user = HelperFunction.isAuthenticated();

  const [currentOrganization, setcurrentOrganization] = useState<IOrganization>({
    'org_id': "",
    'org_name': " ",
    'org_email': " ",
    'org_phone_m': " ",
    'org_phone_nm': " ",
    'org_status': InActiveStatusID,
    'org_is_deleted': InActiveStatusID
  });

  const [openReactivateOrgDialog, setOpenReactivateOrgDialog] = useState({
    'open': false,
    'org_id': null,
    'org_name': ""
  });

  //#region Handle Reactivate Org
  const handleReactivateOrg = async () => {
    const orgdata = {
      'org_id': openReactivateOrgDialog.org_id,
      'org_name': currentOrganization.org_name,
      'org_email': currentOrganization.org_email,
      'org_phone_m': currentOrganization.org_phone_m,
      'org_phone_nm': currentOrganization.org_phone_nm,
      'org_status': InActiveStatusID,
      'org_is_deleted': InActiveStatusID,
      'updated_by': user.userId
    }

    try {
      await apis.instance.post(`${OrganizationUpdateRoute}`, orgdata)
        .then((resp: any) => {
          if (resp.data.success) {
            handleCloseReactivateOrg();
            GetAllDeletedOrganizations();
            toast.success(t('responseMsg.msg_orgRestored'));
          } else {
            toast.error(resp.data.error);
          }
        })
        .finally(() => setLoading(false));
    } catch (error: any) {
      setLoading(false);
      toast.error(t('responseMsg.msg_wrongEntry'));
      console.log(error.message);
    }
  }

  const handleCloseReactivateOrg = () => {
    setOpenReactivateOrgDialog({
      'open': false,
      'org_id': null,
      'org_name': ""
    });
  }
  //#endregion

  const setOrgData = ({ data }: any) => {
    const sortedAllDataByNameThenByStatus = SortDataByIntialThenBySecondaryKeys(data, "org_name", "org_status");
    setShowAllOrgData(sortedAllDataByNameThenByStatus);

    const nonDeletedOrganizations = GetDeletedOrganization(data);
    const sortedDataByNameThenByStatus = SortDataByIntialThenBySecondaryKeys(nonDeletedOrganizations, "org_name", "org_status");
    setShowNonDeletedOrgData(sortedDataByNameThenByStatus);
    setOrgGridData(sortedDataByNameThenByStatus);
  }

  const handleSearchInputChange = (event: any) => {
    let value = event.target.value;
    setSearchQuery(value);

    if (event.target.value !== "") {
      let filterData: any = data.filter((row: any) => {
        return row.org_name.toLowerCase().includes(value.toLowerCase()) ||
          row.org_email.toLowerCase().includes(value.toLowerCase()) ||
          row.org_phone_m.toLowerCase().includes(value.toLowerCase())
      })
      setOrgGridData(filterData)
    }
    else if (event.target.value == "") {
      setOrgGridData(data)
    }
  }

  const SortDataByIntialThenBySecondaryKeys = (data: any[], initialSortKey: string, secondarySortKey: string) => {
    const sortedData: any = data.sort(function (a, b) {
      if (a.org_name < b.org_name) { return -1; }
      if (a.org_name > b.org_name) { return 1; }
      return 0;
    }).sort(function (x, y) {
      if (x.org_status > y.org_status) { return -1; }
      if (x.org_status < y.org_status) { return 1; }
      return 0;
    }).sort(function (p, q) {
      if (p.org_is_deleted < q.org_is_deleted) { return -1; }
      if (p.org_is_deleted > q.org_is_deleted) { return 1; }
      return 0;
    })
    return sortedData;
  }

  const columns: GridColDef[] = [
    { field: 'org_id', width: 0, hide: true, },
    {
      field: 'org_name', headerName: 'Name', minWidth: 200, flex: 1, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header',
      renderCell: ({ row }: any) => {
        return <>
          <ListItem disablePadding>
            <Tooltip title={`${row.org_name}`} >
              <ListItemText sx={{ textTransform: 'capitalize', }} className="long-col-name-ellipsis"
                primary={`${row.org_name}`}
                secondary=""
              />
            </Tooltip>
          </ListItem>
        </>
      },
    },
    {
      field: 'org_email', headerName: 'Email', minWidth: 300, flex: 1, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', sortable: false,
      renderCell: ({ row }: any) => (
        <Tooltip title={row.org_email} >
          <span className="table-cell-trucate">{row.org_email}</span>
        </Tooltip>
      ),
    },
    {
      field: 'org_phone_m', headerName: 'Phone', flex: 1, minWidth: 150, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', sortable: false, renderCell: ({ row }: any) => {
        const phoneNumber = row.org_phone_m;
        const phoneNumberTwo = row.org_phone_nm;
        return <Stack direction="column" textAlign={'left'}>
          <Box>{formatPhoneNumber(phoneNumber)}</Box>
          <Box>{formatPhoneNumber(phoneNumberTwo)}</Box>
        </Stack>
      }
    },
    {
      field: 'actions', headerAlign: 'center',
      headerClassName: 'app-theme-header bg-action',
      cellClassName: 'app-theme-cell bg-action',
      headerName: 'Actions', align: 'center',
      minWidth: 90, sortable: false, renderCell: (params: any) => {
        return <Stack direction="row" spacing={0}>
          <Box className='grid-act-btn'>
            <Tooltip title={t('lbl_orgReactivate')} placement="bottom-end">
              <IconButton aria-label="ReactivateOrg"
                id={ButtonReactivate} onClick={(e) => {
                  onClick(e, params)
                }} color="success">
                <span className="cad-restore-1"></span>
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
      }
    },

  ];

  //#region onClick column actions
  const onClick = (e: any, params: any) => {
    e.stopPropagation(); // don't select this row after 
    OnActionClick(e.currentTarget.id, params?.row);
  };
  //#endregion

  //#region Action Buttons Click
  const OnActionClick = (actionId: string, row: any) => {
    switch (actionId) {
      case ButtonReactivate:
        setOpenReactivateOrgDialog({
          'open': true,
          'org_id': row.org_id,
          'org_name': row.org_name
        });
        setcurrentOrganization(row);
        break;
      default:
        break;
    }
  }


  //#endregion

  const GetAllDeletedOrganizations = async () => {
    handleToggleLoader(true);
    await apis.instance.get(`${GetDeletedOrgs}`)
      .then((resp: any) => {
        handleToggleLoader(false);
        setOrgData(resp.data)
        setData(resp.data?.data)
      })
      .catch(err => {
        handleToggleLoader(false);
        if (err?.response.status !== 401) toast.error(err.response?.data?.message)
        console.log(err)
      })
      .finally(() => setLoading(false));
  }

 

  // dynamic scrollbar for grid view start
  const PageTitleRef: any = useRef();
  const tableGridRef: any = useRef();
  const [tableHeight, setTableHeight] = useState<any>(
  );

  const getPageTitleHeight = () => {
    let pageTitleHeight: any = 0;
    if (PageTitleRef && PageTitleRef.current) {
      pageTitleHeight = PageTitleRef.current.clientHeight;
    }
    setTableHeight(window.innerHeight - pageTitleHeight - 82);
  };

  window.addEventListener("resize", getPageTitleHeight);

  useEffect(() => {
    GetAllDeletedOrganizations();
    getPageTitleHeight();
  }, [])
  // dynamic scrollbar for grid view end


  return (
    <div>
      <Box ref={PageTitleRef} className="page-title-row pb-3">
        <Grid container alignItems={'center'} textAlign={'left'} rowSpacing={2} columnSpacing={{ xs: 2, md: 0 }}>
          <Grid item xs={12} md={auto} mr={auto} sm padding={0} className="page-title-box">
            <Typography className="page-title mt-0 mb-0" sx={(theme) => theme.typography.h5}> {t('lbl_deletedOrg')} </Typography>
          </Grid>
          <Grid item xs={12} md={auto} padding={0}>

            <TextField className="cadis-search"
              id="outlined-start-adornment"
              size="small"
              type="search" placeholder="Search"
              sx={{ width: '18ch', my: .5 }}
              value={searchQuery}
              onChange={handleSearchInputChange}
              InputProps={{
                endAdornment: <InputAdornment position="end"><span className="cad-search"></span></InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={GridStyling} ref={tableGridRef} height={tableHeight}>
        <DataGrid
          components={{
            NoRowsOverlay: GetGridNoRecordMessage,
          }}
          rows={orgGridData}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
          rowsPerPageOptions={GridRowsPerPageOptions}
          getRowId={(row: any) => row.org_id}
          autoHeight={false}
          autoPageSize={false}
          disableColumnFilter={true}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
          getRowClassName={(params) => params.row.org_is_deleted && `app-them-row-hidden`}
        />
      </Box>

      <Dialog
        open={openReactivateOrgDialog.open}
        onClose={() => { return null }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('lbl_confirmation')}
        </DialogTitle>
        <DialogContent>
          {t('lbl_reactivate')} {openReactivateOrgDialog.org_name}?
        </DialogContent>
        <DialogActions>
          <CadisButton onClick={handleReactivateOrg} variant="contained" color='success'>
            {t('lbl_yes')}
          </CadisButton>
          <CadisButton onClick={handleCloseReactivateOrg} variant="contained" color='error'>
            {t('lbl_no')}
          </CadisButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeletedOrganizationList