import { Avatar, Box, Grid, IconButton, InputAdornment, ListItem, ListItemAvatar, ListItemText, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { auto } from '@popperjs/core';
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GetDeletedUsersByOrg } from "../../Common/api.routes";
import { ButtonReactivate, DefaultGridRowSize, GridRowsPerPageOptions } from "../../Common/helper";
import { GetGridNoRecordMessage, formatPhoneNumber, isAuthenticated, searchFilterFunction, stringAvatar } from '../../Common/helperFunction';
import { GridStyling } from "../../Common/sharedStyling";
import apis from "../../HttpConfig/Api";
import { ROLE } from "../../Interfaces/IConstants";
import { useUserRoleContext } from "../../context/UserRoleContext";
import UserReactivate from "./UserReactivate";

const DeletedUserList = () => {
    const { handleToggleLoader } = useUserRoleContext();
    const { t } = useTranslation();
    const param = useParams();
    const user = isAuthenticated();
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState<number>(DefaultGridRowSize);
    const [deletedUserGridData, setDeletedUserGridData] = useState([]);
    const [searchQuery, setSearchQuery] = useState();
    const [data, setData] = useState([]);
    const location = useLocation();
    const [gridTitle, setGridTitle] = useState("");
    const [showNonDeletedUserData, setShowNonDeletedUserData] = useState([]);
    const [openReactivateUserDialog, setOpenReactivateUserDialog] = useState({
        'open': false,
        'UserID': null,
        'UserName': ""
    });

    const columns: GridColDef[] = [
        { field: 'user_id', width: 0, hide: true, },
        { field: 'user_title', width: 0, hide: true, },
        { field: 'user_fname', width: 0, hide: true, },
        { field: 'user_lname', width: 0, hide: true, },
        {
            field: 'userFullName', headerName: 'Name', minWidth: 200, flex: 1, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', sortable: true,
            renderCell: ({ row }: any) => {
                return <>
                    <ListItem disablePadding>
                        <ListItemAvatar>
                            <Avatar {...stringAvatar(row.full_name)} src={row.user_profile_img} />

                        </ListItemAvatar>
                        <Tooltip title={`${row.full_name}`} >
                            <ListItemText sx={{ textTransform: 'capitalize', }} className="long-col-name-ellipsis"
                                primary={`${row.full_name}`}
                                secondary=""
                            /></Tooltip>
                    </ListItem>
                </>
            },
        },
        {
            field: 'user_email', headerName: 'Email', minWidth: 300, flex: 1, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', sortable: false,
            renderCell: ({ row }: any) => (
                <Tooltip title={row.user_email} >
                    <span className="table-cell-trucate">{row.user_email}</span>
                </Tooltip>
            ),
        },
        {
            field: 'phone_number_m', headerName: 'Phone', minWidth: 150, flex: 1, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', sortable: false, renderCell: ({ row }: any) => {
                const phoneNumber = row.phone_number_m;
                const phoneNumberTwo = row.phone_number_nm;
                return <Stack direction="column" textAlign={'left'}>
                    <Box>{formatPhoneNumber(phoneNumber)}</Box>
                    <Box>{formatPhoneNumber(phoneNumberTwo)}</Box>
                </Stack>
            }
        },
        {
            field: 'actions', headerName: 'Actions',
            minWidth: 90, headerClassName: 'app-theme-header bg-action',
            flex: 1, cellClassName: 'app-theme-cell bg-action', sortable: false,
            headerAlign: 'center', align: 'center', renderCell: (params: any) => {
                return <Stack direction="row" spacing={0}>

                    <Box className='grid-act-btn'>
                        <Tooltip title="Unhide User" placement="bottom-end">
                            <IconButton aria-label="ReactivateUser"
                                id={ButtonReactivate} onClick={(e) => {
                                    onClick(e, params)
                                }} color="success">
                                {/* <span className="cad-Restore-2"></span> */}
                                <span className="cad-restore-1"></span>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Stack>
            }
        }
    ]
    // filtering the search filed
    const handleSearchInputChange = (event: any) => {
        let value = event.target.value;
        setSearchQuery(value);

        if (event.target.value !== "") {
            let filterData: any = searchFilterFunction(data, value)
            setDeletedUserGridData(filterData)
        }

    }

    const settingData = (data: any) => {
        const sortedDataByFullnameThenByStatus = SortDataByIntialThenBySecondaryKeys(data, "userFullName", "user_status");
        setDeletedUserGridData(sortedDataByFullnameThenByStatus);
        setData(sortedDataByFullnameThenByStatus);
        setShowNonDeletedUserData(sortedDataByFullnameThenByStatus);
    }

    const SortDataByIntialThenBySecondaryKeys = (data: any[], initialSortKey: string, secondarySortKey: string) => {
        const updatedData = data.map(obj => ({ ...obj, userFullName: `${obj.user_fname} ${obj.user_lname}` }))
        const sortedData: any = updatedData.sort(function (a, b) {
            if (a.userFullName < b.userFullName) { return -1; }
            if (a.userFullName > b.userFullName) { return 1; }
            return 0;
        }).sort(function (x, y) {
            if (x.user_status > y.user_status) { return -1; }
            if (x.user_status < y.user_status) { return 1; }
            return 0;
        }).sort(function (p, q) {
            if (p.user_is_deleted < q.user_is_deleted) { return -1; }
            if (p.user_is_deleted > q.user_is_deleted) { return 1; }
            return 0;
        })
        return sortedData;
    }

    const onClick = (e: any, params: any) => {
        e.stopPropagation(); // don't select this row after 
        OnActionClick(e.currentTarget.id, params?.row);
    };

    //#region Action Buttons Click
    const OnActionClick = (actionId: string, row: any) => {
        switch (actionId) {
            case ButtonReactivate:
                setOpenReactivateUserDialog({
                    'open': true,
                    'UserID': row.user_id,
                    'UserName': row.user_fname ?? '' + " " + row.user_lname ?? ''
                });
                break;
            default:
                break;
        }
    }
    //#endregion

    // dynamic scrollbar for grid view start
    const PageTitleRef: any = useRef();
    const tableGridRef: any = useRef();
    const [tableHeight, setTableHeight] = useState<any>(
    );

    const getPageTitleHeight = () => {
        let pageTitleHeight: any = 0;
        if (PageTitleRef && PageTitleRef.current) {
            pageTitleHeight = PageTitleRef.current.clientHeight;
        }
        setTableHeight(window.innerHeight - pageTitleHeight - 82);
    };

    window.addEventListener("resize", getPageTitleHeight);

    // dynamic scrollbar for grid view end

    //#region GET CADIS ADMIN LIST
    const GetAllDeletedUsers = async () => {
        const roleid = param.roleId == "cadisAdmin" ? ROLE.CadisAdmin : param.roleId === "orgAdmins" ?
            ROLE.OrgAdmin : param.roleId === "consultants" ? ROLE.Consultant : ROLE.Assistant;

        handleToggleLoader(true);

        const params = {
            'org_id': user.orgId,
            'role_id': roleid
        }

        await apis.instance.get(`${GetDeletedUsersByOrg}`, { params })
            .then((resp: any) => {

                let response = resp?.data?.data.map((item: any) => {
                    return {
                        ...item,
                        full_name: param.roleId === "assistants"
                            ? `${item.user_fname} ${item.user_lname ?? ""}`
                            : item.user_title === "None"
                                ? item.user_fname
                                : `${item.user_title ?? ""} ${item.user_fname} ${item.user_lname ?? ""}`
                    }
                })

                handleToggleLoader(false);
                settingData(response)
            })
            .catch(err => {
                handleToggleLoader(false);
                if (err?.response.status !== 401) toast.error(err.response?.data?.message)
                console.log(err)
            })
            .finally(() => setLoading(false));
    }

    const GridTitle = () => {
        const title = param.roleId == "cadisAdmin" ? t('lbl_deletedCadisSuperAdmin') : param.roleId === "orgAdmins" ?
            t('lbl_deletedOrgAdministrators') : param.roleId === "consultants" ? t('lbl_deletedConsultants') : t('lbl_deletedAssistants');
        setGridTitle(title);
    }

    useEffect(() => {
        setDeletedUserGridData([]);
        GridTitle();
        GetAllDeletedUsers();
        getPageTitleHeight();
    }, [location]);
    //#endregion

    return (
        <div>
            <Box ref={PageTitleRef} className="page-title-row pb-3">
                <Grid container alignItems={'center'} textAlign={'left'} rowSpacing={2} columnSpacing={{ xs: 2, md: 0 }}>
                    <Grid item xs={12} md={auto} mr={auto} sm className="page-title-box" padding={0}>
                        <Typography className='page-title mt-0 mb-0' sx={(theme) => theme.typography.h5}>{gridTitle}</Typography>
                    </Grid>
                    <Grid item xs={12} md={auto} padding={0}>

                        <TextField className="cadis-search"
                            id="outlined-end-adornment"
                            size="small"
                            type="search" placeholder="Search"
                            sx={{ width: '18ch', my: .5 }}
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><span className="cad-search"></span></InputAdornment>,
                            }}
                        />

                    </Grid>
                </Grid>
            </Box>
            <Box sx={GridStyling} ref={tableGridRef} height={tableHeight}>
                <DataGrid
                    components={{
                        NoRowsOverlay: GetGridNoRecordMessage,
                    }}
                    rows={deletedUserGridData}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
                    rowsPerPageOptions={GridRowsPerPageOptions}
                    getRowId={(row: any) => row.user_id}
                    autoHeight={false}
                    autoPageSize={false}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    disableSelectionOnClick={true}
                />
            </Box>
            <UserReactivate
                openReactivateOrgAdminDialog={openReactivateUserDialog}
                setOpenReactivateOrgAdminDialog={setOpenReactivateUserDialog}
                getAllOrgAdmins={GetAllDeletedUsers}
            />
        </div>
    );
}

export default DeletedUserList;