import { Box } from "@mui/material";
import jwtDecode from "jwt-decode";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { InActiveStatusID, allowedProfileImgExtensions, profileImageFileSizeLimitInMB } from "../Common/helper";
import apis from "../HttpConfig/Api";
import { ROLE, ROLENAMES, User } from "../Interfaces/IConstants";
import { GetUserCreationCount, getSubscriptionDataUsage } from "./api.routes";

export const isAuthenticated = () => {
  const user = getUser();
  const userDetails: User = {
    id: 0, name: '', orgId: 0, roles: [], isCadisAdmin: false, isOrgAdmin: false, isConsultant: false, userId: 0,
    username: "", userEmail: ""
  };

  if (user) {
    const users: any = jwtDecode(user);
    userDetails.id = users.id;
    userDetails.name = users.user.user_lname !== null ? users.user.user_fname + " " + users.user.user_lname : users.user.user_fname;
    userDetails.orgId = users.user.org_id;
    userDetails.roles = users.roles;
    userDetails.isCadisAdmin = users.roles.includes(ROLE.CadisAdmin);
    userDetails.isOrgAdmin = users.roles.includes(ROLE.OrgAdmin);
    userDetails.isConsultant = users.roles.includes(ROLE.Consultant);
    userDetails.userId = users.user.user_id;
    userDetails.username = users.user.user_name;
    userDetails.userEmail = users.user.user_email
    return userDetails;
  }

  return userDetails;
}

export const getUser = () => {
  const user = sessionStorage.getItem("token");
  return user;
}

const stringToColor = (string: string) => {
  let hash = 4;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}

export const stringAvatar = (name: string) => {
  let nameLength = name.includes(" ");
  if (nameLength) {
    var s = name.split(' ');
    if (s.length > 1)
      nameLength = s[1] === "" ? false : true;
  }

  if (name === "undefined " || name === "") {
    return '';
  }

  else {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },

      children: nameLength ? `${name.split(' ')[0][0].toUpperCase()}${name.split(' ')[1][0].toUpperCase()}`
        : `${name.split(' ')[0][0].toUpperCase()}`,
    }
  }
}

export const getFullName = (params: any) => {
  return `${params.row.user_fname || ''} ${params.row.user_lname || ''}`;
}

export const fileToBase64 = (file: any) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const GenerateRandomUsername = (firstName: string, lastName: string) => {
  //COMMENTED CODES ARE NEEDED FOR ANY FURTURE IMPROVEMENTS
  //firstName.lastName{random number from 0 to 100 will be here}
  //eg:- kunal.bhosale29, kunal.bhosale56
  if (!firstName && !lastName) {
    return "".replace(/\s/g, "");
  }
  var randomNumber = Math.round(Math.random() * 1000);

  if (firstName && lastName) {
    const trimmedName = `${firstName.toLowerCase()}${lastName.toLowerCase()}${randomNumber}`.replace(/\s/g, "");
    return GenerateUsernameByLength(8, trimmedName);
    // return `${firstName.toLowerCase()}${lastName.toLowerCase()}${randomNumber}`.replace(/\s/g, "");
  }

  if (!firstName && lastName) {//fname not present = lname present
    const trimmedName = `${lastName.toLowerCase()}${randomNumber}`.replace(/\s/g, "");
    return GenerateUsernameByLength(8, trimmedName);
    // return `${lastName.toLowerCase()}${randomNumber}`.replace(/\s/g, "");
  }
  if (firstName && !lastName) {//fname present = lname present
    const trimmedName = `${firstName.toLowerCase()}${randomNumber}`.replace(/\s/g, "");
    return GenerateUsernameByLength(8, trimmedName);
    // return `${firstName.toLowerCase()}${randomNumber}`.replace(/\s/g, "");
  }
}

export const getUserRoleName = (user: User) => {
  if (user.isCadisAdmin)
    return 'CADIS Super Admin';
  else if (user.isOrgAdmin)
    return 'Org Admin';
  else if (user.isConsultant)
    return 'Consultant';
}

export const getLoggedUserRoleName = (user: number | null | undefined) => {
  if (user === ROLE.CadisAdmin)
    return 'CADIS Super Admin';
  else if (user === ROLE.OrgAdmin)
    return 'Org Admin';
  else if (user === ROLE.Consultant)
    return 'Consultant';
}

export const getUserRole = (roleName: string) => {
  if (roleName === ROLENAMES.OrgAdmin)
    return ROLE.OrgAdmin;
  else if (roleName === ROLENAMES.Consultant)
    return ROLE.Consultant;
  else if (roleName === ROLENAMES.Assistant)
    return ROLE.Assistant;
  else if (roleName === ROLENAMES.CadisAdmin)
    return ROLE.CadisAdmin;
}

export const GenerateFormattedDateTime = (date: Date) => {
  let formattedDate: string = "-";
  let formattedTime: any;
  if (Date) {
    formattedDate = new Date(date).toLocaleDateString();
    formattedTime = new Date(date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
  }

  return <>{formattedDate} <br /> {formattedTime ?? null}</>;
}

export const GetTimeWithAMPM = (date: Date) => {
  if (date) return new Date(date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
  return null;
}
// Coords transformation with WebAssistant and android 
export const coordsTransformRatio = (width: number, height: number) => {
  const isWebAssistant = sessionStorage.getItem("isWebAssistant") === "true";
  const isConferenceCall = sessionStorage.getItem("isConferenceCall") === "true"
  const rokidWidth = Number(sessionStorage.getItem("rokidWidth"))
  const rokidHeight = Number(sessionStorage.getItem("rokidHeight"))
  // Check if it's a conference call session
  if (isConferenceCall) {
    if (isWebAssistant) {
      // Adjust height and width based on the window size for conference calls
      const height_ = window.innerHeight - 82; // Adjusting for header height
      const width_ = (16 / 9) * height_; // Assuming a 16:9 aspect ratio
      const transformXRatio = width_ / width; // Calculate X-axis transform ratio
      const transformYRatio = height_ / height; // Calculate Y-axis transform ratio
      return { transformXRatio, transformYRatio };
    } else {
      // Default transform ratios for non-conference call sessions
      const transformXRatio = rokidWidth / width;
      const transformYRatio = rokidHeight / height;
      return { transformXRatio, transformYRatio };
    }

  } else {
    // Default transform ratios for non-conference call sessions
    const transformXRatio = rokidWidth / width;
    const transformYRatio = rokidHeight / height;
    return { transformXRatio, transformYRatio };
  }
};

// ARRRAY FILTER (ONLINE USERS)
export const filterOnlineUsers = (arr: any, onlineUser?: any) => {
  return arr.filter((user: any) => user.username === onlineUser?.username)[0]
}

// NOT IN USE COMMENTED FOR FUTURE REF
// SET SESSION ID IN SESSION STORAGE
export const setSessionIDInSessionStorage = (sessionId: number) => {
  sessionStorage.setItem('sessionId', JSON.stringify(sessionId));
}

// NOT IN USE COMMENTED FOR FUTURE REF
// GET SESSION ID FROM SESSION STORAGE
export const getSessionIDFromSessionStorage = () => {
  let sessionId: number | null = parseInt(JSON.parse(sessionStorage.getItem('sessionId')!));
  return sessionId;
}

// NOT IN USE COMMENTED FOR FUTURE REF
// CLEAR SESSION ID FROM SESSION STORAGE
export const clearSessionIDFromSessionStorage = () => {
  sessionStorage.removeItem("sessionId");
}

//COMMENTED BY KUNAL FOR FUTURE BELOW CODE IS NOT USED
export const SortDataByIntialThenBySecondaryKey = (data: any[], initialSortKey: string, secondarySortKey: string) => {
  const sortedData: any = data.sort(function (a, b) {
    if (`${a}.${initialSortKey}` < `${b}.${initialSortKey}`) { return -1; }
    if (`${a}.${initialSortKey}` > `${b}.${initialSortKey}`) { return 1; }
    return 0;
  }).sort(function (x, y) {
    if (`${x}.${secondarySortKey}` > `${y}.${secondarySortKey}`) { return -1; }
    if (`${x}.${secondarySortKey}` < `${y}.${secondarySortKey}`) { return 1; }
    return 0;
  })
  return sortedData;
}

function GenerateUsernameByLength(length: number, trimmedName: string) {
  let result = '';
  const characters = trimmedName;
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const GetNonDeletedUser = (dataToFilter: any[], userId: any) => {
  return dataToFilter.filter((el) => {
    return el.user_is_deleted !== 1 && el.user_id !== userId
  })
}

export const GetDeletedUser = (dataToFilter: any[], userId: any) => {
  return dataToFilter.filter((el) => {
    return el.user_is_deleted !== 0 && el.user_id !== userId
  })
}
export const GetNonDeletedUserCallsession = (dataToFilter: any[], userId: any) => {
  return dataToFilter.filter((el) => {
    return el.user_is_deleted !== 1
  })
}

export const GetNonLoggedInUser = (dataToFilter: any[], userId: any) => {
  return dataToFilter.filter((el) => {
    return el.user_id !== userId
  })
}

export const GetNonDeletedOrganization = (dataToFilter: any[]) => {
  return dataToFilter.filter((el) => {
    return el.org_is_deleted !== 1
  })
}

export const GetDeletedOrganization = (dataToFilter: any[]) => {
  return dataToFilter.filter((el) => {
    return el.org_is_deleted !== InActiveStatusID
  })
}

// for filtering the DataGrid table by search
export const searchFilterFunction = (item: any, value: any) => {

  return item.filter((row: any) => {
    return row?.speciality == null || row?.speciality == undefined ?
      (
        row.user_fname.toLowerCase().includes(value.toLowerCase()) ||
        // row.org_name.toLowerCase().includes(value.toLowerCase()) ||
        row.phone_number_m.includes(value.toLowerCase()) ||
        row.user_email.toLowerCase().includes(value.toLowerCase()) ||
        row.speciality?.toLowerCase().includes(value.toLowerCase()) ||
        row.full_name.toLowerCase().includes(value.toLowerCase()) ||
        row.call?.date?.toLowerCase().includes(value.toLowerCase())
      )
      :
      (
        row.full_name.toLowerCase().includes(value.toLowerCase()) ||
        row.phone_number_m.includes(value.toLowerCase()) ||
        row.user_email.toLowerCase().includes(value.toLowerCase()) ||
        row.speciality[0]?.speciality?.toLowerCase().includes(value.toLowerCase()) ||
        row.call?.date?.toLowerCase().includes(value.toLowerCase())
      )
  });
}

const profileImageAllowedFileExtensionsArray = allowedProfileImgExtensions.replace(/\s/g, "").split(",");//(times bytes)
export const ValidateFileSize = (file: any) => {
  return file.size < profileImageFileSizeLimitInMB;
}
export const ValidateFileType = (file: any) => {
  let isValid = false;
  profileImageAllowedFileExtensionsArray
    .map((el) => {
      if (file.type.includes(el.replace(/\./g, ""))) {
        isValid = true;
      }
      return el;
    });
  return isValid;
}

export const DisplayProfileImageValidationMessage = (isFileTypeValid: boolean, isFileSizeValid: boolean) => {
  let fileValidationMessage = "";
  const formattedAllowedExtensions = allowedProfileImgExtensions.toUpperCase().replace(/\./g, "")
  if (!isFileTypeValid && !isFileSizeValid) {
    fileValidationMessage = `Please select a file that is a valid image type (${formattedAllowedExtensions}) and is 2MB or less in size.`;
  } else if (!isFileTypeValid) {
    fileValidationMessage = `Please select a file that is a valid image type (${formattedAllowedExtensions}).`;
  } else if (!isFileSizeValid) {
    fileValidationMessage = 'The file size is too large. Please select a file that is 2MB or less in size.';
  }

  toast.error(fileValidationMessage);
}

export const GetTimeWithAMPMWithLuxon = (date: any) => {
  return DateTime
    .fromISO(date)
    .toLocaleString(DateTime.TIME_SIMPLE).toUpperCase();
}

export const GetShortDateWithLuxon = (date: any) => {
  return DateTime
    .fromISO(date)
    .toLocaleString(DateTime.DATE_SHORT);
}

export const GetDateWithLuxon = (date: string) => {
  const dateTime = DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss');
  return dateTime.toLocaleString(DateTime.DATE_SHORT);
}

export const formatPhoneNumber = (phoneNumber: string) => {
  // Check if phoneNumber is valid and contains at least 3 characters
  if (phoneNumber) {
    const formattedNumber = phoneNumber.slice(0, 3) + '-' + phoneNumber.slice(3, 6) + '-' + phoneNumber.slice(6);
    return formattedNumber;
  }
}

export const GetGridNoRecordMessage = () => {
  return <Box sx={{ mt: 1, px: 1, opacity: '0.8' }}>
    No record found
  </Box>
}

export const validateAndSetPrice = (inputValue: string, fieldName: string, mode: string, setFieldValue: any) => {
  inputValue = inputValue.replace(/\s/g, '');
  if (mode === 'update' && inputValue === '0') {
    setFieldValue(fieldName, '');
    return;
  }
  if (inputValue.length > 1 && inputValue[0] === '0') {
    inputValue = inputValue.slice(1);
  }
  if (!/^\d*\.?\d*$/.test(inputValue)) {
    return;
  }
  setFieldValue(fieldName, inputValue);
};

export const getAccuratePrice = (price: number) => {
  const numericPrice = Number(price);
  if (!isNaN(numericPrice)) {
    const formattedPrice = numericPrice.toFixed(2);
    return parseFloat(formattedPrice).toString();
  }
  return price;
}

export const checkUserCreationCount = async (orgId: number, userRole: number) => {
  const params = {
    'org_id': orgId
  }
  try {
    const resp = await apis.instance.get(`${GetUserCreationCount}`, { params });
    if (resp.data.success) {
      return true;
    } else {
      if (resp.data.error.toLowerCase() === 'no subscriptions assigned for this organization') {
        if (userRole === ROLE.OrgAdmin) {
          toast.error('You need to buy subscription to create user.');
        } else {
          toast.error('Organization need to buy subscription to create user.');
        }
      }
      else if (resp.data.error.toLowerCase() === 'failed to retrieve total user count for the organization.'
        || resp.data.error.toLowerCase() === 'failed to retrieve total active user count for the organization.'
        || resp.data.error.toLowerCase() === 'cannot create user as no subscription plan is active for this organization') {
        if (userRole === ROLE.OrgAdmin) {
          toast.error('No active subscription to create a user.');
        } else {
          toast.error('Organization does not have an active subscription to create a user.');
        }
      }
      else if (resp.data.error.toLowerCase() === 'organization has exceeded the maximum limit for user creation.') {
        if (userRole === ROLE.OrgAdmin) {
          toast.error('You have reached the limit for user creation.');
        } else {
          toast.error('Organization has reached the limit for user creation.');
        }
      }
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
}

export const validateAndSetMonthPrice = (inputValue: string, fieldName: string, mode: string, setFieldValue: any) => {
  inputValue = inputValue.replace(/\s/g, '');
  if (mode === 'update' && inputValue === '0') {
    setFieldValue(fieldName, '');
    return;
  }
  if (inputValue.length > 1 && inputValue[0] === '0') {
    inputValue = inputValue.slice(1);
  }
  const regex = /^(\d*(\.\d{0,2})?)?$/;

  if (!regex.test(inputValue)) {
    const validPart = inputValue.match(/^\d*(\.\d{0,2})?/);
    setFieldValue(fieldName, validPart ? validPart[0] : '');
    return;
  }
  setFieldValue(fieldName, inputValue);
};

export const validateToOnedecimalOnly = (inputValue: string, fieldName: string, mode: string, setFieldValue: any) => {
  inputValue = inputValue.replace(/\s/g, '');
  if (mode === 'update' && inputValue === '0') {
    setFieldValue(fieldName, '');
    return;
  }
  if (inputValue.length > 1 && inputValue[0] === '0') {
    inputValue = inputValue.slice(1);
  }
  const regex = /^(\d*(\.\d{0,1})?)?$/; // Modified regex to allow only one digit after decimal point

  if (!regex.test(inputValue)) {
    const validPart = inputValue.match(/^\d*(\.\d{0,1})?/); // Adjusted to match the modified regex
    setFieldValue(fieldName, validPart ? validPart[0] : '');
    return;
  }
  setFieldValue(fieldName, inputValue);
};


//comparative sorting function based on status
export const statusSortComparator = (v1: string, v2: string) => {
  const statusOrder = ['Active', 'Verification Pending', 'Disabled'];
  return statusOrder.indexOf(v1) - statusOrder.indexOf(v2);
};

//check remaining subscription data limit 
export const subscriptionDataUsage = async (onDataFinished: any, onCallInitiated: any) => {
  try {
    const subscriptionId = sessionStorage.getItem("subscriptionId");
    const params = { "subscriptionId": subscriptionId };
    const resp = await apis.instance.get(`${getSubscriptionDataUsage}`, { params });

    if (resp.data.success) {
      if (resp.data.data.remainingData == 0) {
        onDataFinished(true);
      } else {
        onCallInitiated();
      }
    } else {
      toast.error(resp.data.error);
      console.error("Subscription data usage retrieval failed:", resp.data.error);
    }
  } catch (error: any) {
    const errorMessage = error.response && error.response.data && error.response.data.message;
    toast.error(errorMessage);
    console.error("Error retrieving subscription data usage:", error);
  }
};

export function formateDecimalValues(value: number | undefined | string): number {
  const numberValue = Number(value);

  if (Number.isNaN(numberValue)) {
    throw new Error("Invalid input: value is not a number");
  }

  if (Number.isInteger(numberValue)) {
    return numberValue;
  } else {
    return parseFloat(numberValue.toFixed(2));
  }
}

export function formateMbtoGb(valueInMb: number) {
  const MB_IN_GB = 1024;

  if (valueInMb < MB_IN_GB && valueInMb > 0) {
    return `${formateDecimalValues(valueInMb)} MB`;
  } else {
    const valueInGb = valueInMb / MB_IN_GB;
    return `${formateDecimalValues(valueInGb)} GB`;
  }
}