import { Box, Chip, Container, Drawer, Grid, IconButton, Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import CadisButton from "../../Components/Input/CadisButton";
import { ROLE } from "../../Interfaces/IConstants";
import { IrenewSubscriptionInfo } from "../../Interfaces/ISubscription";

const RenewedSubscriptionDetails = (
    { isRenewPlanDetailsDrawerOpen,
        setIsRenewPlanDetailsDrawerOpen,
        currentRenewPlanDetails,
        setRenewSubscriptionDrawer
    }: {
        isRenewPlanDetailsDrawerOpen: boolean,
        setIsRenewPlanDetailsDrawerOpen: Dispatch<SetStateAction<boolean>>,
        setRenewSubscriptionDrawer: Dispatch<SetStateAction<boolean>>,
        currentRenewPlanDetails: IrenewSubscriptionInfo
    }) => {

    const { t } = useTranslation();
    const localRole: any = Number(sessionStorage.getItem("role"));

    const handleFormClose = () => {
        setIsRenewPlanDetailsDrawerOpen(false)
    }

    return (
        <>
            <Drawer
                anchor='right'
                open={isRenewPlanDetailsDrawerOpen}
                onClose={() => { return null }}
            >
                <Box role="presentation">
                    <Box sx={{ pt: 1.5, position: 'sticky', top: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }} >
                        <Container sx={{ alignItems: 'center', display: 'flex' }} disableGutters>
                            <Box sx={{ width: '40px', height: '40px', flexGrow: 1, display: { xs: 'flex', pr: 2 } }}>
                                <IconButton onClick={handleFormClose} sx={{}} className='c-pointer'>
                                    <span className='cad-close'></span>
                                </IconButton>
                            </Box>
                        </Container>
                        <Box sx={{ mb: 2, pb: 2, mt: 2, display: 'flex' }}>
                            <Typography className="mt-0 mb-0 slide-right-title" sx={(theme) => theme.typography.h5}>
                                {t('lbl_renewedSubscriptionDetails')} -
                                <Typography sx={{ display: 'inline', font: 'inherit' }} color={'success.main'} >{currentRenewPlanDetails?.planName}</Typography>
                            </Typography>
                        </Box>
                    </Box>
                    <Grid container width={'100%'}>
                        <Stack spacing={2} direction={'column'} paddingX={'20px'} width={'100%'}>
                            <div>
                                <label className='form-label'>{t("lbl_planName")}</label>
                                <Typography>
                                    {currentRenewPlanDetails.planName}
                                </Typography>
                            </div>
                            <div>
                                <label className='form-label'>{t("lbl_renewedPlanStartDate")}</label>
                                <Typography>
                                    {currentRenewPlanDetails.startDate === "Invalid DateTime" ? "-" : currentRenewPlanDetails.startDate}
                                </Typography>
                            </div>
                            <div>
                                <label className='form-label'>{t("lbl_renewedPlanExpiryDate")}</label>
                                <Typography>
                                    {currentRenewPlanDetails.endDate === "Invalid DateTime" ? "-" : currentRenewPlanDetails.endDate}
                                </Typography>
                            </div>
                            <div>
                                <label className='form-label'>{t("lbl_subscriptionTotalData")}</label>
                                <Typography>
                                    {currentRenewPlanDetails.totalData === 0 ? t("lbl_unlimited") : <>{currentRenewPlanDetails.totalData / 1024} {`${t('lbl_gb')}`} ({t("lbl_effectiveFrom")} {currentRenewPlanDetails.startDate === "Invalid DateTime" ? "-" : currentRenewPlanDetails.startDate})</>}
                                </Typography>
                            </div>
                            <div>
                                <label className='form-label'>{t("lbl_paymentStatus")}</label>
                                <Typography color={
                                    currentRenewPlanDetails.paymentStatus == "Succeeded" ? "success.main" : (currentRenewPlanDetails.paymentStatus === "Failed") ? "error" : "warning.main"
                                }>
                                    {currentRenewPlanDetails.paymentStatus}
                                </Typography>
                            </div>

                            {currentRenewPlanDetails.paymentStatus === "Failed" && <Chip color="error" size="small" sx={{ paddingX: 1, paddingY: .8 }} label={`${localRole === ROLE.CadisAdmin ? t('lbl_organization') : t('lbl_your')} ${t("lbl_renewFailedMsg")}`} />}

                        </Stack>
                        <Box width={'100%'} sx={{ pt: 2, pb: 2, position: 'sticky', bottom: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }}>
                            <Grid container spacing={0} alignItems="center" width={'100%'}>
                                <Grid item xs={12}>
                                    <CadisButton onClick={() => {
                                        if ((currentRenewPlanDetails.paymentStatus === "Failed")) {
                                            setRenewSubscriptionDrawer(true)
                                            setIsRenewPlanDetailsDrawerOpen(false)
                                        } else {
                                            setIsRenewPlanDetailsDrawerOpen(false)
                                        }
                                    }} type='submit' variant="contained" color='primary'>
                                        {(currentRenewPlanDetails.paymentStatus === "Failed") ? t("lbl_tryRenew") : t("lbl_close")}
                                    </CadisButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Box>
            </Drawer>
        </>
    );
}
export default RenewedSubscriptionDetails;