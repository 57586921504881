import { Avatar, Badge, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, ListItem, ListItemAvatar, ListItemText, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { AllSubscribedUserList, SendQRCodeToAssistantRoute, getSubscriptionDataUsage } from "../../Common/api.routes";
import { ADD_ROKID_USER } from "../../Common/helper";
import { GetNonDeletedUser, filterOnlineUsers, isAuthenticated, stringAvatar } from "../../Common/helperFunction";
import CadisLoader from "../../Components/Loading/CadisLoader";
import apis from "../../HttpConfig/Api";
import { ROLE } from "../../Interfaces/IConstants";
import { IUser } from "../../Interfaces/IUser";
import { useSocketContext } from "../../context/SocketContext";

const AssistantListForCalling = () => {
    const [assistantList, setAssistantList] = useState<IUser[]>([]);
    const [selectedAst, setSelectedAst] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [isAssistantInOrg, setIsAssistantInOrg] = useState<boolean>(false);
    const user = isAuthenticated();
    const { socketRef, sessionIdRef, rokidInviteDialog, onlineUsers, setRokidInviteDialog, isConferenceCall, setRokidInviteSent } = useSocketContext(); // socket context customeHook
    const { t } = useTranslation();
    let userStatus = useRef<any>([]);

    useEffect(() => {
        GetAllAssistantList();
    }, [])

    useEffect(() => {
        if (
            filterOnlineUsers(userStatus.current, onlineUsers)?.username !==
            onlineUsers?.username &&
            onlineUsers?.status === "online"
        ) {
            userStatus.current.push({
                username: onlineUsers?.username,
                status: onlineUsers?.status,
            });
        } else if (
            filterOnlineUsers(userStatus.current, onlineUsers)?.username ===
            onlineUsers?.username &&
            onlineUsers?.status === "offline"
        ) {
            userStatus.current = userStatus.current.filter(
                (item: any) => item.username !== onlineUsers.username
            );
        } else if (
            filterOnlineUsers(userStatus.current, onlineUsers)?.username ===
            onlineUsers?.username &&
            onlineUsers?.status === "busy"
        ) {
            const index = userStatus.current.findIndex(
                (user: any) => user.username === onlineUsers?.username
            );
            userStatus.current.map((user: any, i: number) => {
                if (i === index) {
                    user.status = onlineUsers?.status;
                }
            });
        } else if (
            filterOnlineUsers(userStatus.current, onlineUsers)?.username ===
            onlineUsers?.username &&
            onlineUsers?.status === "online"
        ) {
            const index = userStatus.current.findIndex(
                (user: any) => user.username === onlineUsers?.username
            );
            userStatus.current.map((user: any, i: number) => {
                if (i === index) {
                    user.status = onlineUsers?.status;
                }
            });
        }
        if (
            filterOnlineUsers(userStatus.current, onlineUsers)?.username !==
            onlineUsers?.username &&
            onlineUsers?.status === "busy"
        ) {
            userStatus.current.push({
                username: onlineUsers?.username,
                status: onlineUsers?.status,
            });
        }
    }, [onlineUsers]);

    const handleSendInviteToRokid = async () => {
        const selectedUserStatus = filterOnlineUsers(userStatus.current, { username: selectedAst });
        const selectedUser: any = await assistantList?.find((user: any) => user.user_name === selectedAst);
        const rokidSubscriptionId = selectedUser.subscription_id
        const params = { "subscriptionId": rokidSubscriptionId };
        const resp = await apis.instance.get(`${getSubscriptionDataUsage}`, { params })
        if (resp.data.success) {
            if (resp.data.data.remainingData > 0 || resp.data.data.isDataUnlimited) {
                if (selectedUserStatus && selectedUserStatus.status === "online") {
                    socketRef.current?.emit(ADD_ROKID_USER, {
                        assistantName: selectedAst,
                        sessionId: sessionIdRef.current,
                    });
                    setRokidInviteSent(true);
                    toast.success(t('responseMsg.msg_invitationSent'));
                }
                else if (selectedUserStatus && selectedUserStatus.status === "busy") {
                    toast.info(selectedUser.full_name + " is busy.");
                }
                else {
                    const data = {
                        user_name: selectedAst,
                        isConferenceCall: true,
                        sessionId: sessionIdRef.current
                    }
                    await apis.instance
                        .post(`${SendQRCodeToAssistantRoute}`, data)
                        .then((resp: any) => {
                            if (resp.data?.success) {
                                toast.success(t('responseMsg.msg_qrCode'));
                                setRokidInviteSent(true);
                            } else {
                                toast.error(resp?.data?.error);
                                setRokidInviteSent(false);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            toast.error(err?.response?.data?.message);
                        });
                }
                setIsAssistantInOrg(false);
                setRokidInviteDialog(false);
            } else {
                toast.warning(t('validation.msg_asstNotEnoughData'));
            }
        }
    };

    const handleCloseRokidInviteDialog = () => {
        setIsAssistantInOrg(false);
        setRokidInviteDialog(false);
    }

    const handleChangeBox = (event: any) => {
        const userName = event.target.value;
        setSelectedAst((prevSelectedAst) =>
            prevSelectedAst === userName ? null : userName
        );
    };

    const GetAllAssistantList = async () => {
        setLoading(true);
        const params = {
            role: ROLE.Assistant,
            orgID: user.orgId
        };
        await apis.instance
            .get(`${AllSubscribedUserList}`, { params })
            .then((resp: any) => {
                if (resp.data?.success) {
                    if (resp?.data?.data.length > 0) {
                        let response = resp?.data?.data.map((item: any) => {
                            return {
                                ...item,
                                full_name: `${item.user_fname} ${item.user_lname ?? ""}`,
                            };
                        });
                        const nonDeletedAssistants = GetNonDeletedUser(response, user.userId);
                        setAssistantList(nonDeletedAssistants);
                        const onlineAssistantList = nonDeletedAssistants.filter(
                            (user) =>
                                userStatus.current?.find(
                                    (status: any) => status.username === user.user_name
                                )?.status === "online"
                        );

                        const offlineAssistantList = nonDeletedAssistants.filter(
                            (user) =>
                                userStatus.current?.find(
                                    (status: any) => status.username === user.user_name
                                )?.status !== "online"
                        );

                        const sortedAssistantList = onlineAssistantList.concat(
                            offlineAssistantList
                        );
                        setAssistantList(sortedAssistantList);
                        setIsAssistantInOrg(true);
                    }
                    else {
                        toast.warning(t('responseMsg.msg_noAssistantHasAdded'));
                        setIsAssistantInOrg(false);
                        setRokidInviteDialog(false);
                    }
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                if (err?.response.status !== 401) toast.error(err.response?.data?.message)
                console.log(err);
            });
    }

    return (
        <Dialog fullWidth className="invite-rokid-dialog"
            open={rokidInviteDialog && isConferenceCall && isAssistantInOrg}
            onClose={() => { return null }}
            aria-labelledby="invite-ROKID-dialog-title"
            aria-describedby="invite-ROKID-dialog-description"
            disableEnforceFocus
            disableAutoFocus
        >
            <DialogTitle id="invite-ROKID-dialog-title" >
                {t('lbl_inviteAssistant')}
            </DialogTitle>
            <DialogContent>
                <Stack spacing={1} >
                    {loading ? <Box justifyContent="center" paddingY={2}><CadisLoader color='primary' size={32} /></Box> : ''}
                    {assistantList
                        .map((user: any) => (
                            <div key={user.user_id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id='user_name'
                                            name='user_name'
                                            value={user.user_name}
                                            onChange={(event) => { handleChangeBox(event); }}
                                            checked={selectedAst === user.user_name}
                                            disabled={selectedAst !== null && selectedAst !== user.user_name}
                                            sx={{ marginRight: 3 }}
                                        />
                                    }
                                    label={
                                        <ListItem disablePadding>
                                            <ListItemAvatar>
                                                <Badge
                                                    overlap="circular"
                                                    title={
                                                        userStatus.current?.filter(
                                                            (item: any) => item.username === user.user_name
                                                        )[0]?.username === user.user_name
                                                            ? userStatus.current?.filter(
                                                                (item: any) => item.username === user.user_name
                                                            )[0]?.status
                                                            : "offline"
                                                    }
                                                    color={
                                                        userStatus.current?.filter(
                                                            (item: any) => item.username === user.user_name
                                                        )[0]?.status !== "online"
                                                            ? userStatus.current?.filter(
                                                                (item: any) => item.username === user.user_name
                                                            )[0]?.status !== "busy"
                                                                ? "secondary"
                                                                : "error"
                                                            : "success"
                                                    }
                                                >
                                                    <Avatar
                                                        {...stringAvatar(user.full_name)}
                                                        src={user.user_profile_img}
                                                    />
                                                </Badge>
                                            </ListItemAvatar>
                                            <ListItemText
                                                sx={{ textTransform: "capitalize" }} className="long-col-name-ellipsis"
                                                primary={`${user.full_name}`}
                                                secondary=""
                                            />
                                        </ListItem>
                                    }
                                />
                            </div>
                        ))
                    }
                </Stack>
            </DialogContent>
            <DialogActions>
                <>
                    <Button variant="contained" disabled={!selectedAst?.length} color="success" onClick={handleSendInviteToRokid}>
                        {t('lbl_invite')}
                    </Button>
                    <Button variant="contained" color="error" onClick={handleCloseRokidInviteDialog}>
                        {t('lbl_cancel')}
                    </Button>
                </>
            </DialogActions>
        </Dialog>
    )
}

export default AssistantListForCalling;