import { Box, Checkbox, Container, Drawer, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Select, Stack, Switch, Typography } from "@mui/material";
import { auto } from "@popperjs/core";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ApplyDiscountCouponRoute, AssignSubscriptionPlanToOrgRoute, RenewSubscriptionPlanForOrgRoute, UpdateSubscriptionPlanForOrgRoute } from "../../Common/api.routes";
import * as Helper from '../../Common/helper';
import { FormAddMode, FormRenewMode, FormUpdateMode, paymentMode } from "../../Common/helper";
import { getAccuratePrice, isAuthenticated } from "../../Common/helperFunction";
import { orgSubscriptionValidationSchema } from "../../Common/validationSchemas";
import CadisButton from "../../Components/Input/CadisButton";
import CadisTextField from "../../Components/Input/CadisTextField";
import CadisLoader from "../../Components/Loading/CadisLoader";
import apis from "../../HttpConfig/Api";
import { ISubscriptionDetails } from "../../Interfaces/ISubscription";

const AddEditOrgSubscriptionDetails = (
    { isOpenOrgSubscriptionDetails,
        setIsOpenOrgSubscriptionDetails,
        currentOrgSubscriptionDetails,
        allSubcriptionPlans,
        mode,
        currentOrgData,
        GetOrgSubscriptionPlanDetails
    }
        :
        {
            isOpenOrgSubscriptionDetails: boolean,
            setIsOpenOrgSubscriptionDetails: any,
            currentOrgSubscriptionDetails: ISubscriptionDetails,
            mode: string, allSubcriptionPlans: any,
            currentOrgData: any,
            GetOrgSubscriptionPlanDetails: any
        }) => {
    const [loading, setLoading] = useState(false);
    const [couponMessage, setCouponMessage] = useState('');
    const [couponStatus, setCouponStatus] = useState(false);
    const [couponCode, setCouponCode] = useState('');
    const user = isAuthenticated();
    const [initialSubscriptionCount, setInitialSubscriptionCount] = useState(currentOrgSubscriptionDetails.subscription_count);
    const [initialplan, setInitialPlan] = useState(currentOrgSubscriptionDetails.plan_id);
    const [subscriptionChanged, setSubscriptionChanged] = useState(mode === FormUpdateMode);
    const [discountPrice, setDiscountPrice] = useState<string>('');
    const [selectedPlanDetails, setSelectedPlanDetails] = useState<any>();
    const [couponValue, setCouponValue] = useState('');
    const { t } = useTranslation();

    const { values, dirty, handleReset, handleSubmit, handleChange, touched, errors, setFieldValue, resetForm } = useFormik({
        initialValues: {
            subscriptionId: currentOrgSubscriptionDetails.subscription_id,
            orgId: currentOrgData.orgId,
            subscriptionPlan: mode === FormAddMode ? allSubcriptionPlans[0].plan_id : currentOrgSubscriptionDetails.plan_id,
            subscriptionCount: currentOrgSubscriptionDetails.subscription_count,
            userCount: currentOrgSubscriptionDetails.user_count,
            paymentMode: currentOrgSubscriptionDetails.payment_mode,
            paidAmount: mode === FormRenewMode ? getOriginalPrice() : currentOrgSubscriptionDetails.paid_amount,
            subscriptionStatus: !currentOrgSubscriptionDetails.is_disabled,
            isInvoice: currentOrgSubscriptionDetails.send_invoice_flag,
            couponCode: '',
            data_limit: currentOrgSubscriptionDetails.data_limit / 1024,
            dataPerMonth: mode === FormAddMode ? "0" : currentOrgSubscriptionDetails.plan_data_limit,
            is_data_unlimited: currentOrgSubscriptionDetails.is_data_unlimited
        },
        validationSchema: orgSubscriptionValidationSchema,
        enableReinitialize: true,
        onSubmit: async (values: any, props: any) => {
            setLoading(true);
            AddUpdateSubscriptionPlanToOrgApiCall();
        },
    })

    const onFormSubmitComplete = (resp: any) => {
        if (resp.data.success) {
            handleReset(values);
            setIsOpenOrgSubscriptionDetails(false);
            toast.success(` ${mode === FormAddMode ? t('lbl_assignNewSubscription') : mode === FormUpdateMode ? t('lbl_updateSubscription') : t('lbl_renewSubscriptionPlan')} ${t('lbl_successfully')}`);
            GetOrgSubscriptionPlanDetails();
            clearStateVaules();
            setLoading(false);
            setCouponValue("")
        } else {
            if (resp.data.error == "Invalid coupon code.") {
                toast.error(t("lbl_couponCodeNotAvailable"));
            }
            if (resp.data.error.toLowerCase() === "plan is not active.") {
                toast.error(t("lbl_planDisabled"))
            }
            setLoading(false);
        }
    }

    const startDate: Date = new Date(currentOrgSubscriptionDetails.start_date);
    const currentDate: Date = new Date();
    const timeDifference: Date = new Date(startDate.getTime() + 24 * 60 * 60 * 1000);
    const isDisabled = currentDate > timeDifference || currentOrgSubscriptionDetails.payment_mode === "Online";

    const AddUpdateSubscriptionPlanToOrgApiCall = async () => {
        const SubscriptionData = {
            'org_id': currentOrgData.orgId,
            'user_id': user.userId,
            'plan_id': values.subscriptionPlan,
            'subscription_id': values.subscriptionId,
            'subscription_count': values.subscriptionCount,
            'user_count': values.userCount,
            'payment_mode': values.paymentMode,
            'paid_amount': values.paidAmount,
            'disableFlag': !values.subscriptionStatus,
            'send_Invoice_flag': values.isInvoice,
            "data_limit": values.is_data_unlimited ? 0 : values.data_limit,
            "is_data_unlimited": values.is_data_unlimited,
            "discount_code": couponValue,
        }
        try {
            if (mode === Helper.FormAddMode) {
                await apis.instance.post(`${AssignSubscriptionPlanToOrgRoute}`, SubscriptionData)
                    .then((resp: any) => {
                        onFormSubmitComplete(resp);
                    })
                    .finally(() => setLoading(false));
            } else if (mode === Helper.FormUpdateMode) {
                if (values.userCount < currentOrgSubscriptionDetails.active_user_count) {
                    setLoading(false);
                    handleReset(values);
                    toast.warning(t("lbl_cantReduceNumberOfSubscription") + currentOrgSubscriptionDetails.active_user_count + t("lbl_userAssigned"));
                } else {
                    await apis.instance.put(`${UpdateSubscriptionPlanForOrgRoute}`, SubscriptionData)
                        .then((resp: any) => {
                            onFormSubmitComplete(resp);
                        })
                        .finally(() => setLoading(false));
                }
            } else if (mode === Helper.FormRenewMode) {
                await apis.instance.put(`${RenewSubscriptionPlanForOrgRoute}`, SubscriptionData)
                    .then((resp: any) => {
                        onFormSubmitComplete(resp);
                    })
                    .finally(() => setLoading(false));
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(t('responseMsg.msg_wrongEntry'));
            console.log(error.message);
        }
    }

    useEffect(() => {
        setInitialSubscriptionCount(currentOrgSubscriptionDetails.subscription_count);
        setInitialPlan(currentOrgSubscriptionDetails.plan_id);
        () => { setCouponValue(''); }
    })
    useEffect(() => {
        if (mode === FormUpdateMode) {
            setSubscriptionChanged((parseFloat(values.subscriptionCount.toString()) !== initialSubscriptionCount) ||
                values.subscriptionPlan !== initialplan);

            if ((parseFloat(values.subscriptionCount.toString()) == initialSubscriptionCount) && values.subscriptionPlan == initialplan)
                setFieldValue('paidAmount', currentOrgSubscriptionDetails.paid_amount);
        }
    }, [values.subscriptionCount, values.subscriptionPlan, initialSubscriptionCount, mode]);

    function getOriginalPrice() {
        const selectedPlanData = allSubcriptionPlans.find((plan: any) => plan.plan_id === currentOrgSubscriptionDetails.plan_id);
        let { total_price } = selectedPlanData;
        const paidAmount = parseInt(currentOrgSubscriptionDetails.subscription_count.toString()) * total_price;
        return paidAmount;
    }

    const calculateUserData = (selectedPlan: number, SubscriptionsCount: number) => {
        const selectedPlanData = allSubcriptionPlans.find((plan: any) => plan.plan_id === selectedPlan);
        if (selectedPlanData && selectedPlanData.plan_id > 0) {
            const { max_user_bundle_count, total_price, data_limit } = selectedPlanData;
            const userCount = SubscriptionsCount * max_user_bundle_count;
            const paidAmount = SubscriptionsCount * total_price;
            const totalSubscriptionData = (SubscriptionsCount * data_limit).toFixed(2);
            const totalData = parseFloat(totalSubscriptionData);
            return { userCount, paidAmount, totalData };
        }

        return { userCount: 0, paidAmount: 0, data_limit: 0 };
    };

    function clearStateVaules() {
        setCouponStatus(false);
        setCouponMessage('');
        setCouponCode('');
        setDiscountPrice('');
        setFieldValue('couponCode', '');
        setCouponValue("")
    }

    const handleInputChange = (e: any) => {
        let inputValue = e.target.value;
        inputValue = inputValue.replace(/\s/g, '');
        if (inputValue === '' || inputValue === '0') {
            setFieldValue('subscriptionCount', '');
            setFieldValue('userCount', 0);
            setFieldValue('paidAmount', 0);
            setFieldValue('data_limit', 0);
            clearStateVaules();
            return;
        }
        if (selectedPlanDetails?.plan_id) {
            setFieldValue("dataPerMonth", selectedPlanDetails?.data_limit)
        }
        if (inputValue.length > 1 && inputValue[0] === '0') {
            inputValue = inputValue.slice(1);
        }
        if (!/^\d+$/.test(inputValue)) {
            return;
        }

        const newValue = parseFloat(inputValue);
        const calculatedValue = calculateUserData(values.subscriptionPlan, newValue);
        setFieldValue('userCount', calculatedValue.userCount);
        setFieldValue('paidAmount', calculatedValue.paidAmount);
        setFieldValue('data_limit', calculatedValue.totalData);
        clearStateVaules();
        //used to trigger logic associated with the error & handleInputChange.
        handleChange(e);
    };

    const handleCouponCodeChange = (e: any) => {
        setCouponMessage('');
        handleChange(e);
    }

    const handleFormReset = (e: any) => {
        clearStateVaules();
        handleReset(e);
    }

    const handleFormClose = () => {
        resetForm()
        setIsOpenOrgSubscriptionDetails(false)
        clearStateVaules();
    }

    const handleCouponClear = () => {
        const calculatedValue = calculateUserData(values.subscriptionPlan, parseFloat(values.subscriptionCount.toString()));
        setFieldValue('paidAmount', calculatedValue.paidAmount);
        clearStateVaules();
    }

    const applyCouponCOde = async () => {
        try {
            const params = {
                "code": values.couponCode
            }
            await apis.instance.get(`${ApplyDiscountCouponRoute}`, { params })
                .then((resp: any) => {
                    if (resp.data.success) {
                        const coupon = resp.data.data[0].discount_code;
                        let discountPercentage = resp.data.data[0].discount_percentage;
                        const totalAmount = (values.paidAmount - (values.paidAmount * discountPercentage) / 100).toFixed(2);
                        const formattedTotalAmount = parseFloat(totalAmount);
                        const discountAmount = values.paidAmount - formattedTotalAmount;
                        setDiscountPrice(parseFloat(discountAmount.toFixed(2)) + ' (' + resp.data.data[0].discount_percentage + '% Off)');
                        const couponMessage = t('responseMsg.msg_couponCodeSuccessMessage');
                        setCouponStatus(true);
                        setCouponCode(coupon);
                        setCouponMessage(couponMessage);
                        setCouponValue(values.couponCode);
                        setFieldValue('couponCode', '');
                        setFieldValue('paidAmount', formattedTotalAmount);
                    } else {
                        if (resp.data.error === "discount code not found") {
                            const couponMessage = t('responseMsg.msg_couponCodeErrorMessage');
                            setCouponStatus(false);
                            setCouponMessage(couponMessage);
                        }
                    }
                })
                .finally(() => setLoading(false));
        }
        catch (error: any) {
            setLoading(false);
            toast.error(t('responseMsg.msg_wrongEntry'));
            console.log(error.message);
        }
    }

    const handleValueCalculations = (planId: number) => {
        const selectedPlan = allSubcriptionPlans.find((i: any) => i.plan_id === planId)
        setSelectedPlanDetails(selectedPlan)
        if (selectedPlan.is_data_unlimited) {
            setFieldValue('is_data_unlimited', true)
        } else {
            setFieldValue('is_data_unlimited', false)
        }

        if (values.subscriptionCount) {
            setFieldValue('dataPerMonth', selectedPlan?.data_limit)
        }
    }

    const filterAllSubscriptionPlans = (): [] => {
        console.log(allSubcriptionPlans, "subscriptionPlan")
        if (mode === FormAddMode) {
            return allSubcriptionPlans.filter((i: any) => i?.plan_status === 1 || i.plan_id === 0);
        } else {
            return allSubcriptionPlans
        }
    }

    return (
        <>
            <Drawer
                anchor='right'
                open={isOpenOrgSubscriptionDetails}
                onClose={() => { return null }}
            >
                <Box role="presentation">
                    <Box sx={{ pt: 1.5, position: 'sticky', top: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }} >
                        <Container sx={{ alignItems: 'center', display: 'flex' }} disableGutters>
                            <Box sx={{ width: '40px', height: '40px', flexGrow: 1, display: { xs: 'flex', pr: 2 } }}>
                                <IconButton onClick={handleFormClose} sx={{}} className='c-pointer'>
                                    <span className='cad-close'></span>
                                </IconButton>
                            </Box>
                        </Container>
                        <Box sx={{ mb: 2, pb: 2, mt: 2, display: 'flex' }}>
                            <Typography className="mt-0 mb-0 slide-right-title" sx={(theme) => theme.typography.h5}>
                                {
                                    mode === FormAddMode
                                        ? t('lbl_assignSubscription')
                                        : mode === FormRenewMode
                                            ? t('lbl_renewSubscription')
                                            : t('lbl_updateAssignedSubscription')
                                }
                            </Typography>
                        </Box>
                    </Box>
                    <Grid container width={'100%'}>
                        <form onSubmit={handleSubmit} onReset={handleFormReset} className="w-100p">
                            <Stack spacing={2} direction={'column'} paddingX={'20px'}>
                                <Grid container columnSpacing={1} rowSpacing={0} rowGap={0} >
                                    <Grid item xs={6} sm={6}>
                                        <FormControl fullWidth variant="outlined" size="small" disabled={isDisabled || mode === FormRenewMode || currentOrgSubscriptionDetails.is_disabled}>
                                            <label className={isDisabled || mode === FormRenewMode || currentOrgSubscriptionDetails.is_disabled ? 'form-label mui-disabled' : 'form-label'} >
                                                {t('lbl_planName')}
                                                <span className='mandatory-star'></span>
                                            </label>
                                            <Select fullWidth
                                                labelId='PlanName'
                                                id="subscriptionPlan"
                                                name='subscriptionPlan'
                                                value={values.subscriptionPlan}
                                                disabled={isDisabled || mode === FormRenewMode || currentOrgSubscriptionDetails.is_disabled}
                                                onChange={(e) => {
                                                    const selectedPlanId = e.target.value;
                                                    const calculatedValue = calculateUserData(selectedPlanId, +values.subscriptionCount);
                                                    handleChange(e);
                                                    handleValueCalculations(selectedPlanId)
                                                    setFieldValue('subscriptionPlan', selectedPlanId);
                                                    setFieldValue('userCount', calculatedValue.userCount);
                                                    setFieldValue('paidAmount', calculatedValue.paidAmount);
                                                    setFieldValue('data_limit', calculatedValue.totalData);
                                                    clearStateVaules();
                                                }}
                                            >
                                                {filterAllSubscriptionPlans().map((item: any, index: any) => (
                                                    <MenuItem value={item.plan_id} key={index}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.subscriptionPlan && touched.subscriptionPlan ? (
                                                <Typography variant="caption" color="error">
                                                    {t(errors.subscriptionPlan as any)}
                                                </Typography>) : null}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <FormControl variant="outlined" size="small" disabled={isDisabled || mode === FormRenewMode || currentOrgSubscriptionDetails.is_disabled}>
                                            <label id="userTitleLabelID" className={isDisabled || mode === FormRenewMode || currentOrgSubscriptionDetails.is_disabled ? 'form-label mui-disabled' : 'form-label'}>
                                                {t('lbl_noOfSubscriptions')}
                                                <span className='mandatory-star'></span>
                                            </label>
                                            <CadisTextField
                                                autoComplete='false'
                                                id="subscriptionCount"
                                                name="subscriptionCount"
                                                value={values.subscriptionCount}
                                                onChange={handleInputChange}
                                                error={touched.subscriptionCount && Boolean(errors.subscriptionCount)}
                                                helperText={touched.subscriptionCount && t(`${errors.subscriptionCount ? errors.subscriptionCount : ''}`)}
                                                disabled={isDisabled || mode === FormRenewMode || currentOrgSubscriptionDetails.is_disabled}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <FormControl variant="outlined" disabled>
                                    <label className='form-label mui-disabled'>
                                        {t('lbl_usersCount')}
                                        <span className='mandatory-star'></span>
                                    </label>
                                    <CadisTextField disabled
                                        autoComplete='false'
                                        id="userCount"
                                        name="userCount"
                                        value={values.userCount}
                                    />
                                </FormControl>
                                {selectedPlanDetails?.is_data_unlimited || values.is_data_unlimited ?
                                    <FormControl variant="outlined" disabled>
                                        <label className='form-label mui-disabled'>
                                            {t('lbl_dataUsageFor')} {selectedPlanDetails?.billing_cycle > 0 ? selectedPlanDetails?.billing_cycle : currentOrgSubscriptionDetails.billing_cycle} {t("lbl_dataMonthInGb")}
                                            <span className='mandatory-star'></span>
                                        </label>
                                        <CadisTextField disabled
                                            value={t("lbl_unlimited")}
                                        />
                                    </FormControl>
                                    : <><FormControl variant="outlined" disabled>
                                        <label className='form-label mui-disabled'>
                                            {t('lbl_dataUsageFor')} {selectedPlanDetails?.billing_cycle > 0 ? selectedPlanDetails?.billing_cycle : currentOrgSubscriptionDetails.billing_cycle} {t("lbl_dataMonthInGb")}
                                            <span className='mandatory-star'></span>
                                        </label>
                                        <CadisTextField disabled
                                            autoComplete='false'
                                            id="dataPerMonth"
                                            name="dataPerMonth"
                                            value={(parseFloat(values.dataPerMonth))}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end" sx={{ fontSize: '1.2rem' }}>
                                                        GB
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />

                                    </FormControl>
                                        <FormControl variant="outlined" disabled>
                                            <label className='form-label mui-disabled'>
                                                {t('lbl_totaldatausage')}
                                                <span className='mandatory-star'></span>
                                            </label>
                                            <CadisTextField
                                                disabled
                                                autoComplete='false'
                                                id="data_limit"
                                                name="data_limit"
                                                value={values.data_limit}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end" sx={{ fontSize: '1.2rem' }}>
                                                            GB
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </FormControl></>}
                                {currentOrgSubscriptionDetails?.isPlanExpired && !values.is_data_unlimited && currentOrgSubscriptionDetails.totalDataOver > 0 ? <FormControl variant="outlined" size="small">
                                    <label id="noOfUserLabelID" className='form-label'>{t("lbl_totalRolloverData")}</label>
                                    <CadisTextField disabled value={currentOrgSubscriptionDetails.totalDataOver} name="dataLimit" />
                                </FormControl> : ""}
                                {!currentOrgSubscriptionDetails.is_disabled &&
                                    <Grid container columnSpacing={0} rowSpacing={0} rowGap={0} columnGap={1} alignItems={"flex-end"}>
                                        <Grid item xs>
                                            <FormControl variant="outlined" fullWidth>
                                                <label className='form-label '>
                                                    {t('lbl_couponCode')}
                                                </label>
                                                <CadisTextField
                                                    autoComplete='false'
                                                    id="couponCode"
                                                    name="couponCode"
                                                    value={values.couponCode}
                                                    disabled={mode == FormUpdateMode ? !subscriptionChanged || values.subscriptionPlan < 1 || couponStatus : (!values.subscriptionCount || values.subscriptionPlan < 1 || couponStatus)}
                                                    onChange={handleCouponCodeChange}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <CadisButton size="large" variant="contained" color='primary' disabled={loading || !values.couponCode}
                                                onClick={applyCouponCOde}>
                                                {t('lbl_apply')}
                                            </CadisButton>
                                        </Grid>
                                        <Grid item xs={12} mt={1}>
                                            <Stack>
                                                {couponStatus && <Grid container alignItems="center" className='access-permission-box' width={"100%"} marginLeft={0} columnGap={1} paddingX={2} paddingY={1} sx={{ borderColor: 'success.main' }}>
                                                    <Grid xs={auto}>
                                                        <span className='cad-tick-icon text-secondary mr-3'></span>
                                                    </Grid>
                                                    <Grid xs>
                                                        <Typography component="div" variant="body1" alignSelf="flex-start">
                                                            <strong>{couponCode}</strong>&nbsp;{couponMessage}
                                                            <Typography variant="body2" color="GrayText">-${discountPrice}</Typography>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid xs={auto}>
                                                        <CadisButton onClick={handleCouponClear} color="error">{t('lbl_remove')}</CadisButton>
                                                    </Grid>
                                                </Grid>}
                                                {(couponMessage && !couponStatus) &&
                                                    <Typography color='error' marginTop={-0.5} fontSize={'0.75rem'} className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained">
                                                        {couponMessage}
                                                    </Typography>
                                                }
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                }
                                <FormControl variant="outlined" disabled>
                                    <label className='form-label mui-disabled'>
                                        {t('lbl_totalAmount')}
                                        <span className='mandatory-star'></span>
                                    </label>
                                    <CadisTextField
                                        autoComplete='false'
                                        id="paidAmount"
                                        name="paidAmount"
                                        value={getAccuratePrice(values.paidAmount)}
                                        disabled
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" sx={{ fontSize: '1.2rem' }}>
                                                    <span className='cad-dollar'></span>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                                <FormControl variant="outlined" size="small">
                                    <label className={mode === FormUpdateMode ? 'form-label mui-disabled' : 'form-label'}>
                                        {t('lbl_paymentMode')}
                                        <span className='mandatory-star'></span>
                                    </label>
                                    <Select fullWidth
                                        labelId='PaymentMode'
                                        id='paymentMode'
                                        name='paymentMode'
                                        value={values.paymentMode}
                                        onChange={handleChange}
                                        disabled={mode === FormUpdateMode}
                                    >
                                        {paymentMode.map((item, index) => { return <MenuItem value={item} key={index}>{item}</MenuItem> })}
                                    </Select>
                                    {errors.paymentMode && touched.paymentMode ? (
                                        <Typography variant="caption" color="error">
                                            {t(errors.paymentMode)}
                                        </Typography>) : null}
                                </FormControl>
                                {mode === FormUpdateMode ?
                                    <FormControl>
                                        <label id="userTitleLabelID" className='form-label'>{t('lbl_permission')}</label>
                                        <Box className='access-permission-box' sx={{ px: 2, py: 1 }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        color='success'
                                                        id="subscriptionStatus"
                                                        name="subscriptionStatus"
                                                        checked={Boolean(values.subscriptionStatus)}
                                                        onChange={(event: any, checked: boolean) => {
                                                            setFieldValue("subscriptionStatus", checked)
                                                        }}
                                                    />
                                                }
                                                label={`${values.subscriptionStatus ? t('lbl_disable') : t('lbl_enable')} ${t('lbl_subscription')}`}
                                            />
                                        </Box></FormControl> : null}
                                <FormControl >
                                    <FormControlLabel control={
                                        <Checkbox
                                            id='Invoice'
                                            name='isInvoice'
                                            value={values.isInvoice}
                                            checked={(Boolean(values.isInvoice) && !isDisabled) || mode === FormRenewMode}
                                            disabled={isDisabled || mode === FormRenewMode}
                                            onChange={(event: any, checked: boolean) => {
                                                setFieldValue("isInvoice", checked);
                                            }}
                                        />
                                    } label={t('lbl_sendInvoicebyEmail')}
                                    />
                                </FormControl>
                            </Stack>
                            <Box sx={{ pt: 2, pb: 2, position: 'sticky', bottom: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }}>
                                <Grid container spacing={0} alignItems="center"  >
                                    <Grid item xs="auto" >
                                        <CadisButton type='submit' variant="contained" color='primary' disabled={mode === 'update' && !dirty || loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
                                            {
                                                mode === FormAddMode ? 'Assign'
                                                    : mode === FormUpdateMode ? 'Update'
                                                        : mode === FormRenewMode ? 'Renew'
                                                            : ''
                                            }
                                        </CadisButton>
                                    </Grid>
                                    <Grid item xs="auto">
                                        {mode !== FormRenewMode ?
                                            <Box sx={{ pl: 1 }}>
                                                <CadisButton type='reset' variant="contained" color='error' disabled={mode === 'update' && !dirty || loading}>
                                                    {mode === FormUpdateMode ? t("lbl_reset") : `${t('lbl_clear')}`}
                                                </CadisButton>
                                            </Box> : null}
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    </Grid>
                </Box>
            </Drawer>
        </>
    );
}
export default AddEditOrgSubscriptionDetails;