import { Avatar, Box, Button, Container, Drawer, FormControl, Grid, IconButton, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import jwtDecode from "jwt-decode";
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import userProfile from "../../Assets/Images/userProfile.svg";
import { UpdateUserRoute } from "../../Common/api.routes";
import { ActiveStatusID, UserTitles, allowedProfileImgExtensions, countries, maxPhoneLength, minPhoneLength, numberAndDashRegex, phoneNumberLengthValidationMessage, phoneNumberStartEndHyphenValidationMessage, } from '../../Common/helper';
import { DisplayProfileImageValidationMessage, ValidateFileSize, ValidateFileType, fileToBase64, getUser, isAuthenticated } from '../../Common/helperFunction';
import { UserProfileValidationSchema } from '../../Common/validationSchemas';
import CadisButton from '../../Components/Input/CadisButton';
import CadisTextField from '../../Components/Input/CadisTextField';
import CadisLoader from '../../Components/Loading/CadisLoader';
import apis from "../../HttpConfig/Api";
import { ROLE } from "../../Interfaces/IConstants";
import { IUser } from "../../Interfaces/IUser";
import { useSocketContext } from '../../context/SocketContext';
import { useUserRoleContext } from '../../context/UserRoleContext';

const UserProfile = ({
  isOpenUpdateUserProfile,
  setIsOpenUpdateUserProfile,
  userDetails,
  consultantSpecialities,
  GetUserDetails,
  setUserName,
}: {
  isOpenUpdateUserProfile: boolean;
  setIsOpenUpdateUserProfile: any;
  userDetails: IUser;
  consultantSpecialities: any[];
  GetUserDetails: any;
  setUserName: any;
}) => {
  const [loading, setLoading] = useState(false);
  const [defaultSpeciality, setSpeciality] = useState({
    speciality_id: "0",
    speciality: "",
  });
  const { userLoginRole, handleToggleLoader } = useUserRoleContext();
  const [localRole, setLocalRole] = useState(userLoginRole);
  const { roles } = isAuthenticated();
  const { setUserDetails } = useSocketContext();
  const [userStatus, setUserStaus] = useState(roles.length > 1 ? true : false);
  const { t } = useTranslation();
  let profileImgRef = useRef<any>();
  let localUser = isAuthenticated();
  let roleID = ROLE.CadisAdmin;
  if (localUser.isOrgAdmin) {
    roleID = ROLE.OrgAdmin;
  } else if (localUser.isConsultant) {
    roleID = ROLE.Consultant;
  } else if (
    !localUser.isOrgAdmin &&
    !localUser.isConsultant &&
    !localUser.isCadisAdmin
  ) {
    roleID = ROLE.Assistant;
  }

  useEffect(() => {
    const temp: any = Number(sessionStorage.getItem("role"));
    if (temp) {
      setLocalRole(temp);
    } else {
      setLocalRole(userLoginRole);
    }
  }, [userLoginRole])

  const ValidatePhoneNumbers = (
    phoneM: string,
    phoneNM: string,
    phoneMFieldName: string,
    phoneNMFieldName: string
  ) => {
    let isPhoneNumberValid = true;
    const orgPhoneMFirstChar = phoneM.charAt(0);
    const orgPhoneMlastChar = phoneM.charAt(phoneM.length - 1);
    if (orgPhoneMFirstChar === "-" || orgPhoneMlastChar === "-") {
      setFieldError(
        phoneMFieldName,
        phoneNumberStartEndHyphenValidationMessage
      );
      isPhoneNumberValid = false;
    }

    const orgPhoneMTextWithoutHyphens = phoneM.replace(/-/g, "");
    if (
      orgPhoneMTextWithoutHyphens.length < minPhoneLength ||
      orgPhoneMTextWithoutHyphens.length > maxPhoneLength
    ) {
      setFieldError(phoneMFieldName, phoneNumberLengthValidationMessage);
      isPhoneNumberValid = false;
    }

    if (phoneNM && phoneNM.length > 0) {
      const orgPhoneNMFirstChar = phoneNM.charAt(0);
      const orgPhoneNMlastChar = phoneNM.charAt(phoneNM.length - 1);
      if (orgPhoneNMFirstChar === "-" || orgPhoneNMlastChar === "-") {
        setFieldError(
          phoneNMFieldName,
          phoneNumberStartEndHyphenValidationMessage
        );
        isPhoneNumberValid = false;
      }

      const orgPhoneNMTextWithoutHyphens = phoneNM.replace(/-/g, "");
      if (
        orgPhoneNMTextWithoutHyphens.length < minPhoneLength ||
        orgPhoneNMTextWithoutHyphens.length > maxPhoneLength
      ) {
        setFieldError(phoneNMFieldName, phoneNumberLengthValidationMessage);
        isPhoneNumberValid = false;
      }
    }
    return isPhoneNumberValid;
  };

  const {
    values,
    dirty,
    handleReset,
    handleSubmit,
    handleChange,
    touched,
    errors,
    setFieldValue,
    setFieldError,
    resetForm,
  } = useFormik({
    initialValues: {
      orgName: userDetails.org_name,
      userTitle: userDetails.org_admin_title,
      userFName: userDetails.org_admin_fname,
      userLName: userDetails.org_admin_lname,
      userEmail: userDetails.org_admin_email,
      userUsername: userDetails.org_admin_username,
      userPhoneM: userDetails.org_admin_phone_m,
      userPhoneNM: userDetails.org_admin_phone_nm,
      userProfileImg: userDetails.profile_image,
      countryCode: userDetails.country_code,
      speciality:
        userDetails.specialities.length === 0
          ? defaultSpeciality.speciality_id
          : userDetails.specialities[0].speciality_id,
      isSpeciality: localRole === ROLE.Consultant,
    },
    enableReinitialize: true,
    validationSchema: UserProfileValidationSchema,
    onSubmit: async (values: any, props: any) => {
      const arePhoneNumbersValid = ValidatePhoneNumbers(
        values.userPhoneM,
        values.userPhoneNM,
        "userPhoneM",
        "userPhoneNM"
      );
      if (!arePhoneNumbersValid) {
        return;
      }

      setLoading(true);
      UpdateUserDetails();
    },
  });

  const onHandleAPIError = (error: any) => {
    setLoading(false);
    toast.error(t("responseMsg.msg_wrongEntry"));
    console.log(error.message);
  };

  const handleRefresh = () => {
    sessionStorage.setItem('successMessage', 'Profile updated');
    window.location.reload();
  };

  const onFormSubmitComplete = (userDetails: any) => {
    setIsOpenUpdateUserProfile(false);
    sessionStorage.setItem("token", userDetails.data?.data?.token);
    const encodedUser: any = getUser();
    const localUser: any = jwtDecode(encodedUser);
    const username = `${localUser.user.user_fname} ${localUser.user.user_lname ?? ''}`;
    setUserName(username);
    sessionStorage.setItem("username", JSON.stringify(localUser.user.user_name));
    let userRole: number | null = parseInt(JSON.parse(sessionStorage.getItem('role')!));
    setUserDetails({ username: username, userrole: userRole });
    GetUserDetails();
    handleRefresh();
    setLoading(false);
  };

  async function UpdateUserDetails() {
    const userData = {
      orgID: userDetails.org_id,
      userID: userDetails.user_id,
      title: values.userTitle,
      fname: values.userFName,
      lname: values.userLName,
      username: values.userUsername,
      email: values.userEmail,
      phoneM: values.userPhoneM,
      phoneNM: values.userPhoneNM,
      role: localRole,
      sendDetailsInEmail: true,
      status: ActiveStatusID,
      user_profile_img: values.userProfileImg,
      country_code: values.countryCode,
      specialityId: values.speciality,
      createID: userDetails.user_id,
      updateID: userDetails.user_id,
      newRoleAssignStatus: userStatus,
      isConferenceCall: false,
      sessionId: 0
    };
    try {
      await apis.instance
        .put(`${UpdateUserRoute}`, userData)
        .then((resp: any) => {
          if (resp.data.success) {
            onFormSubmitComplete(resp);
          } else {
            if (resp.data.error.toLowerCase() === "user with this id already exist. try using another user id")
              toast.error(t(`responseMsg.msg_useralreadyexist`));
            else
              toast.error(resp.data.error);
            setLoading(false);
          }
        })
        .finally(() => setLoading(false));
    } catch (error: any) {
      onHandleAPIError(error);
    }
  }

  const RemoveProfileImg = () => {
    profileImgRef.current.value = "";
    setFieldValue("userProfileImg", "");
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpenUpdateUserProfile}
        onClose={() => {
          return null;
        }}
      >
        <Box role="presentation">
          <Box sx={{ pt: 1.5, position: 'sticky', top: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }}>
            <Container sx={{ alignItems: 'center', display: 'flex' }} disableGutters>
              <Box sx={{ width: '40px', height: '40px', flexGrow: 1, display: { xs: 'flex', pr: 2 } }}>
                <IconButton onClick={() => { setIsOpenUpdateUserProfile(false); }} className='c-pointer'>
                  <span className="cad-close"></span>
                </IconButton>
              </Box>
            </Container>
            <Box sx={{ mb: 2, pb: 2, mt: 2, display: "flex" }}>
              <Typography
                className="mt-0 mb-0 slide-right-title"
                sx={(theme) => theme.typography.h5}
              >
                {t("lbl_myProfile")}
              </Typography>
            </Box>
          </Box>
          <Grid container width={"100%"}>
            <form onSubmit={handleSubmit} onReset={handleReset} style={{ width: "100%" }}>
              <Stack spacing={2} direction={"column"} paddingX={'20px'}>
                <FormControl>
                  {/* <label className='form-label '>Upload Profile Image</label> */}
                  <Stack direction="column">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignSelf: "center",
                      }}
                      className="upload-user-prof-wrapper"
                    >
                      <Avatar
                        alt={values.userFName}
                        src={
                          values.userProfileImg !== ""
                            ? values.userProfileImg
                            : userProfile
                        }
                        sx={{
                          boxShadow: "0 0 1px #AEAEAE",
                          border: "solid 2px #ffffff",
                          width: 100,
                          height: 100,
                        }}
                      />
                    </Box>
                    <Typography
                      color="error"
                      textAlign="center"
                      fontWeight="bold"
                    >
                      <small>{t("lbl_maxFileSize")}</small>
                    </Typography>
                    <Grid
                      container
                      spacing={0}
                      alignItems="center"
                      sx={{ mt: 1, displa: "flex", justifyContent: "center" }}
                    >
                      <Box sx={{ pl: 1 }} className="upload-btn-wrapper">
                        <Button variant="contained" component="label">
                          {t("lbl_uploadPhoto")}
                          <input
                            hidden
                            onChange={async (event: any) => {
                              if (event.currentTarget.files.length > 0) {
                                const file = event.currentTarget.files[0];
                                const isFileSizeValid = ValidateFileSize(file);
                                const isFileTypeValid = ValidateFileType(file);
                                if (!isFileSizeValid || !isFileTypeValid) {
                                  DisplayProfileImageValidationMessage(
                                    isFileTypeValid,
                                    isFileSizeValid
                                  );
                                  setFieldValue("userProfileImg", "");
                                } else {
                                  let base = await fileToBase64(file);
                                  setFieldValue("userProfileImg", base);
                                }
                              } else RemoveProfileImg();
                            }}
                            accept={allowedProfileImgExtensions}
                            id="userProfileImg"
                            name="userProfileImg"
                            type="file"
                            ref={profileImgRef}
                          />
                        </Button>
                      </Box>
                      {values.userProfileImg.length > 0 ? (
                        <Box sx={{ pl: 1 }}>
                          <CadisButton
                            variant="contained"
                            color="error"
                            onClick={() => RemoveProfileImg()}
                          >
                            {t("lbl_delete")}
                          </CadisButton>
                        </Box>
                      ) : null}
                    </Grid>
                  </Stack>
                </FormControl>

                {roleID === ROLE.Assistant ? (
                  <FormControl variant="outlined">
                    <label className="form-label ">
                      {t("lbl_individualName")}
                      <span className="mandatory-star"></span>
                    </label>
                    <CadisTextField
                      autoComplete="false"
                      id="userFName"
                      name="userFName"
                      value={values.userFName.trimStart()}
                      onChange={handleChange}
                      error={touched.userFName && Boolean(errors.userFName)}
                      helperText={
                        touched.userFName &&
                        t(`${errors.userFName ? errors.userFName : ""}`)
                      }
                    />
                  </FormControl>
                ) : (
                  <Box>
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        marginX: "-8px",
                        display: "flex",
                        alignItems: "flex-start",
                      }}
                    >
                      <Grid item xs="auto" width='100px !important'>
                        <FormControl variant="outlined" size="small" fullWidth>
                          <label id="userTitleLabelID" className="form-label ">
                            {t("lbl_title")}{" "}
                            <span className="mandatory-star"></span>
                          </label>
                          <Select fullWidth
                            labelId="userTitleLabelID"
                            id="userTitle"
                            name="userTitle"
                            value={values.userTitle}
                            onChange={handleChange}
                          >
                            {UserTitles.map((title, index) => {
                              return (
                                <MenuItem value={title} key={index}>
                                  {title}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg>
                        <Grid container spacing={1}>
                          <Grid item xs={6} sm={6}>
                            <FormControl>
                              <label
                                id="userTitleLabelID"
                                className="form-label "
                              >
                                {t("lbl_firstName")}{" "}
                                <span className="mandatory-star"></span>
                              </label>
                              <CadisTextField
                                fullWidth
                                autoComplete="false"
                                id="userFName"
                                name="userFName"
                                value={values.userFName.replace(/\s/g, "")}
                                onChange={handleChange}
                                error={
                                  touched.userFName && Boolean(errors.userFName)
                                }
                                helperText={
                                  touched.userFName &&
                                  t(
                                    `${errors.userFName ? errors.userFName : ""
                                    }`
                                  )
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} sm={6}>
                            <FormControl>
                              <label
                                id="userTitleLabelID"
                                className="form-label "
                              >
                                {t("lbl_lastName")}
                              </label>
                              <CadisTextField
                                autoComplete="false"
                                id="userLName"
                                name="userLName"
                                value={values.userLName.replace(/\s/g, "")}
                                onChange={handleChange}
                                error={
                                  touched.userLName && Boolean(errors.userLName)
                                }
                                helperText={
                                  touched.userLName && errors.userLName
                                }
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                <FormControl>
                  <label id="userTitleLabelID" className="form-label ">
                    {t("lbl_email")} <span className="mandatory-star"></span>
                  </label>
                  <CadisTextField
                    id="userEmail"
                    name="userEmail"
                    value={values.userEmail}
                    onChange={handleChange}
                    error={touched.userEmail && Boolean(errors.userEmail)}
                    helperText={
                      touched.userEmail &&
                      t(`${errors.userEmail ? errors.userEmail : ""}`)
                    }
                  />
                </FormControl>
                <FormControl>
                  <label id="userTitleLabelID" className="form-label ">
                    {t("lbl_userId")} <span className="mandatory-star"></span>
                  </label>
                  <CadisTextField
                    autoComplete="false"
                    id="userUsername"
                    name="userUsername"
                    value={values.userUsername}
                    onChange={async (event: any) => {
                      const username = event.currentTarget.value;
                      setFieldValue("userUsername", username.replace(/\s/g, ""));
                    }}
                    error={touched.userUsername && Boolean(errors.userUsername)}
                    helperText={
                      touched.userUsername &&
                      t(`${errors.userUsername ? errors.userUsername : ""}`)
                    }
                  />
                </FormControl>
                {roleID !== ROLE.Assistant && (
                  <FormControl variant="outlined" size="small" fullWidth>
                    <label className="form-label ">
                      {t("lbl_country")}
                      <span className="mandatory-star"></span>
                    </label>
                    <Select fullWidth
                      labelId="userTitleLabelID"
                      id="countryCode"
                      name="countryCode"
                      value={values.countryCode}
                      onChange={handleChange}
                    >
                      {countries.map((item, index) => {
                        return (
                          <MenuItem value={item.code} key={index}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
                <FormControl>
                  <label id="userTitleLabelID" className="form-label ">
                    {t("lbl_phone1")} <span className="mandatory-star"></span>
                  </label>
                  <CadisTextField
                    id="userPhoneM"
                    name="userPhoneM"
                    value={values.userPhoneM}
                    onChange={async (e: any) => {
                      if (e.currentTarget.value.trim().length < 1) {
                        setFieldValue("userPhoneM", e.currentTarget.value);
                      } else if (
                        numberAndDashRegex.test(e.currentTarget.value)
                      ) {
                        setFieldValue("userPhoneM", e.currentTarget.value);
                      }
                    }}
                    error={touched.userPhoneM && Boolean(errors.userPhoneM)}
                    helperText={
                      touched.userPhoneM &&
                      t(`${errors.userPhoneM ? errors.userPhoneM : ""}`)
                    }
                  />
                </FormControl>
                <FormControl>
                  <label id="userTitleLabelID" className="form-label ">
                    {t("lbl_phone2")}
                  </label>
                  <CadisTextField
                    id="userPhoneNM"
                    name="userPhoneNM"
                    value={values.userPhoneNM}
                    onChange={async (e: any) => {
                      if (e.currentTarget.value.trim().length < 1) {
                        setFieldValue("userPhoneNM", e.currentTarget.value);
                      } else if (
                        numberAndDashRegex.test(e.currentTarget.value)
                      ) {
                        setFieldValue("userPhoneNM", e.currentTarget.value);
                      }
                    }}
                    error={touched.userPhoneNM && Boolean(errors.userPhoneNM)}
                    helperText={touched.userPhoneNM && errors.userPhoneNM}
                  />
                </FormControl>
                {localRole === ROLE.Consultant && (
                  <FormControl variant="outlined" size="small" fullWidth>
                    <label className="form-label ">
                      {t("lbl_speciality")}
                      <span className="mandatory-star"></span>
                    </label>
                    <Select fullWidth
                      labelId="userTitleLabelID"
                      id="speciality"
                      name="speciality"
                      value={values.speciality}
                      onChange={handleChange}
                    >
                      <MenuItem value="0">{t("lbl_selectSpeciality")}</MenuItem>
                      {consultantSpecialities.sort((specialityA: any, specialityB: any) => specialityA.speciality_id - specialityB.speciality_id)
                        .map((item: any, index: number) => (<MenuItem value={item.speciality_id} key={index}>
                          {item.speciality}
                        </MenuItem>))}
                    </Select>
                    {errors.speciality && touched.speciality ? (
                      <Typography variant="caption" color="error">
                        {t(errors.speciality)}
                      </Typography>
                    ) : null}
                  </FormControl>
                )}
              </Stack>
              <Box sx={{ pt: 2, pb: 2, position: 'sticky', bottom: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }}>
                <Grid container spacing={0} alignItems="center"  >
                  <Grid item xs="auto" >
                    <CadisButton type='submit' variant="contained" color='primary' disabled={!dirty || loading} endIcon={
                      loading ? (<CadisLoader color="primary" size={14} />) : ("")}>
                      {t("lbl_update")}
                    </CadisButton>
                  </Grid>
                  <Grid item xs="auto">
                    <Box sx={{ pl: 1 }}>
                      <CadisButton type='reset' variant="contained" color='error' disabled={!dirty || loading} onClick={() => setIsOpenUpdateUserProfile(false)}>
                        {t("lbl_cancel")}
                      </CadisButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Grid>
        </Box>
      </Drawer>
    </>
  );
};

export default UserProfile;
