import MicOffIcon from '@mui/icons-material/MicOff';
import { Box, IconButton } from "@mui/material";
import { FC } from 'react';

interface CustomIconButtonProps {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
};

const CadisUnmuteIcon: FC<CustomIconButtonProps> = ({ top = 'auto', right = 'auto', bottom = 'auto', left = 'auto' }) => {
  const boxStyle = {
    position: 'absolute' as const,
    top,
    right,
    bottom,
    left,
  };

  return (
    <Box sx={boxStyle as any}>
      <IconButton color="error" size='small' sx={{ pointerEvents: 'none', backgroundColor: 'rgb(255, 255, 255, .6)' }}>
        <MicOffIcon />
      </IconButton>
    </Box>
  );
};

export default CadisUnmuteIcon;
