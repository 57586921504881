import { Dispatch, SetStateAction, useState } from 'react';
import { useFormik } from 'formik';
import { Box, Container, Drawer, FormControl, Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { validateAndSetMonthPrice, validateToOnedecimalOnly } from '../../Common/helperFunction';
import { AddOnDataPackRoute, UpdateAddOnDataPackRoute } from '../../Common/api.routes';
import { IUpdateAddOnPack } from '../../Interfaces/ISubscription';
import CadisTextField from '../../Components/Input/CadisTextField';
import apis from '../../HttpConfig/Api';
import CadisButton from '../../Components/Input/CadisButton';
import CadisLoader from '../../Components/Loading/CadisLoader';
import * as Yup from "yup";
import * as Helper from '../../Common/helper';

const AddUpdateDataAddOn = (
  { isOpenDataAddOnForm, setIsOpenDataAddOnForm, mode, addOnDetails, getallAddonPackList }
    :
    {
      getallAddonPackList: () => void
      isOpenDataAddOnForm: boolean,
      setIsOpenDataAddOnForm: Dispatch<SetStateAction<boolean>>,
      mode: string, addOnDetails: IUpdateAddOnPack
    }) => {

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    addonPrice: Yup.number()
      .typeError(`${t("lbl_addOnDataPrice")} ${t("validation.msg_validNumber")}`)
      .required(`${t("lbl_addOnDataPrice")} ${t("validation.msg_isRequired")}`)
      .positive(`${t("lbl_addOnDataPrice")} ${t("validation.msg_beGreaterThanZero")}`),
    addonData: Yup.number()
      .typeError(`${t("lbl_addOnData")} ${t("validation.msg_validNumber")}`)
      .required(`${t("lbl_addOnData")} ${t("validation.msg_isRequired")}`)
      .positive(`${t("lbl_addOnData")} ${t("validation.msg_beGreaterThanZero")}`)
  });

  const CheckNaN = (value: any) => {
    if (isNaN(value)) {
      return ""
    } else {
      return value
    }
  }

  const { values, dirty, handleReset, handleSubmit, touched, errors, setFieldValue, resetForm } = useFormik({
    initialValues: {
      addonPrice: CheckNaN(parseFloat(addOnDetails?.addon_price)),
      addonData: CheckNaN(parseFloat(addOnDetails?.addon_data)),
      addonId: addOnDetails?.addon_id
    },

    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async () => {
      setLoading(true);
      AddUpdateDataAddonApiCall();
    }
  });

  const onFormSubmitComplete = (resp: any) => {
    if (resp.data.success) {
      handleReset(values);
      getallAddonPackList();
      toast.success(`${t("lbl_addOnDataPack")} ${mode === Helper.FormAddMode ? t('lbl_created') : t('lbl_updated')} ${t('lbl_successfully')}`);
      setIsOpenDataAddOnForm(false);
      setLoading(false);
    } else {
      if (resp.data.error.toLowerCase() === "Add-On Data Pack already exists.".toLowerCase()) {
        toast.error(t("responseMsg.msg_addonDataAlreadyExists"));
      }
      else {
        toast.error(resp.data.error);
      }
      setLoading(false);
    }
  };

  const AddUpdateDataAddonApiCall = async () => {
    const addonPackData = {
      'addonId': parseInt(values.addonId),
      'addonPrice': values.addonPrice,
      'addonData': values.addonData,
    }
    try {
      if (mode === Helper.FormAddMode) {
        await apis.instance.post(`${AddOnDataPackRoute}`, addonPackData)
          .then((resp: any) => {
            onFormSubmitComplete(resp);
          })
          .finally(() => setLoading(false));
      }
      else if (mode === Helper.FormUpdateMode) {
        await apis.instance.post(`${UpdateAddOnDataPackRoute}`, addonPackData)
          .then((resp: any) => {
            onFormSubmitComplete(resp);
          })
          .finally(() => setLoading(false));
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(t('responseMsg.msg_wrongEntry'));
      console.log(error.message);
    }
  };

  const handleGbValueChange = async (event: any) => {
    const gbValue = event.currentTarget.value;
    validateToOnedecimalOnly(gbValue, "addonData", mode, setFieldValue);
  };

  const handleAddPackPriceChange = (event: any) => {
    const couponCode = event.currentTarget.value;
    validateAndSetMonthPrice(couponCode, "addonPrice", mode, setFieldValue);
  };

  const handleFormReset = (e: any) => {
    handleReset(e);
  };

  return (
    <>
      <Drawer
        anchor='right'
        open={isOpenDataAddOnForm}
        onClose={() => { return null }}
      >
        <Box role="presentation">
          <Box sx={{ pt: 1.5, position: 'sticky', top: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }} >
            <Container sx={{ alignItems: 'center', display: 'flex' }} disableGutters>
              <Box sx={{ width: '40px', height: '40px', flexGrow: 1, display: { xs: 'flex', pr: 2 } }}>
                <IconButton onClick={() => {
                  resetForm()
                  setIsOpenDataAddOnForm(false)
                }} sx={{}} className='c-pointer'>
                  <span className='cad-close'></span>
                </IconButton>
              </Box>
            </Container>
            <Box sx={{ mb: 2, pb: 2, mt: 2, display: 'flex' }}>
              <Typography className="mt-0 mb-0 slide-right-title" sx={(theme) => theme.typography.h5}> {mode === 'add' ? t('lbl_create') : t('lbl_update')} {t('lbl_addOnDataPack')}</Typography>
            </Box>
          </Box>
          <Grid container width={'100%'}>
            <form onSubmit={handleSubmit} onReset={handleFormReset} style={{ width: '100%' }}>
              <Stack spacing={2} direction={'column'} paddingX={'20px'}>
                <FormControl>
                  <label id="userTitleLabelID" className='form-label '>
                    {t('lbl_addOndatainGb')}<span className='mandatory-star'></span></label>
                  <CadisTextField
                    id="addonData"
                    name="addonData"
                    value={values.addonData}
                    onChange={handleGbValueChange}
                    error={touched.addonData && Boolean(errors.addonData)}
                    helperText={touched.addonData && t(`${errors.addonData ? errors.addonData : ''}`)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ fontSize: '1.2rem' }}>
                          GB
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>

                <FormControl variant="outlined" size="small">
                  <label id="userTitleLabelID" className='form-label'>
                    {t('lbl_addOndataprice')}
                    <span className='mandatory-star'></span></label>
                  <CadisTextField id="addonPrice"
                    name="addonPrice" value={values.addonPrice} onChange={handleAddPackPriceChange}
                    inputProps={{ maxLength: 15 }}
                    error={touched.addonPrice && Boolean(errors.addonPrice)}
                    helperText={touched.addonPrice && t(`${errors.addonPrice ? errors.addonPrice : ''}`)}
                    className='custom-field-caps'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" sx={{ fontSize: '1.2rem' }}>
                          $
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Stack>
              <Box sx={{ pt: 2, pb: 2, position: 'sticky', bottom: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }}>
                <Grid container spacing={0} alignItems="center">
                  <Grid item xs="auto" >
                    <CadisButton type='submit' variant="contained" color='primary' disabled={mode === 'update' && !dirty || loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
                      {mode === 'add' ? t('lbl_create') : t('lbl_update')}
                    </CadisButton>
                  </Grid>
                  <Grid item xs="auto">
                    <Box sx={{ pl: 1 }}>
                      <CadisButton type='reset' variant="contained" color='error' disabled={mode === Helper.FormUpdateMode && !dirty || loading}>
                        {mode === Helper.FormUpdateMode ? `${t('lbl_reset')}` : `${t('lbl_clear')}`}
                      </CadisButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Grid>
        </Box>
      </Drawer >
    </>
  )
}

export default AddUpdateDataAddOn;