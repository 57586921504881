import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormControl, Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { verifyUser } from '../../Common/api.routes';
import { accessRestricted, inactiveOrganization } from '../../Common/helper';
import CadisButton from '../../Components/Input/CadisButton';
import CadisTextField from '../../Components/Input/CadisTextField';
import CadisLoader from '../../Components/Loading/CadisLoader';
import apis from '../../HttpConfig/Api';

const validationSchema =
    Yup.object().shape({
        username: Yup.string().required('validation.msg_username'),
        password: Yup.string().required('validation.msg_password').min(8, "validation.msg_loginPasswordLength").max(15, "validation.msg_loginPasswordLength")
    })
const Login = ({ setUserId }: any) => {

    const { t } = useTranslation();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const preventCopyPaste = (e: any) => {
        e.preventDefault()
    }

    const goToForgotPasswordScreen = () => {
        navigate("/forgotPassword");
    }

    //useFormik
    const { values, handleChange, handleSubmit, errors, touched, handleReset, setFieldValue } = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        onSubmit: async (values, props) => {
            setLoading(true);
            loginApiCall();
        },
        validationSchema: validationSchema
    });

    const loginApiCall = async () => {
        const data = {
            user_name: values.username,
            user_password: values.password,
        }
        try {
            const response = await apis.loginInstance.post(`${verifyUser}`, data)
            if (response.data?.success) {
                if (response.data?.token) {
                    toast.warning(accessRestricted);
                }

                else {
                    handleReset(values);
                    await setUserId(response?.data?.user_id);
                    navigate("/LoginOtp", { state: { user_id: response.data.user_id, user_name: values.username } });
                    toast.success(t('responseMsg.msg_loginToOtp'));
                }
                setLoading(false);
            } else {
                if (response.data.error === "Invalid credentials")
                    toast.error(t('responseMsg.msg_invalidCredentials'));
                else if (response.data.error.toLowerCase() === "your account is not active. consult your admin.")
                    toast.error(t('responseMsg.msg_inactiveuser'));
                else if (response.data.error.toLowerCase() === "your account is not active. contact the administrator.")
                    toast.error(t('responseMsg.msg_checkuserpassword'));
                else if (response.data.error === "User does not exist")
                    toast.error(t('responseMsg.msg_userDoesNotExist'));
                else if (response.data.error === "User status is not active")
                    toast.error(accessRestricted);
                else if (response.data.error === inactiveOrganization)
                    toast.error(inactiveOrganization);
                else if (response.data.error.toLowerCase() === "you are not assigned to any subscription plan.")
                    toast.error(t('responseMsg.msg_unsubscribedUser'))
                else if (response.data.error.toLowerCase() === "your subscription plan has expired")
                    toast.error(t('responseMsg.msg_subscriptionPlanExpired'))
                else
                    toast.error(response.data.error);
                setLoading(false);
            }
        } catch (error: any) {
            toast.error(error.message);
            setLoading(false);
        }
    }
    return (
        <div>
            <Typography className="mt-0 mb-5 prelogin-title" sx={(theme) => theme.typography.h3}>{t('lbl_login')}</Typography>
            <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                    <FormControl variant="outlined">
                        <label className='form-label '>
                            {t('lbl_username')}
                            <span className='mandatory-star'></span>
                        </label>
                        <CadisTextField
                            autoComplete="false"
                            value={values.username}
                            name="username"
                            type="text"
                            onChange={async (event: any) => {
                                const username = event.currentTarget.value;
                                setFieldValue("username", username.replace(/\s/g, ""));
                            }}
                            error={touched.username && errors.username ? true : false}
                            helperText={touched.username && t(`${errors.username ? errors.username : ''}`)}
                        />
                    </FormControl>
                    <FormControl>
                        <label className='form-label '>
                            {t('lbl_password')}
                            <span className='mandatory-star'></span>
                        </label>
                        <CadisTextField
                            autoComplete="off"
                            value={values.password}
                            name="password" type={showPassword ? 'text' : 'password'}
                            onChange={async (event: any) => {
                                const password = event.currentTarget.value;
                                setFieldValue("password", password.replace(/\s/g, ""));
                            }}
                            onPaste={preventCopyPaste}
                            onCut={preventCopyPaste}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            error={touched.password && errors.password ? true : false}
                            helperText={touched.password && t(`${errors.password ? errors.password : ''}`)}
                        />
                    </FormControl>
                    <Grid container spacing={0} alignItems="center" >
                        <Grid item xs>
                            <CadisButton type='submit' variant="contained" color='primary' disabled={loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
                                {t('lbl_login')}
                            </CadisButton>
                        </Grid>
                        <Grid item xs="auto">
                            <div className="">
                                <CadisButton onClick={goToForgotPasswordScreen}>
                                    {t('lbl_forgot_password')}
                                </CadisButton>
                            </div>
                        </Grid>
                    </Grid>
                </Stack>
            </form>
        </div>
    )
}

export default Login