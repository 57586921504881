import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getUserSubscriptionStatus } from '../../Common/api.routes';
import { assistants, cadisAdmin, consultants, navigateToAssistant, navigateToCadisAdmin, navigateToCallSessions, navigateToConsultants, navigateToDataAddOnList, navigateToDeletedOrgs, navigateToDeletedUser, navigateToDiscountCouponCode, navigateToManageUsers, navigateToMyPlans, navigateToOrgAdmins, navigateToOrganizations, navigateToPurchasedPlansMangeUser, navigateToSubscriptionDetails, navigateToSubscriptionPlans, orgAdmins } from '../../Common/helper';
import { isAuthenticated } from '../../Common/helperFunction';
import CadisButton from '../../Components/Input/CadisButton';
import apis from '../../HttpConfig/Api';
import { ROLE } from '../../Interfaces/IConstants';
import { useUserRoleContext } from '../../context/UserRoleContext';

const drawerWidth = 240;

const Sidebar = (props: any) => {
  const user = isAuthenticated();
  const location = useLocation();
  const path = window.location.pathname;
  const [selectedIndex, setSelectedIndex] = useState(path);
  const { userLoginRole } = useUserRoleContext();
  const [localRole, setLocalRole] = useState(userLoginRole);
  const [planExpirePopup, setPlanExpirePopup] = useState(false)
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedIndex(path);
  }, [userLoginRole, location])

  const handleListItemClick = (index: string) => {
    localStorage.setItem("currentNavNode", index);
    setSelectedIndex(index);
    props.setMobileOpen(false);
  };

  useEffect(() => {
    const temp: number = Number(sessionStorage.getItem("role"));
    if (temp) {
      setLocalRole(temp);
    } else {
      setLocalRole(userLoginRole);
    }
  }, [userLoginRole])

  const checkUserSubscriptionStatus = async () => {
    try {
      const subscriptionStatus = await apis.instance.get(getUserSubscriptionStatus + user.userId)
      if (subscriptionStatus.data.success) {
        if (subscriptionStatus.data.data.is_expired === 0) {
          return true
        } else if (subscriptionStatus.data.data.is_expired === 1) {
          setPlanExpirePopup(true)
          return false
        }
      }
    } catch (error) {
      if(error !== undefined) toast.error(t("responseMsg.msg_wrongEntry"))
      return false
    }
  }

  const changeView = async () => {
    const temp: number = Number(sessionStorage.getItem("role"));
    if (temp === ROLE.OrgAdmin) {
      if (await checkUserSubscriptionStatus()) {
        props.viewAsConsultant();
      }
    }
    else {
      props.viewAsAdministrator();
    }
  }

  const drawer = (
    <>
      <div className="sidebar-menu-listbox">
        <List className='sidebar-listing-menu'>

          {/* Super Admin Organizations Menu */}
          {localRole === ROLE.CadisAdmin &&
            <ListItemButton
              className='menuItem hasSubMenus'
              onClick={props.handleOrgClick}
              selected={selectedIndex === navigateToOrganizations || selectedIndex === navigateToDeletedOrgs || selectedIndex === navigateToSubscriptionDetails}>
              <ListItemIcon>
                <span className='cad-home'></span>
              </ListItemIcon>
              <ListItemText primary={t('lbl_organizations')} />
              {props.openOrg ? <span className='cad-angle-up'></span> : <span className='cad-angle-down'></span>}
            </ListItemButton>}
          {/* Super Admin Organizations Sub-Menu */}
          {localRole === ROLE.CadisAdmin &&
            <Collapse in={props.openOrg} timeout="auto" unmountOnExit>
              <ListItemButton
                className='menuSubItem'
                component={Link}
                to={navigateToOrganizations}
                selected={selectedIndex === navigateToOrganizations || selectedIndex === navigateToSubscriptionDetails} onClick={(index) => handleListItemClick(navigateToOrganizations)}>
                <ListItemIcon sx={{ textAlign: 'right' }} >
                  <span className='cad-circle-filled'></span>
                </ListItemIcon>
                <ListItemText primary={t('lbl_activeInactive')} />
              </ListItemButton>
              <ListItemButton
                className='menuSubItem'
                component={Link}
                to={navigateToDeletedOrgs}
                selected={selectedIndex === navigateToDeletedOrgs}
                onClick={() => handleListItemClick(navigateToDeletedOrgs)}>
                <ListItemIcon sx={{ textAlign: 'right' }} >
                  <span className='cad-circle-filled'></span>
                </ListItemIcon>
                <ListItemText primary={t("lbl_hidden")} />
              </ListItemButton>
            </Collapse>}

          {/* Super Admin Subscription Plans Menu */}
          {(localRole === ROLE.CadisAdmin) &&
            <ListItemButton
              className='menuItem'
              component={Link}
              to={`${navigateToSubscriptionPlans}/${cadisAdmin}/0`}
              selected={selectedIndex === `${navigateToSubscriptionPlans}/${cadisAdmin}/0`}
              onClick={() => {
                props.setOpen(false);
                props.SetOpenOrg(false);
                handleListItemClick(`${navigateToSubscriptionPlans}/${cadisAdmin}`)
              }}>
              <ListItemIcon >
                <span className='cad-subscription-plans'></span>
              </ListItemIcon>
              <ListItemText primary={t("lbl_subscriptionPlans")} />
            </ListItemButton>
          }

          {/* Super Admin Coupon Codes Menu */}
          {(localRole === ROLE.CadisAdmin) &&
            <ListItemButton
              className='menuItem'
              component={Link}
              to={`${navigateToDiscountCouponCode}`}
              selected={selectedIndex === `${navigateToDiscountCouponCode}`}
              onClick={() => {
                props.setOpen(false);
                props.SetOpenOrg(false);
                handleListItemClick(`${navigateToDiscountCouponCode}`)
              }}>
              <ListItemIcon >
                <span className='cad-coupon-discount-icon'></span>
              </ListItemIcon>
              <ListItemText primary={t("lbl_discountCouponCode")} />
            </ListItemButton>
          }
          {(localRole === ROLE.CadisAdmin) &&
            <ListItemButton
              className='menuItem'
              component={Link}
              to={`${navigateToDataAddOnList}`}
              selected={selectedIndex === `${navigateToDataAddOnList}`}
              onClick={() => {
                props.setOpen(false);
                props.SetOpenOrg(false);
                handleListItemClick(`${navigateToDataAddOnList}`)
              }}>
              <ListItemIcon >
                <span className='cad-data-add-on-packs'></span>
              </ListItemIcon>
              <ListItemText primary={t("lbl_dataAddOns")} />
            </ListItemButton>
          }

          {/* Org Admin Consultants Menu */}
          {localRole === ROLE.OrgAdmin &&
            <ListItemButton
              className='menuItem hasSubMenus'
              onClick={props.handleConsultantClick}
              selected={selectedIndex === navigateToConsultants || selectedIndex === `${navigateToDeletedUser}/${consultants}`}>
              <ListItemIcon>
                <span className='cad-statoscope'></span>
              </ListItemIcon>
              <ListItemText primary={t("lbl_consultants")} />
              {props.openConsultant ? <span className='cad-angle-up'></span> : <span className='cad-angle-down'></span>}
            </ListItemButton>
          }
          {/* Org Admin Consultants Sub-Menu */}
          {localRole === ROLE.OrgAdmin &&
            <Collapse in={props.openConsultant} timeout="auto" unmountOnExit>
              <ListItemButton
                className='menuSubItem'
                component={Link}
                to={navigateToConsultants}
                selected={selectedIndex === navigateToConsultants}
                onClick={(index) => handleListItemClick(navigateToConsultants)}>
                <ListItemIcon sx={{ textAlign: 'right' }} >
                  <span className='cad-circle-filled'></span>
                </ListItemIcon>
                <ListItemText primary={t('lbl_activeInactive')} />
              </ListItemButton>
              <ListItemButton
                className='menuSubItem'
                component={Link}
                to={`${navigateToDeletedUser}/${consultants}`}
                selected={selectedIndex === `${navigateToDeletedUser}/${consultants}`}
                onClick={(index) => handleListItemClick(`${navigateToDeletedUser}/${consultants}`)}>
                <ListItemIcon sx={{ textAlign: 'right' }} >
                  <span className='cad-circle-filled'></span>
                </ListItemIcon>
                <ListItemText primary={t("lbl_hidden")} />
              </ListItemButton>
            </Collapse>
          }

          {/* Org Admin Assistants Menu */}
          {localRole === ROLE.OrgAdmin &&
            <ListItemButton
              className='menuItem hasSubMenus'
              onClick={props.handleAssistantClick}
              selected={selectedIndex === navigateToAssistant || selectedIndex === `${navigateToDeletedUser}/${assistants}`}>
              <ListItemIcon>
                <span className='cad-assistant'></span>
              </ListItemIcon>
              <ListItemText primary={t("lbl_assistants")} />
              {props.openAssistant ? <span className='cad-angle-up'></span> : <span className='cad-angle-down'></span>}
            </ListItemButton>
          }
          {/* Org Admin Assistants Sub-Menu */}
          {localRole === ROLE.OrgAdmin &&
            <Collapse in={props.openAssistant} timeout="auto" unmountOnExit>
              <ListItemButton
                className='menuSubItem'
                component={Link}
                to={navigateToAssistant}
                selected={selectedIndex === navigateToAssistant}
                onClick={(index) => handleListItemClick(navigateToAssistant)}>
                <ListItemIcon sx={{ textAlign: 'right' }} >
                  <span className='cad-circle-filled'></span>
                </ListItemIcon>
                <ListItemText primary={t('lbl_activeInactive')} />
              </ListItemButton>
              <ListItemButton
                className='menuSubItem'
                component={Link}
                to={`${navigateToDeletedUser}/${assistants}`}
                selected={selectedIndex === `${navigateToDeletedUser}/${assistants}`}
                onClick={(index) => handleListItemClick(`${navigateToDeletedUser}/${assistants}`)}>
                <ListItemIcon sx={{ textAlign: 'right' }} >
                  <span className='cad-circle-filled'></span>
                </ListItemIcon>
                <ListItemText primary={t("lbl_hidden")} />
              </ListItemButton>
            </Collapse>
          }

          {/* Consulatant-Role Assistants Menu */}
          {(localRole === ROLE.Consultant) &&
            <ListItemButton
              className='menuItem'
              component={Link}
              to={navigateToAssistant}
              selected={selectedIndex === navigateToAssistant}
              onClick={() => handleListItemClick(navigateToAssistant)}>
              <ListItemIcon>
                <span className='cad-assistant'></span>
              </ListItemIcon>
              <ListItemText primary={t("lbl_assistants")} />
            </ListItemButton>
          }

          {/* Consulatant-Role Consultants Menu */}
          {localRole === ROLE.Consultant &&
            <ListItemButton
              className='menuItem'
              component={Link}
              to={navigateToConsultants}
              selected={selectedIndex === navigateToConsultants}
              onClick={() => handleListItemClick(navigateToConsultants)}>
              <ListItemIcon>
                <span className='cad-statoscope'></span>
              </ListItemIcon>
              <ListItemText primary={t("lbl_consultants")} />
            </ListItemButton>
          }

          {/* Org Admin and Consulatant-Role Call Sessions Menu */}
          {(localRole === ROLE.OrgAdmin || localRole === ROLE.Consultant) &&
            <ListItemButton
              className='menuItem'
              component={Link}
              to={navigateToCallSessions}
              selected={selectedIndex === navigateToCallSessions}
              onClick={() => {
                props.setOpen(false);
                props.setOpenConsultant(false);
                props.setOpenAssistant(false);
                props.setOpenMyPlan(false);
                handleListItemClick(navigateToCallSessions);
              }}>
              <ListItemIcon>
                <span className='cad-call-circle'></span>
              </ListItemIcon>
              <ListItemText primary={t("lbl_callSessions")} />
            </ListItemButton>
          }

          {/* Org Admin Subscription Plans Menu */}
          {localRole === ROLE.OrgAdmin &&
            <ListItemButton
              className='menuItem hasSubMenus'
              onClick={props.handleMyPlanClick}
              selected={selectedIndex === navigateToMyPlans || selectedIndex === `${navigateToSubscriptionPlans}/${orgAdmins}/1` || selectedIndex === `${navigateToSubscriptionPlans}/${orgAdmins}/0` || selectedIndex === `${navigateToSubscriptionPlans}/${orgAdmins}/2`
                || selectedIndex === `${navigateToMyPlans}/0` || selectedIndex === `${navigateToMyPlans}/1` || selectedIndex === `${navigateToMyPlans}/2` || selectedIndex === `${navigateToPurchasedPlansMangeUser}`}>
              <ListItemIcon>
                <span className='cad-subscription-plans'></span>
              </ListItemIcon>
              <ListItemText primary={t("lbl_subscriptionPlans")} />
              {props.openMyPlan ? <span className='cad-angle-up'></span> : <span className='cad-angle-down'></span>}
            </ListItemButton>
          }
          {/* Org Admin Subscription Plans Sub-Menu */}
          {localRole === ROLE.OrgAdmin &&
            <Collapse in={props.openMyPlan} timeout="auto" unmountOnExit>
              <ListItemButton
                className='menuSubItem'
                component={Link}
                to={`${navigateToMyPlans}/0`}
                selected={selectedIndex === `${navigateToMyPlans}/0` || selectedIndex === `${navigateToMyPlans}/1` || selectedIndex === `${navigateToMyPlans}/2` || selectedIndex === `${navigateToManageUsers}` || selectedIndex === `${navigateToPurchasedPlansMangeUser}`} onClick={(index) => handleListItemClick(`${navigateToMyPlans}/0`)}>
                <ListItemIcon sx={{ textAlign: 'right' }} >
                  <span className='cad-circle-filled'></span>
                </ListItemIcon>
                <ListItemText primary={t("lbl_purchasedPlan")} />
              </ListItemButton>
              <ListItemButton
                className='menuSubItem'
                component={Link}
                to={`${navigateToSubscriptionPlans}/${orgAdmins}/0`}
                selected={selectedIndex === `${navigateToSubscriptionPlans}/${orgAdmins}/0` || selectedIndex === `${navigateToSubscriptionPlans}/${orgAdmins}/1` || selectedIndex === `${navigateToSubscriptionPlans}/${orgAdmins}/2`} onClick={(index) => handleListItemClick(`${navigateToSubscriptionPlans}/${orgAdmins}/0`)}>
                <ListItemIcon sx={{ textAlign: 'right' }} >
                  <span className='cad-circle-filled'></span>
                </ListItemIcon>
                <ListItemText primary={t("lbl_planList")} />
              </ListItemButton>
            </Collapse>
          }

          {/* Super Admin and Org Admin Manage Menu */}
          {localRole !== ROLE.Consultant &&
            <ListItemButton
              className='menuItem hasSubMenus'
              onClick={props.handleClick}
              selected={selectedIndex === navigateToCadisAdmin || selectedIndex === `${navigateToDeletedUser}/${cadisAdmin}` || selectedIndex === navigateToOrgAdmins || selectedIndex === `${navigateToDeletedUser}/${orgAdmins}` || selectedIndex === `${navigateToManageUsers}`}>
              <ListItemIcon>
                <span className='cad-cog'></span>
              </ListItemIcon>
              <ListItemText primary={t("lbl_manage")} />
              {props.open ? <span className='cad-angle-up'></span> : <span className='cad-angle-down'></span>}
            </ListItemButton>
          }

          {/* Super Admin Manage Sub-Menu */}
          <Collapse in={props.open} timeout="auto" unmountOnExit>
            {localRole === ROLE.CadisAdmin &&
              <ListItemButton
                className='menuSubItem'
                component={Link}
                to={navigateToCadisAdmin}
                selected={selectedIndex === navigateToCadisAdmin}
                onClick={(index) => handleListItemClick(navigateToCadisAdmin)}>
                <ListItemIcon sx={{ textAlign: 'right' }} >
                  <span className='cad-circle-filled'></span>
                </ListItemIcon>
                <ListItemText primary={t('lbl_activeInactiveCadis')} />
              </ListItemButton>
            }
            {localRole === ROLE.CadisAdmin &&
              <ListItemButton
                className='menuSubItem'
                component={Link}
                to={`${navigateToDeletedUser}/${cadisAdmin}`}
                selected={selectedIndex === `${navigateToDeletedUser}/${cadisAdmin}`}
                onClick={(index) => handleListItemClick(`${navigateToDeletedUser}/${cadisAdmin}`)}>
                <ListItemIcon sx={{ textAlign: 'right' }} >
                  <span className='cad-circle-filled'></span>
                </ListItemIcon>
                <ListItemText primary={t("lbl_hiddenCadisSuperAdmins")} />
              </ListItemButton>
            }

            {/* Org Admin Manage Sub-Menu */}
            {localRole === ROLE.OrgAdmin &&
              <ListItemButton
                className='menuSubItem'
                component={Link}
                to={navigateToOrgAdmins}
                selected={selectedIndex === navigateToOrgAdmins}
                onClick={() => handleListItemClick(navigateToOrgAdmins)}>
                <ListItemIcon sx={{ textAlign: 'right' }} >
                  <span className='cad-circle-filled'></span>
                </ListItemIcon>
                <ListItemText primary={t('lbl_activeInactiveOrgAdmin')} />
              </ListItemButton>
            }
            {localRole === ROLE.OrgAdmin &&
              <ListItemButton
                className='menuSubItem'
                component={Link}
                to={`${navigateToDeletedUser}/${orgAdmins}`}
                selected={selectedIndex === `${navigateToDeletedUser}/${orgAdmins}`}
                onClick={(index) => handleListItemClick(`${navigateToDeletedUser}/${orgAdmins}`)}>
                <ListItemIcon sx={{ textAlign: 'right' }} >
                  <span className='cad-circle-filled'></span>
                </ListItemIcon>
                <ListItemText primary={t("lbl_hiddenOrgAdmins")} />
              </ListItemButton>
            }
          </Collapse>
        </List>
      </div>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, zIndex: 1, position: 'relative', height: '100vh' }}  >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer container={props.container} variant="temporary" open={props.mobileOpen}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, mt: 7.5, height: 'calc(100% - 60px)', pb: 1 },
        }} className={user.isCadisAdmin ? 'superAdminTheme leftSidePanel'
          : localRole === ROLE.Consultant ? 'consultantTheme leftSidePanel' : 'orgAdminTheme leftSidePanel'}>
        <>{drawer}</>
        {(user.roles.length > 1 && props.subscriptionId > 0) && <Button color="warning" variant="contained" size='medium' onClick={changeView} sx={{ m: '0 8px 0 8px', position: 'sticky', bottom: '0', zIndex: '1200', textTransform: 'unset' }}
          startIcon={<span className={localRole === ROLE.Consultant ? 'cad-user-setting' : 'cad-statoscope'}></span>}>
          <Typography sx={{ font: 'inherit' }} display="inline">{props.viewButtonLabel}</Typography>
        </Button>}
      </Drawer>
      <Drawer variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, mt: 8, height: 'calc(100% - 64px)', pb: 1 },
        }} open className={user.isCadisAdmin ? 'superAdminTheme'
          : localRole === ROLE.Consultant ? 'consultantTheme' : 'orgAdminTheme'}>
        <>{drawer}</>
        {(user.roles.length > 1 && props.subscriptionId > 0) && <Button color="warning" variant="contained" size='medium' onClick={changeView} sx={{ m: '0 8px 0 8px', position: 'sticky', bottom: '0', zIndex: '1200', textTransform: 'unset' }}
          startIcon={<span className={localRole === ROLE.Consultant ? 'cad-user-setting' : 'cad-statoscope'}></span>}>
          <Typography sx={{ font: 'inherit' }} display="inline">{props.viewButtonLabel}</Typography>
        </Button>}
      </Drawer>
      <Dialog
        open={planExpirePopup}
        onClose={() => { return null }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" color="error.main">
          {t('lbl_alert')}
        </DialogTitle>
        <DialogContent>
          {(t('responseMsg.msg_consultantPlanExpired'))}
        </DialogContent>
        <DialogActions>
          <CadisButton variant="contained" color="primary" onClick={() => setPlanExpirePopup(false)}>{t('lbl_close')}</CadisButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default Sidebar