import { Box, Rating, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RATE_CALL } from '../../Common/helper';
import CadisButton from '../../Components/Input/CadisButton';
import { useSocketContext } from '../../context/SocketContext';
import { t } from 'i18next';

type Props = {}

const CallQuality = (props: Props) => {
    const { socketRef, sessionIdRef, openIncomingCallBox } = useSocketContext();
    const navigate = useNavigate();

    const handleRatingChange = (event: React.SyntheticEvent<Element, Event>, value: number | null) => {
        socketRef.current?.emit(RATE_CALL, { sessionId: sessionIdRef.current, rating: value });
        navigate('/feedback');
    }

    // useEffect(()=>{
    //     return ()=> {
    //         sessionIdRef.current = null;
    //     }
    // },[]);

    return (
        <>
            {openIncomingCallBox()}
            <Box height='100vh' sx={{ backgroundColor: '#2A2A2A' }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                <Typography fontSize='2rem' color='#ffffff' fontWeight='semi-bold'>{t("lbl_callHasEnded")}</Typography>
                <Typography fontSize='2rem' color='#ffffff' fontWeight='semi-bold'>{t("lbl_howWasCallQuality")}</Typography>
                <Box padding={2}>
                    <Rating name="size-large" defaultValue={0} max={5} size="large" sx={{ color: '#F2AF4C', fontSize: '3rem' }} onChange={(event, newValue) => handleRatingChange(event, newValue)} />
                </Box>
                <Box>
                    <CadisButton style={{ color: '#ffffff', fontSize: '1rem', border: '1px solid #ffffff' }} onClick={() => navigate('/dashboard/assistants')}>{t("lbl_skip")}</CadisButton>
                </Box>
            </Box>
        </>
    )
}

export default CallQuality