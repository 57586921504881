import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import jwtDecode from 'jwt-decode';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ConfirmPassword } from '../../Common/api.routes';
import { minPasswordLength } from '../../Common/helper';
import CadisButton from '../../Components/Input/CadisButton';
import CadisTextField from '../../Components/Input/CadisTextField';
import CadisLoader from '../../Components/Loading/CadisLoader';
import apis from '../../HttpConfig/Api';

const validationSchema =
    Yup.object().shape({
        password: Yup.string().required('validation.msg_password').min(minPasswordLength, `Password must be at least ${minPasswordLength} characters long. `),
        confirmpassword: Yup.string().required('validation.msg_confirmPassword')
            .oneOf([Yup.ref('password')], 'validation.msg_set_confirm_password')
    })

const SetNewPassword = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setshowConfirmPassword] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setshowConfirmPassword((show) => !show);
    const [loading, setLoading] = useState(false);

    const preventCopyPaste = (e: any) => {
        e.preventDefault()
    }

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    const goToLogin = () => {
        navigate("/login");
    }

    //useFormik
    const { values, handleChange, handleSubmit, errors, touched, handleReset } = useFormik({
        initialValues: {
            password: '',
            confirmpassword: ''
        },
        onSubmit: async (values, props) => {
            setLoading(true);
            setPasswordCall();
        },
        validationSchema: validationSchema
    });

    const setPasswordCall = async () => {
        const data1: string = params.userId !== undefined ? params.userId : '';
        const user: any = jwtDecode(data1);

        const data = {
            user_name: user.username,
            new_password: values.password,
            token: data1
        }
        try {
            const response = await apis.instance.post(`${ConfirmPassword}`, data)

            if (response.data?.success) {
                setOpenDialog(true);
                setLoading(false)
            } else {
                if (response.data.error.toLowerCase() === "jwt expired")
                    toast.error(t('responseMsg.msg_setpasswordlinkexpired'));
                else if (response.data.error === "Password reset link has expired")
                    toast.error(t('responseMsg.msg_setpasswordlinkexpired'));
                else
                    toast.error(t('responseMsg.msg_passwordErrorMsg'));
                setLoading(false);
            }
        } catch (error: any) {
            toast.error(error.message);
            setLoading(false);
        }
    }

    const handleCharacterLength = (e: any) => {
        if (e.target.value.length <= 15) {
            handleChange(e)
        }
    }

    return (
        <div>
            <Typography className="mt-0 mb-5 prelogin-title" sx={(theme) => theme.typography.h3}>{t('lbl_newPassword')}</Typography>
            <form onSubmit={handleSubmit} onReset={handleReset} >
                <Stack spacing={2}>
                    <FormControl variant="outlined">
                        <label className='form-label '>
                            {t('lbl_set_password')}
                            <span className='mandatory-star'></span>
                        </label>
                        <CadisTextField
                            autoComplete="off"
                            value={values.password.trim()}
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => handleCharacterLength(e)}
                            onCopy={preventCopyPaste}
                            onPaste={preventCopyPaste}
                            onCut={preventCopyPaste}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <span className='cad-eye'></span> : <span className='cad-eye-off'></span>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            error={touched.password && errors.password ? true : false}
                            helperText={touched.password && t(`${errors.password ? errors.password : ''}`)}
                        />
                    </FormControl>
                    <FormControl variant="outlined">
                        <label className='form-label '>
                            {t('lbl_confirm_password')}
                            <span className='mandatory-star'></span>
                        </label>
                        <CadisTextField
                            autoComplete="off"
                            value={values.confirmpassword.trim()}
                            name="confirmpassword"
                            type={showConfirmPassword ? 'text' : 'password'}
                            onChange={(e) => handleCharacterLength(e)}
                            onCopy={preventCopyPaste}
                            onPaste={preventCopyPaste}
                            onCut={preventCopyPaste}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showConfirmPassword ? <span className='cad-eye'></span> : <span className='cad-eye-off'></span>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            error={touched.confirmpassword && errors.confirmpassword ? true : false}
                            helperText={touched.confirmpassword && t(`${errors.confirmpassword ? errors.confirmpassword : ''}`)}
                        />
                    </FormControl>
                    <Grid container spacing={0} alignItems="center" >
                        <Grid item xs>
                            <CadisButton type='submit' variant="contained" color='primary' disabled={loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
                                {t('lbl_continue')}
                            </CadisButton>
                        </Grid>
                    </Grid>
                </Stack>
            </form>

            <Dialog
                open={openDialog}
                onClose={() => { return null }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" color="success.main">
                    {t('lbl_success')}
                </DialogTitle>
                <DialogContent>
                    {t('lbl_loginWithNewSetPassword')}
                </DialogContent>
                <DialogActions>
                    <CadisButton variant="contained" color='primary' onClick={goToLogin}>
                        {t('lbl_goToLogin')}
                    </CadisButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default SetNewPassword