import { Box, Button, Card, CardContent, Grid, ListItem, ListItemText, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { auto } from '@popperjs/core';
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetAllSubscribedUsesofOrgRoute, RemoveUserInSubscription } from "../../Common/api.routes";
import { ButtonActionAdd, ButtonActionDelete, DefaultGridRowSize, ExpiredStatus, FormAddMode, FormViewMode, GridRowsPerPageOptions, InActiveStatus, navigateToMyPlans } from "../../Common/helper";
import { GetGridNoRecordMessage, GetShortDateWithLuxon, isAuthenticated } from "../../Common/helperFunction";
import { GridStyling } from "../../Common/sharedStyling";
import apis from "../../HttpConfig/Api";
import { useUserRoleContext } from "../../context/UserRoleContext";
import AddRemoveUsers from "./AddRemoveUsers";

const ManageUsers = () => {
    const PageTitleRef: any = useRef();
    const subscriptionDetailsRef: any = useRef();
    const tableGridRef: any = useRef();
    const [pageSize, setPageSize] = useState<number>(DefaultGridRowSize);
    const [tableHeight, setTableHeight] = useState<any>();
    const { handleToggleLoader } = useUserRoleContext();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { orgSubscriptionData } = location.state;
    const { t } = useTranslation();
    const user = isAuthenticated();
    const [subscriptionDetailsMode, setSubscriptionDetailsMode] = useState(FormViewMode);
    const [openAssignRemoveUsersDrawer, setOpenAssignRemoveUsersDrawer] = useState<boolean>(false);
    const [allSubscribedUserList, setAllSubscribedUserList] = useState([]);
    const [selectedRows, setSelectedRows] = useState<GridSelectionModel>([]);
    const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
    const [activateEffect, setActivateEffect] = useState(false);

    useEffect(() => {
        getPageTitleHeight();
        getAllSubscribedUsersDetails();
    }, []);

    // dynamic scrollbar for grid view start
    const getPageTitleHeight = () => {
        let pageTitleHeight: any = 0;
        if (PageTitleRef && PageTitleRef.current) {
            pageTitleHeight = (PageTitleRef.current.clientHeight + subscriptionDetailsRef.current.clientHeight);
        }
        setTableHeight(window.innerHeight - pageTitleHeight - 100);
    };
    window.addEventListener("resize", getPageTitleHeight);

    const getAllSubscribedUsersDetails = async () => {
        handleToggleLoader(true);
        const params = {
            'org_id': user.orgId,
            'subscription_id': orgSubscriptionData.subscription_id
        }
        try {
            const response = await apis.instance.get(`${GetAllSubscribedUsesofOrgRoute}`, { params });
            if (response.data.success) {
                let responseData = response?.data?.data.map((item: any, index: number) => {
                    return {
                        assigned_users: item.user_id,
                        ...item,
                        full_name: item.user_title === "None" ? `${item.user_fname} ${item.user_lname ?? ""}` : `${item.user_title} ${item.user_fname} ${item.user_lname ?? ""}`,
                    }
                })
                responseData = responseData
                    .sort((a: any, b: any) => a.user_fname.localeCompare(b.user_fname))
                    .map((item: any, index: number) => ({ ...item, index: index + 1 }));
                setAllSubscribedUserList(responseData);
            } else {
                setAllSubscribedUserList([]);
            }
            handleToggleLoader(false);
        } catch (err) {
            handleToggleLoader(false);
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const userArray = selectedRows.map(userId => ({ user_id: userId }))
    const removeSingleUserInSubscription = async () => {
        const UserData = {
            "subscription_id": orgSubscriptionData.subscription_id,
            "org_id": user.orgId,
            "userArray": userArray,
        }
        await apis.instance.delete(`${RemoveUserInSubscription}`, { data: UserData })
            .then((resp: any) => {
                if (resp.data.success) {
                    getAllSubscribedUsersDetails()
                    toast.success(t('responseMsg.msg_unassignUser'));
                    setLoading(false);
                    const isIdExist = userArray.some(item => item.user_id === user.userId);
                    if (isIdExist)
                        window.location.reload();
                } else {
                    toast.error(resp.data.error);
                    setLoading(false);
                }
                getAllSubscribedUsersDetails()
            })
            .finally(() => setLoading(false));
    }

    const columns: GridColDef[] = [
        { field: 'subscription_id', width: 1, hide: true },
        { field: 'user_id', width: 1, hide: true },
        { field: 'index', headerName: 'Sr. No.', minWidth: 80, maxWidth: 80, sortable: false, flex: 1, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', align: 'center', headerAlign: 'center' },
        {
            field: 'full_name', flex: 1, headerName: 'Assigned User Name', minWidth: 200, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header',
            renderCell: ({ row }: any) => (
                <ListItem disablePadding>
                    <ListItemText sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center' }} className="" >
                        <Box display={'flex'} alignItems={'center'}>
                            {row.full_name}
                            {row.user_role_id === 3 ? (
                                <Box className='mx-1 ico-circle-secondary cad-statoscope' sx={{ lineHeight: '1' }} />
                            ) : row.user_role_id === 4 ? (
                                <Box className='mx-1 ico-circle-secondary cad-assistant' sx={{ lineHeight: '1' }} />
                            ) : null}
                        </Box>
                    </ListItemText>
                </ListItem>
            ),
        }
    ];

    const onClick = (e: any, params: any) => {
        e.stopPropagation();
        OnActionClick(e.currentTarget.id, params);
    };

    const onBackClick = () => {
        navigate(`${navigateToMyPlans}/0`);
    }

    const OnActionClick = (actionId: string, params: any) => {
        switch (actionId) {
            case ButtonActionAdd:
                setSubscriptionDetailsMode(FormAddMode);
                setOpenAssignRemoveUsersDrawer(true);
                setActivateEffect(true);
                break;
            case ButtonActionDelete:
                removeSingleUserInSubscription()
                break;
            default:
                break;
        }
    }

    const handleSelectionChange = (newSelection: GridSelectionModel) => {
        setSelectedRows(newSelection);
        setIsCheckboxSelected(newSelection.length > 0);
    };

    return (
        <div>
            <Box ref={PageTitleRef} className="page-title-row pb-3">
                <Grid
                    container
                    alignItems={"center"}
                    textAlign={"left"}
                    rowSpacing={2}
                    columnSpacing={2}>
                    <Grid item xs={auto}>
                        {
                            <Button id='backbtn'
                                onClick={(e) => { onBackClick() }}
                                startIcon={<span className='cad-arrow-left'></span>}
                                variant="outlined" color="primary">
                                {t('lbl_goBack')}
                            </Button>
                        }
                    </Grid>
                    <Grid item sm padding={0} className="page-title-box">
                        <Typography
                            className="page-title mt-0 mb-0 text-truncate"
                            sx={(theme) => theme.typography.h5}>
                            {t(`lbl_manageUsers`)} -&nbsp;
                            <Typography
                                className="page-title mt-0 mb-0"
                                sx={{ font: 'inherit' }} display="inline" color='success.main'>
                                {orgSubscriptionData.name}_{orgSubscriptionData.subscription_count}_{orgSubscriptionData.user_count}_{GetShortDateWithLuxon(orgSubscriptionData.actual_start_date)}
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={auto} padding={0}>
                        {orgSubscriptionData.subscription_status !== ExpiredStatus && orgSubscriptionData.subscription_status !== InActiveStatus ? (
                            <Button
                                id={ButtonActionAdd}
                                onClick={(e) => onClick(e, null)}
                                startIcon={<span className='cad-plus' color="primary"></span>}
                                variant="contained"
                                sx={{ my: 0.5, mr: 1 }}
                            >
                                {t(`lbl_assign`)}
                                <Typography sx={{ font: 'inherit' }} display={{ xs: 'none', md: 'inline' }}>
                                    &nbsp;{t(`lbl_users`)}
                                </Typography>
                            </Button>
                        ) : null}
                        <Button id={ButtonActionDelete}
                            disabled={!isCheckboxSelected}
                            onClick={(e) => { onClick(e, null) }}
                            startIcon={<span className='cad-minus'></span>}
                            variant="contained" sx={{ my: .5 }} color="error">
                            {t(`lbl_unassign`)}<Typography sx={{ font: 'inherit' }} display={{ xs: 'none', md: 'inline' }}>&nbsp;{t(`lbl_users`)}</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Box ref={subscriptionDetailsRef} className="subscription-details-row" marginBottom={2}>
                <Card sx={{ width: '100%' }} elevation={2} className="bg-light">
                    <CardContent sx={{ pb: '1.2rem !important' }}>
                        <Grid
                            container
                            alignItems={"center"}
                            textAlign={"left"}
                            rowSpacing={2}
                            columnSpacing={2}>
                            <Grid item xs={6} md={3} lg={3}>
                                <label id="" className='form-label'>{t(`lbl_planName`)}</label>
                                <Typography fontWeight={600} color='grey-black' className="text-truncate">
                                    {orgSubscriptionData.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={3} lg={3}>
                                <label id="" className='form-label'>{t(`lbl_assignedUsers`)}</label>
                                <Typography fontWeight={600} color='grey-black'>
                                    {allSubscribedUserList.length}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={3} lg={3}>
                                <label id="" className='form-label'>{t(`lbl_totalUsers`)}</label>
                                <Typography fontWeight={600} color='grey-black'>
                                    {orgSubscriptionData.user_count}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={3} lg={3}>
                                <label id="" className='form-label'>{t(`lbl_userBundles`)}</label>
                                <Typography fontWeight={600} color='grey-black'>
                                    {orgSubscriptionData.subscription_count}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
            <Box sx={GridStyling} ref={tableGridRef} height={tableHeight}>
                <DataGrid checkboxSelection
                    components={{
                        NoRowsOverlay: GetGridNoRecordMessage,
                    }}
                    rows={allSubscribedUserList}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
                    rowsPerPageOptions={GridRowsPerPageOptions}
                    getRowId={(row: any) => row.user_id}
                    autoHeight={false}
                    autoPageSize={false}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    disableSelectionOnClick={true}
                    onSelectionModelChange={handleSelectionChange}
                />
            </Box>
            <AddRemoveUsers
                activateEffect={activateEffect}
                onEffectActivated={() => setActivateEffect(false)}
                isOpenAssignRemoveUsersDrawer={openAssignRemoveUsersDrawer}
                setOpenAssignRemoveUsersDrawer={setOpenAssignRemoveUsersDrawer}
                getAllSubscribedUsersDetails={getAllSubscribedUsersDetails}
            />
        </div>
    );
}
export default ManageUsers;