import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

let feedbackTimeout: any;

const FeedbackReplay = () => {
  const navigate = useNavigate();

  useEffect(() => {
    feedbackTimeout = setTimeout(() => {
      navigate('/dashboard/assistants');
    }, 2000);
    return () => {
      clearTimeout(feedbackTimeout);
      feedbackTimeout = null;
    }
  }, []);

  return (
    <Box height='100vh' sx={{ backgroundColor: '#2A2A2A' }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
      <Typography fontSize='2rem' color='#ffffff' fontWeight='bold'>{t("lbl_thanksForFeedBack")} </Typography>
      <Typography color='#ffffff'>{t("lbl_improvingYourExperience")} </Typography>
    </Box>
  )
}

export default FeedbackReplay;