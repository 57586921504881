import { FormControl, Grid, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { io } from 'socket.io-client';
import { JOINED_ROOM, JOIN_ALREADY_ENDED_ROOM, WEB_AST_CALL_INITIATION } from '../../Common/helper';
import CadisButton from '../../Components/Input/CadisButton';
import CadisTextField from '../../Components/Input/CadisTextField';
import CadisLoader from '../../Components/Loading/CadisLoader';
import apis from '../../HttpConfig/Api';
import { useSocketContext } from '../../context/SocketContext';

const WebAssistantJoin = () => {

    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { socketRef, setJanusURL } = useSocketContext(); // socket context customeHook

    useEffect(() => {
        socketRef.current?.on(JOIN_ALREADY_ENDED_ROOM, () => {
            toast.error(t('responseMsg.msg_theLinkHasExpired'));
            setLoading(false);
        });

        socketRef.current?.on(JOINED_ROOM, (data) => {
            setJanusURL(data?.url);
            navigate("/webassistantcall", {
                state: {
                    callSessionId: params.sessionId,
                    janusURL: data?.url
                }
            })
        });

        // unmount function to cleare session id from sessionstorage
        return () => {
            sessionStorage.removeItem("WastSessionId");
        }

    }, [socketRef.current])

    const { values, handleSubmit, errors, touched, handleChange } = useFormik({
        initialValues: {
            userName: ''
        },
        onSubmit: async (values, props) => {
            setLoading(true);
            onJoinCall();
        },
    });

    const onJoinCall = () => {
        if (!socketRef.current && apis.socketURL) {
            const token = sessionStorage.getItem('token');
            const socketAuth = {
                query: { token },
            };
            socketRef.current = io(apis.socketURL, socketAuth);
            socketRef.current.on('connect', () => {
                console.log('Socket connected');
            });
        }
        const linkRoomID = params.sessionId !== undefined ? params.sessionId : '';
        socketRef.current?.emit(WEB_AST_CALL_INITIATION, {
            webAssistantName: values.userName,
            sessionId: linkRoomID,
        });
    }

    return (
        <div>
            <Typography className="mt-0 mb-5 prelogin-title" sx={(theme) => theme.typography.h3}>{t('lbl_joinCall')}</Typography>
            <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                    <FormControl variant="outlined">
                        <label className='form-label '>
                            {t('lbl_enterName')}
                        </label>
                        <CadisTextField autoComplete="false" value={values.userName} name="userName" type="text" onChange={handleChange}
                            error={touched.userName && errors.userName ? true : false}
                            helperText={touched.userName && t(`${errors.userName ? errors.userName : ''}`)}
                        />
                    </FormControl>
                    <Grid container spacing={0} alignItems="center" >
                        <Grid item xs>
                            <CadisButton type='submit' variant="contained" color='primary'
                                disabled={loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
                                {t('lbl_join')}
                            </CadisButton>
                        </Grid>
                    </Grid>
                </Stack>
            </form>
        </div>
    )
}

export default WebAssistantJoin