import { Grid, Stack, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useFormik } from 'formik';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { resendOtp } from '../../Common/api.routes';
import * as Helper from '../../Common/helper';
import CadisButton from '../../Components/Input/CadisButton';
import CadisTextField from '../../Components/Input/CadisTextField';
import CadisLoader from '../../Components/Loading/CadisLoader';
import apis from '../../HttpConfig/Api';
import { IUserOtp, ROLE, User } from '../../Interfaces/IConstants';
import { useUserRoleContext } from '../../context/UserRoleContext';


const LoginOtp = ({ userId }: any) => {

  const { t } = useTranslation();
  const { userLoginRole, setUserLoginRole, setSessionState } = useUserRoleContext();

  const validationSchema = Yup.object().shape({
    otp: Yup.string().matches(Helper.otpLengthExp, 'validation.msg_otp')
      .required('validation.msg_requirdOtp')
  })
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!userId) {
      navigate("/");
    }
  }, [userId])

  const { values, handleChange, handleSubmit, errors, touched, resetForm } = useFormik({
    initialValues: {
      otp: ''
    },
    onSubmit: async () => {
      setLoading(true);
      verifyOtpCall();
    },
    validationSchema: validationSchema
  })

  const verifyOtpCall = async () => {
    const data: IUserOtp = {
      user_id: parseInt(state.user_id),
      otp: values.otp
    }

    try {
      const response = await apis.loginInstance.post('auth/verifyOtp', data)
      if (response.data?.success && response.data?.token) {
        setSessionState("active");
        await sessionStorage.setItem("token", response.data?.token);
        const user: User = jwtDecode(response.data?.token);
        let username = response.data?.userName
        await sessionStorage.setItem("username", JSON.stringify(username));
        setUserRoleDetails(user.roles);
        if (user?.roles.includes(ROLE.CadisAdmin)) {
          navigate(Helper.navigateToOrganizations);
        }
        else if (user?.roles.includes(ROLE.OrgAdmin)) {
          navigate(Helper.navigateToConsultants);
        }
        else if (user?.roles.includes(ROLE.Consultant)) {
          navigate(Helper.navigateToAssistant);
        }

        toast.success(t('responseMsg.msg_loginSuccess'));
        setLoading(false);
      }
      else {
        if (response.data.error === (t('responseMsg.msg_wrongVerificationCode')))
          toast.warning(t('responseMsg.msg_invalidOtp'));
        else {
          toast.warning(t('responseMsg.msg_otpExpire'));
        }
        setLoading(false);
      }
    } catch (error: any) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  const setUserRoleDetails = (role: any) => {
    setUserLoginRole(role[0]);
    sessionStorage.setItem("role", role[0])
  }

  const resendOTP = async () => {
    const user_id = userId;
    resetForm();
    try {
      const res = await apis.loginInstance.post(`${resendOtp}`, { user_id });
      if (res.data?.success) {
        toast.success(t('responseMsg.msg_loginToOtp'));
        setLoading(false);
      } else {
        toast.error(t('responseMsg.msg_otpErrorMsg'));
        setLoading(false);
      }
    }
    catch (error: any) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <Typography className="mt-0 mb-5 prelogin-title" sx={(theme) => theme.typography.h3}>{t('lbl_otp')}</Typography>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <p>{t('lbl_otpMessage')}</p>
          <FormControl>
            <label className='form-label'>
              {t('lbl_otpMsg')}
              <span className='mandatory-star'></span>
            </label>
            <CadisTextField
              type="text"
              inputProps={{ maxLength: 6 }}
              value={values.otp}
              onChange={handleChange}
              name="otp"
              error={touched.otp && errors.otp ? true : false}
              helperText={touched.otp && t(`${errors.otp ? errors.otp : ''}`)}
            />
          </FormControl>

          <Grid container spacing={0}
            alignItems="center" >
            <Grid item xs>
              <CadisButton
                type='submit'
                variant="contained"
                color='primary' disabled={loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
                {t('lbl_continue')}
              </CadisButton>
            </Grid>
            <Grid item xs="auto">
              <div className="">
                <CadisButton
                  onClick={resendOTP}>
                  {t('lbl_resendOtp')}
                </CadisButton>
              </div>
            </Grid>
          </Grid>

        </Stack>
      </form>
    </div>
  )
}

export default LoginOtp