import { Box, Button, Chip, Grid, ListItem, ListItemText, Stack, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { auto } from '@popperjs/core';
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetOrganizationSubscriptionDetailsRoute, GetSubscriptionPlan, getExpiredPlanRolledOverData } from "../../Common/api.routes";
import { ActiveStatus, ButtonActionAdd, ButtonActionAddonPack, ButtonActionEdit, ButtonActionRenew, ButtonShowRenewPlanDetails, DefaultGridRowSize, DefaultRenewSubscriptionDetails, DefaultSubscriptionDetails, ExpiredStatus, FormAddMode, FormRenewMode, FormUpdateMode, FormViewMode, GridRowsPerPageOptions, InActiveStatus, navigateToOrganizations, paymentMode } from "../../Common/helper";
import { GetGridNoRecordMessage, GetShortDateWithLuxon, formateMbtoGb } from "../../Common/helperFunction";
import { GridStyling } from "../../Common/sharedStyling";
import BuildGridAction from "../../Components/Input/cadisBuildGridAction";
import apis from "../../HttpConfig/Api";
import { ISubscriptionDetails, IrenewSubscriptionInfo } from "../../Interfaces/ISubscription";
import { useUserRoleContext } from "../../context/UserRoleContext";
import AssignAddonPack from "../DataAddOns/AssignAddonPack";
import AddEditOrgSubscriptionDetails from "./AddEditOrgSubscriptionDetails";
import RenewedSubscriptionDetails from "./RenewedSubscriptionDetails";

const OrgSubscriptionDetails = () => {
    const [currentOrgSubscriptionDetails, setCurrentOrgSubscriptionDetails] = useState<ISubscriptionDetails>(DefaultSubscriptionDetails);
    const [subscriptionDetailsMode, setSubscriptionDetailsMode] = useState(FormViewMode);
    const [subscriptionDetailsDrawer, setsubscriptionDetailsDrawer] = useState<boolean>(false);
    const [orgSubscriptionDetailsGridData, setOrgSubscriptionDetailsGridData] = useState([]);
    const [orgDetails, setOrgDetails] = useState(JSON.parse(sessionStorage.getItem("orgDetails") || "{}"))
    const [assignNewAddon, setAssignNewAddon] = useState(false)
    const PageTitleRef: any = useRef();
    const tableGridRef: any = useRef();
    const [pageSize, setPageSize] = useState<number>(DefaultGridRowSize);
    const [tableHeight, setTableHeight] = useState<any>();
    const [isRenewPlanDetailsDrawerOpen, setIsRenewPlanDetailsDrawerOpen] = useState(false);
    const [subscriptionPlanDetails, setSubscriptionPlanDetails] = useState<IrenewSubscriptionInfo>(DefaultRenewSubscriptionDetails)
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [loading, setLoading] = useState(false);
    const { handleToggleLoader } = useUserRoleContext();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const columns: GridColDef[] = [
        { field: 'subscription_id', width: 1, hide: true },
        { field: 'plan_id', width: 1, hide: true },
        {
            field: 'name', flex: 1, headerName: 'Plan Name', minWidth: 160, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', sortable: true,
            renderCell: ({ row }: any) => {
                return <>
                    <ListItem disablePadding>
                        <Tooltip title={`${row.name}`} >
                            <ListItemText sx={{ textTransform: 'capitalize', }} className="long-col-name-ellipsis"
                                primary={`${row.name}`}
                                secondary=""
                            />
                        </Tooltip>
                    </ListItem>
                </>
            },
        },
        {
            field: 'active_user_count', headerName: 'Active Users', minWidth: 106, maxWidth: 160, flex: 1, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', sortable: false,
            renderCell: ({ row }: any) => (
                `${row.active_user_count}`
            ),
        },
        {
            field: 'users_for_assignment', headerName: 'Available User Count for Assignment', minWidth: 140, maxWidth: 200, flex: 1, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', sortable: false,
            renderCell: ({ row }: any) => (
                `${row.users_for_assignment}`
            ),
        },
        {
            field: 'user_bundle', headerName: 'User Bundles', minWidth: 120, maxWidth: 160, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', sortable: false, flex: 1,
            renderCell: ({ row }: any) => (
                <Typography variant="inherit" component="div" color="inherit" className="">{row.subscription_count}&nbsp;
                    <Typography variant="body1" component="span" fontSize={'0.8rem'} color="GrayText">({row.user_count} Users)</Typography>
                </Typography>
            ),
        },
        {
            field: 'actual_start_date', headerName: 'Purchase Date', minWidth: 130, maxWidth: 160, flex: 1, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', sortable: false,
            renderCell: ({ row }: any) => (
                row.actual_start_date ? GetShortDateWithLuxon(row.actual_start_date) : ''
            ),
        },
        {
            field: 'actual_end_date', flex: 1, headerName: 'Expiry Date', minWidth: 160, maxWidth: 200, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', sortable: true,
            renderCell: ({ row }: any) => {
                return <ListItem disablePadding>
                    {row.actual_end_date ?
                        <ListItemText primary={GetShortDateWithLuxon(row.actual_end_date)}
                            secondary={<React.Fragment>
                                {
                                    row.actual_end_date && row.renew?.length ? <Tooltip title={row.renew[0].payment_status === "Succeeded" ? 'Subscription is already renewed' : row.renew[0].payment_status === "Failed" ? ` ${t("lbl_preRenewalFailed")}` :
                                        row.renew[0].payment_status === "Pending" ? `${("lbl_preRenewalInProgress")}` : ""}>
                                        <Typography variant="inherit" color={row.renew[0].payment_status === "Succeeded" ? 'success.main' : row.renew[0].payment_status === "Failed" ? 'error' : row.renew[0].payment_status === "Pending" ? 'warning.main' : ""}>
                                            {row.renew[0].payment_status === "Succeeded" ? `[${t("lbl_preRenewed")}]` : row.renew[0].payment_status === "Failed" ? `[${t("lbl_renewFailed")}]` :
                                                row.renew[0].payment_status === "Pending" ? `[${t("lbl_renewPending")}]` : ""}
                                        </Typography>
                                    </Tooltip> : ""
                                }
                            </React.Fragment >}
                        /> : ''}
                </ListItem >
            }
        },
        {
            field: 'payment_mode', headerName: 'Payment Mode', minWidth: 130, maxWidth: 160, flex: 1, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', sortable: false,
            renderCell: ({ row }: any) => (
                `${row.payment_mode}`
            ),
        },
        {
            field: 'subscription_status', headerName: 'Status',
            cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', minWidth: 100, maxWidth: 160, flex: 1, sortable: true,
            sortComparator: (row1, row2) => {
                if (row1 < row2)
                    return -1;
                if (row1 > row2)
                    return 1;

                return 0;
            },
            renderCell: ({ row }: any) => {
                return <Chip size="small" label={row.subscription_status} color={row.subscription_status === "Payment Pending" ? "warning" : row.subscription_status === "Active" ? 'success' : 'error'} />
            }
        },
        {
            field: 'rollover_data_limit', headerName: 'Rolled Over Data',
            cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', minWidth: 110, maxWidth: 160, flex: 1, sortable: false,
            renderCell: ({ row }: any) => row.rollover_data_limit == 0 ? '-' : !isNaN(row.rollover_data_limit) ? (formateMbtoGb(row.rollover_data_limit)) : "-"
        },
        {
            field: 'data_limit', headerName: 'Subscription Data',
            cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', minWidth: 120, maxWidth: 160, flex: 1, sortable: false,
            renderCell: ({ row }: any) => <span>{row.is_data_unlimited === 1 ? "Unlimited" : (!isNaN(row.data_limit) && row.data_limt !== null) ? (formateMbtoGb(row.data_limit)) : "-"}</span>
        },
        {
            field: 'addon_data_limit', headerName: 'Add-On Data',
            cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', minWidth: 160, maxWidth: 200, flex: 1, sortable: false,
            renderCell: ({ row }: any) => {
                return <ListItem disablePadding>
                    <ListItemText primary={row.addon_data_limit == 0 ? '-' : !isNaN(row.addon_data_limit) ? (formateMbtoGb(row.addon_data_limit)) : "-"}
                        secondary={<React.Fragment>
                            {
                                row?.pending_addon_data_limit > 0 ? <Tooltip title={`${t("lbl_paymentFor")} ${formateMbtoGb(row?.pending_addon_data_limit)} ${t("lbl_dataIsInProgressInfo")}`}>
                                    <Typography variant='inherit'>
                                        {` [ +${formateMbtoGb(row?.pending_addon_data_limit)} ${t("lbl_pending")}]`}
                                    </Typography>
                                </Tooltip> : ""
                            }
                        </React.Fragment>}
                    />
                </ListItem>
            }
        },
        {
            field: 'total_data_used', headerName: 'Total Consumed Data',
            cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', minWidth: 110, maxWidth: 160, flex: 1, sortable: false,
            renderCell: ({ row }: any) => (<span>{!isNaN(row.total_data_used) && row.total_data_used !== null && row.total_data_used != 0 ? (formateMbtoGb(row.total_data_used)) : "-"}</span>)
        },
        {
            field: 'remaining_data_limit', headerName: 'Total Remaining Data',
            cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', minWidth: 110, maxWidth: 180, flex: 1, sortable: false,
            renderCell: ({ row }: any) => <span className={row.remaining_data_Percentage <= 10 ? "text-danger" : ""}>{!isNaN(row.remaining_data_limit) ? (formateMbtoGb(row.remaining_data_limit)) : "-"}</span>
        },
        {
            field: 'actions', headerAlign: 'center',
            cellClassName: ' app-theme-cell bg-action', headerClassName: ' app-theme-header bg-action',
            flex: 1,
            headerName: 'Actions', align: 'center',
            minWidth: 240, maxWidth: 340, sortable: false,
            renderCell: (params: any) => {
                const status = (params.row.is_disabled && params.row.is_expired) ? true : params.row.is_expired ? false : true;
                const isDisabled: boolean = params.row.is_disabled;
                const subscriptionStatus = params.row.subscription_status === "Payment Pending"
                    || params.row.subscription_status === "Payment cancelled"
                    || params.row.subscription_status === "Payment Failed";
                const isShowDataAddOn = (!subscriptionStatus
                    && params.row.subscription_status
                    && params.row.is_data_unlimited == 0 &&
                    params.row.subscription_status !== ExpiredStatus
                    && params.row.is_disabled == 0)
                const renewSubscriptionPaymentStatus = (params.row.renew.length > 0 && params.row.renew[0].payment_status === "Pending") ? true : false
                return <Stack direction="row" spacing={0}>
                    {!isDisabled && !subscriptionStatus && ((params.row.renew.length === 0) || (params.row.renew[0]?.payment_status === "Cancelled" && params.row.renew.length === 1)) ?
                        <BuildGridAction 
                            title={params.row.plan_status === 0 ? t("lbl_planNoLongerActive") : t("lbl_renewSubscription")}
                            onClickAction={(e: any) => ((params.row.plan_status && !renewSubscriptionPaymentStatus) ? onClick(e, params) : "")}
                            actionParams={params}
                            placement="bottom-end"
                            color={params.row.plan_status === 0 ? "default" : "primary"}
                            ActionId={ButtonActionRenew}
                        >
                            {<span className='cad-renew-subscription'></span>}
                        </BuildGridAction>
                        : params.row?.renew.length ? <BuildGridAction
                            title={t("lbl_viewRenewedSubPlanDetails")}
                            onClickAction={(e: any) => onClick(e, params)}
                            actionParams={params}
                            placement="bottom-end"
                            color="primary"
                            ActionId={ButtonShowRenewPlanDetails}
                        >
                            <span className="cad-tick-icon"></span>
                        </BuildGridAction> : <Box className="grid-act-btn"></Box>}

                    {isShowDataAddOn ? <BuildGridAction
                        title={t("lbl_assignAddOnForm")}
                        onClickAction={(e: any) => onClick(e, params)}
                        actionParams={params}
                        placement="bottom-end"
                        color="primary"
                        ActionId={ButtonActionAddonPack}
                    >
                        <span className="cad-plus"></span>
                    </BuildGridAction> : <Box className='grid-act-btn'></Box>}
                    <Box className='grid-act-btn'></Box>
                    {status && !subscriptionStatus ? (
                        <BuildGridAction
                            title={renewSubscriptionPaymentStatus ? t("lbl_paymentInProgressCantEdit") : `${t("lbl_editSubscription")}`}
                            onClickAction={(e: any) => renewSubscriptionPaymentStatus ? "" : onClick(e, params)}
                            actionParams={params}
                            placement="bottom-end"
                            color={renewSubscriptionPaymentStatus ? "default" : "primary"}
                            ActionId={ButtonActionEdit}
                            disabled={renewSubscriptionPaymentStatus}
                        >
                            {<span className="cad-edit"></span>}
                        </BuildGridAction>
                    ) : <Box className='grid-act-btn'></Box>}
                </Stack>
            }
        }
    ];

    const GetOrgSubscriptionPlanDetails = async () => {
        handleToggleLoader(true);
        const roleId: number = Number(sessionStorage.getItem("role"));
        const params = {
            'org_id': orgDetails.orgId,
            'role_id': roleId
        }
        try {
            const response = await apis.instance.get(`${GetOrganizationSubscriptionDetailsRoute}`, { params });
            if (response.data.success) {
                const responseData = response?.data?.data.map((item: any) => {
                    return {
                        ...item,
                        user_bundle: `${item.subscription_count}(${item.user_count})`,
                        subscription_status: getStatus(item),
                        users_for_assignment: item.user_count - item.active_user_count
                    }
                })
                responseData.reverse();
                const filteredSubscriptionPlans = GetFilteredSubscriptionPlans(responseData)
                setOrgSubscriptionDetailsGridData(filteredSubscriptionPlans);
            }
            handleToggleLoader(false);
        } catch (err) {
            handleToggleLoader(false);
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const GetFilteredSubscriptionPlans = (dataToFilter: any) => {
        return dataToFilter.filter((el: any) => {
            return el.subscription_status !== "Payment cancelled" && el.subscription_status !== "Payment Failed"
        })
    }

    const getStatus = (data: any) => {
        const paymetStatus = data.payment_status;
        const isDisabled = data.is_disabled;
        // Determine status based on conditions
        const status = isDisabled ? InActiveStatus : data.is_expired ? ExpiredStatus
            : paymetStatus === "Succeeded" ? ActiveStatus : paymetStatus === "Cancelled" ? "Payment cancelled"
                : paymetStatus === "Failed" ? "Payment Failed" : "Payment Pending";

        return status;
    }

    const GetAllSubscriptionPlan = async () => {
        try {
            const response = await apis.instance.get(`${GetSubscriptionPlan}`);
            const responseData = response?.data?.data;
            responseData.sort((a: any, b: any) => a.name.localeCompare(b.name));
            responseData.unshift({ plan_id: 0, name: 'Select Plan Name', max_user_bundle_count: 0, price: 0 });
            setSubscriptionPlans(responseData);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        GetOrgSubscriptionPlanDetails();
        GetAllSubscriptionPlan();
        getPageTitleHeight();

        // on unmount of page clear orgdetails 
        return () => {
            sessionStorage.removeItem("orgDetails")
        }
    }, []);

    // dynamic scrollbar for grid view start
    const getPageTitleHeight = () => {
        let pageTitleHeight: any = 0;
        if (PageTitleRef && PageTitleRef.current) {
            pageTitleHeight = PageTitleRef.current.clientHeight;
        }
        setTableHeight(window.innerHeight - pageTitleHeight - 82);
    };
    window.addEventListener("resize", getPageTitleHeight);

    const onBackClick = () => {
        navigate(navigateToOrganizations);
    }

    const onClick = (e: any, params: any) => {
        e.stopPropagation();
        OnActionClick(e.currentTarget.id, params);
    };

    const getUpdatedPlanDetails = async (subscriptionId: number) => {
        const params = { "subscriptionId": subscriptionId };
        try {
            const resp = await apis.instance.get(`${getExpiredPlanRolledOverData}`, { params });
            if (resp.data.success) {
                return resp.data;
            } else {
                toast.error(resp.data.error || t('responseMsg.msg_wrongEntry'));
            }
        } catch (error) {
            toast.error(t('responseMsg.msg_wrongEntry'));
        }
    };

    const OnActionClick = async (actionId: string, params: any) => {
        switch (actionId) {
            case ButtonActionAdd:
                setSubscriptionDetailsMode(FormAddMode);
                setCurrentOrgSubscriptionDetails(DefaultSubscriptionDetails)
                setsubscriptionDetailsDrawer(true);
                break;
            case ButtonActionEdit:
                setSubscriptionDetailsMode(FormUpdateMode);
                setsubscriptionDetailsDrawer(true)
                const editSubscription = {
                    'user_count': params.row.user_count,
                    'org_id': orgDetails.org_id,
                    'plan_id': params.row.plan_id,
                    'subscription_id': params.row.subscription_id,
                    'subscription_count': params.row.subscription_count,
                    'is_disabled': params.row.is_disabled,
                    'paid_amount': params.row.paid_amount,
                    'payment_mode': params.row.payment_mode,
                    'send_invoice_flag': true,
                    'start_date': params.row.start_date,
                    'active_user_count': params.row.active_user_count,
                    "renew_payment_status": params.row.renew_payment_status,
                    'data_limit': params.row.is_data_unlimited == 1 ? 0 : params.row.data_limit,
                    'is_data_unlimited': params.row.is_data_unlimited == 1 ? true : false,
                    'isPlanExpired': params.row.is_expired == 1 ? true : false,
                    'totalDataOver': (parseFloat(params.row.rollover_data_limit) + parseFloat(params.row.addon_data_limit)) / 1024,
                    'billing_cycle': params.row.billing_cycle,
                    'plan_data_limit': (Number(params.row.plan_data_limit) / 1024).toString()

                }
                setCurrentOrgSubscriptionDetails(editSubscription)
                break;
            case ButtonActionRenew:
                setsubscriptionDetailsDrawer(true)
                setSubscriptionDetailsMode(FormRenewMode);
                const subscriptionIdSuperAdmin = params.row.subscription_id;
                const updatedPlanDetailsSuperAdmin = await getUpdatedPlanDetails(subscriptionIdSuperAdmin);
                if (updatedPlanDetailsSuperAdmin.success) {
                    const renewSubscription = {
                        'user_count': (updatedPlanDetailsSuperAdmin?.data.max_user_bundle_count * params.row.subscription_count),
                        'org_id': orgDetails.org_id,
                        'plan_id': updatedPlanDetailsSuperAdmin?.data.plan_id,
                        'subscription_id': params.row.subscription_id,
                        'subscription_count': params.row.subscription_count,
                        'is_disabled': params.row.is_disabled,
                        'paid_amount': params.row.paid_amount,
                        'payment_mode': paymentMode[0],
                        'send_invoice_flag': true,
                        'start_date': params.row.start_date,
                        'active_user_count': params.row.active_user_count,
                        "renew_payment_status": params.row.renew_payment_status,
                        'data_limit': (updatedPlanDetailsSuperAdmin?.data.data_limit * params.row.subscription_count),
                        'is_data_unlimited': updatedPlanDetailsSuperAdmin.data.is_data_unlimited,
                        'isPlanExpired': updatedPlanDetailsSuperAdmin?.data.is_expired,
                        'totalDataOver': (parseFloat((updatedPlanDetailsSuperAdmin?.data.rolloverDataLimit / 1024).toString())),
                        'billing_cycle': updatedPlanDetailsSuperAdmin?.data.billing_cycle,
                        'plan_data_limit': (Number(updatedPlanDetailsSuperAdmin?.data.data_limit / 1024).toString())
                    }
                    setCurrentOrgSubscriptionDetails(renewSubscription)
                }
                break;
            case ButtonActionAddonPack:
                setAssignNewAddon(true)
                setCurrentOrgSubscriptionDetails({ ...DefaultSubscriptionDetails, subscription_id: params.row.subscription_id })
                break;
            case ButtonShowRenewPlanDetails:
                const renewInfoDetails: IrenewSubscriptionInfo = {
                    planName: params.row.renew[0].name,
                    startDate: GetShortDateWithLuxon(params.row.renew[0].actual_start_date),
                    endDate: GetShortDateWithLuxon(params.row.renew[0].actual_end_date),
                    paymentStatus: params.row.renew[0].payment_status,
                    totalData: params.row.renew[0].is_data_unlimited === 1 ? 0 : params.row.renew[0].data_limit
                }
                setSubscriptionPlanDetails(renewInfoDetails)
                setIsRenewPlanDetailsDrawerOpen(true)
                setSubscriptionDetailsMode(FormRenewMode);
                const renewSubscriptionPlan = {
                    'user_count': params.row.user_count,
                    'org_id': orgDetails.org_id,
                    'plan_id': params.row.plan_id,
                    'subscription_id': params.row.subscription_id,
                    'subscription_count': params.row.subscription_count,
                    'is_disabled': params.row.is_disabled,
                    'paid_amount': params.row.paid_amount,
                    'payment_mode': paymentMode[0],
                    'send_invoice_flag': true,
                    'start_date': params.row.start_date,
                    'active_user_count': params.row.active_user_count,
                    "renew_payment_status": params.row.renew_payment_status,
                    'data_limit': params.row.data_limit,
                    'is_data_unlimited': params.row.is_data_unlimited == 1 ? true : false,
                    'isPlanExpired': params.row.is_expired == 1 ? true : false,
                    'totalDataOver': (parseFloat(params.row.rollover_data_limit) + parseFloat(params.row.addon_data_limit)) / 1024,
                    'billing_cycle': params.row.billing_cycle,
                    'plan_data_limit': (Number(params.row.plan_data_limit) / 1024).toString()

                }
                setCurrentOrgSubscriptionDetails(renewSubscriptionPlan)
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <Box ref={PageTitleRef} className="page-title-row pb-3">
                <Grid
                    container
                    alignItems={"center"}
                    textAlign={"left"}
                    rowSpacing={2}
                    columnSpacing={2}>
                    <Grid item xs={auto} >
                        <Button id='backbtn'
                            onClick={(e) => { onBackClick() }}
                            startIcon={<span className='cad-arrow-left'></span>}
                            variant="outlined" color="primary">
                            {t('lbl_goBack')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={auto} mr={auto} sm padding={0} className="page-title-box">
                        <Typography
                            className="page-title mt-0 mb-0"
                            sx={(theme) => theme.typography.h5}>
                            {t('lbl_subscriptionDetails')} -&nbsp;
                            <Typography
                                className="page-title mt-0 mb-0"
                                sx={{ font: 'inherit' }} display="inline" color='success.main'>
                                {orgDetails?.orgName}
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={auto} padding={0}>
                        <Button id={ButtonActionAdd}
                            onClick={(e) => { onClick(e, null) }}
                            startIcon={<span className='cad-plus'></span>}
                            variant="contained" sx={{ my: .5, mr: 1 }}>
                            {t('lbl_assign')}<Typography sx={{ font: 'inherit' }} display={{ xs: 'none', md: 'inline' }}>&nbsp;{t('lbl_newSubscription')}</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={GridStyling} ref={tableGridRef} height={tableHeight}>
                <DataGrid
                    components={{
                        NoRowsOverlay: GetGridNoRecordMessage,
                    }}
                    rows={orgSubscriptionDetailsGridData}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
                    rowsPerPageOptions={GridRowsPerPageOptions}
                    getRowId={(row: any) => row.subscription_id}
                    autoHeight={false}
                    autoPageSize={false}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    disableSelectionOnClick={true}
                    headerHeight={72}
                />
            </Box>
            {subscriptionDetailsMode !== FormViewMode ? <AddEditOrgSubscriptionDetails
                isOpenOrgSubscriptionDetails={subscriptionDetailsDrawer}
                setIsOpenOrgSubscriptionDetails={setsubscriptionDetailsDrawer}
                currentOrgSubscriptionDetails={currentOrgSubscriptionDetails}
                allSubcriptionPlans={subscriptionPlans}
                mode={subscriptionDetailsMode}
                GetOrgSubscriptionPlanDetails={GetOrgSubscriptionPlanDetails}
                currentOrgData={orgDetails}
            /> : null}
            {assignNewAddon ?
                <AssignAddonPack selectedSubscriptionId={currentOrgSubscriptionDetails.subscription_id} isAddonPackOpen={assignNewAddon}
                    setIsAddonPackOpen={setAssignNewAddon}
                    GetOrgSubscriptionPlanDetails={GetOrgSubscriptionPlanDetails}
                /> : ""
            }
            <RenewedSubscriptionDetails
                setRenewSubscriptionDrawer={setsubscriptionDetailsDrawer}
                currentRenewPlanDetails={subscriptionPlanDetails}
                setIsRenewPlanDetailsDrawerOpen={setIsRenewPlanDetailsDrawerOpen}
                isRenewPlanDetailsDrawerOpen={isRenewPlanDetailsDrawerOpen} />
        </div>
    );
}
export default OrgSubscriptionDetails;