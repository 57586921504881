import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isAuthenticated } from "../Common/helperFunction";
import { Iroutes } from "../Interfaces/IConstants";
import { t } from "i18next";

const PrivateRoute: React.FC<Iroutes> = ({ children: RouteComponent, roles }) => {

    const user: any = isAuthenticated();

    let userHasRequiredRole = false;

    if (user) {
        userHasRequiredRole = roles.some(t => user.roles.includes(t)) ? true : false;
    }

    if (user && userHasRequiredRole) {
        return RouteComponent
    }
    else if (user && !userHasRequiredRole) {
        toast.warning(`${t("lbl_accessDenied")}`);
        return <Navigate to="/login" />
    }

    return <Navigate to="/login" />
}

export default PrivateRoute;