import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { Dispatch, MutableRefObject, ReactNode, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Socket } from "socket.io-client";
import { SendRoomInviteLink, SendRoomInviteLinkByMessage, SendRoomInviteLinkToSelf } from "../Common/api.routes";
import { ANSWER_REJECT, ANSWER_SUCCESS, BUSY, CALL_END_BY_ASSISTANT, CALL_REJECTED, END_CALL, END_OUTGOING_CALL, INCOMING_CALL, INVITATION_SENT, JOIN_ROOM, MISSED_CALL, PING, PONG, PRIVATE_ROOM_CREATED, RECONNECT, REJOIN_ROOM, RESEND_ROKID_RESOLUTION, ROOM_CREATED, ROOM_DESTROYED, SESSION_DETAILS, SET_WEB_AST_NAME, USER_CON_MUTE, USER_CON_UNMUTE, USER_MUTE, USER_STATUS, USER_UNMUTE, WEB_ASSISTANT_MUTE, WEB_ASSISTANT_UNMUTE, currentHost, maxPhoneLength, minPhoneLength, numberAndDashRegex, phoneNumberLengthValidationMessage, phoneNumberStartEndHyphenValidationMessage, pingInterval, ringtoneTimeout } from "../Common/helper";
import { isAuthenticated } from "../Common/helperFunction";
import CadisDialog from "../Components/Dialog/CadisDialog";
import CadisTextField from "../Components/Input/CadisTextField";
import CadisLoader from "../Components/Loading/CadisLoader";
import apis from "../HttpConfig/Api";
import { ROLE } from "../Interfaces/IConstants";
import { ISocketUserDetails } from "../Interfaces/IUser";
import { IOnlineUsers } from "../Interfaces/lAssistantsData";
import AssistantListForCalling from "../Pages/CallScreen/AssistantListForCalling";

const song = require("../audio/Ring_Synth.mp3");
const callerTone = require("../audio/Phone Ringing Sound.mp3")

interface CallToAssistant {
  open: boolean;
  UserID: null;
  AssistantName: string;
}
export interface ISocketType {
  roomDetails: any;
  webAsstRoomDetails: any;
  failedPong: Number;
  janusURL: String | null;
  CallToAssistant: any;
  onlineUsers: IOnlineUsers | null;
  socketRef: MutableRefObject<Socket | null>;
  callToAssistantRef: MutableRefObject<CallToAssistant>;
  callerToneRef: React.MutableRefObject<HTMLAudioElement | undefined>;
  ringtoneRef: React.MutableRefObject<HTMLAudioElement | undefined>;
  pingIntervalRef: React.MutableRefObject<any>;
  sessionIdRef: React.MutableRefObject<number | null>;
  isConferenceCall: boolean;
  rokidInviteDialog: boolean;
  roomData: any;
  isMuteConsultant: boolean;
  isMuteRokid: boolean;
  isWebAsstMuted: boolean;
  isNormalCall: boolean;
  wastInviteSent: boolean;
  rokidInviteSent: boolean;
  endCallDialog: boolean;
  setOnlineUsers: Dispatch<SetStateAction<IOnlineUsers | null>>;
  setCallToAssistant: Dispatch<SetStateAction<any>>;
  setInCommingCall: Dispatch<SetStateAction<boolean>>;
  setUserDetails: Dispatch<SetStateAction<ISocketUserDetails | null>>;
  setRoomDetails: Dispatch<SetStateAction<any>>;
  setWebAsstRoomDetails: Dispatch<SetStateAction<any>>;
  setJanusURL: Dispatch<SetStateAction<String | null>>;
  setEndCallDialog: Dispatch<SetStateAction<any>>;
  openIncomingCallBox: () => JSX.Element;
  openCallToAssistantBox: () => JSX.Element;
  openReconnectDialog: () => JSX.Element;
  setInviteDialog: Dispatch<SetStateAction<boolean>>;
  setRokidInviteDialog: Dispatch<SetStateAction<boolean>>;
  setIsConferenceCall: Dispatch<SetStateAction<boolean>>;
  openInviteDialog: () => JSX.Element;
  openRokidUserInviteDialog: () => JSX.Element;
  handleCloseSocket: () => void;
  openEndCallPopup: () => JSX.Element;
  setRoomData: Dispatch<SetStateAction<any>>;
  SetIsMuteConsultant: Dispatch<SetStateAction<boolean>>;
  SetIsMuteRokid: Dispatch<SetStateAction<boolean>>;
  SetIsWebAsstMuted: Dispatch<SetStateAction<boolean>>;
  setEmailAddress: Dispatch<SetStateAction<string>>;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  setIsNormalCall: Dispatch<SetStateAction<boolean>>;
  setWastInviteSent: Dispatch<SetStateAction<boolean>>;
  setSelfEmailAddress: Dispatch<SetStateAction<string>>;
  setRokidInviteSent: Dispatch<SetStateAction<boolean>>;
}
type ContextProviderProps = {
  children: ReactNode;
};

const defaultValue = {
} as ISocketType;

// creating socketContext
export const SocketContext = React.createContext(defaultValue);
export function useSocketContext() {
  return useContext(SocketContext);
}

// variables for call notification timeout 
let inCommingCallTimeout: any;
let CallToAssistantTimeout: any;
let reconnectTimeout: any;
let sfutest: any = null;

function ContextProvider({ children }: ContextProviderProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [inCommingCall, setInCommingCall] = useState<boolean>(false);
  const [CallToAssistant, setCallToAssistant] = useState<CallToAssistant>({
    open: false,
    UserID: null,
    AssistantName: "",
  });
  const [assistantName, setAssistantName] = useState<string | null>(null);
  const [userDetails, setUserDetails] = useState<ISocketUserDetails | null>(null);
  const [roomDetails, setRoomDetails] = useState<any>(null);
  const [webAsstRoomDetails, setWebAsstRoomDetails] = useState<any>(null);
  const [janusURL, setJanusURL] = useState<String | null>(null);
  const [onlineUsers, setOnlineUsers] = useState<IOnlineUsers | null>(null);
  const [failedPong, setFailedPong] = useState<number>(0);
  const [reconnectDialog, setReconnectDialog] = useState<boolean>(false);
  const [inviteDialog, setInviteDialog] = useState<boolean>(false);
  const [isConferenceCall, setIsConferenceCall] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [endCallDialog, setEndCallDialog] = useState(false);
  const [rokidInviteDialog, setRokidInviteDialog] = useState<boolean>(false);
  const [roomData, setRoomData] = useState<String | null>(null);
  const [isMuteConsultant, SetIsMuteConsultant] = useState<boolean>(false);
  const [isMuteRokid, SetIsMuteRokid] = useState<boolean>(false);
  const [isWebAsstMuted, SetIsWebAsstMuted] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState('SMS');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [isNumberValid, setIsNumberValid] = useState(true);
  const [phoneNumberErrorMsg, setPhoneNumberErrorMsg] = useState<string>('');
  const [isNormalCall, setIsNormalCall] = useState(true);
  const [wastInviteSent, setWastInviteSent] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const user = isAuthenticated();
  const [selfEmailAddress, setSelfEmailAddress] = useState(user?.userEmail);
  const [rokidInviteSent, setRokidInviteSent] = useState(false);

  let callToAssistantRef = useRef<CallToAssistant>({ open: false, UserID: null, AssistantName: "" });
  let inCommingCallRef = useRef<boolean>(false);
  let ringtoneRef = useRef<HTMLAudioElement>();
  let callerToneRef = useRef<HTMLAudioElement>();
  let pingIntervalRef = useRef<any>();
  let socketRef = useRef<Socket | null>(null);
  let sessionIdRef = useRef<number | null>(null);

  useEffect(() => {
    let userName = userDetails?.username;
    // RINGTONE
    let audio = document.createElement('audio');
    audio.src = song;
    audio.loop = true;
    ringtoneRef.current = audio;

    // CALLER TONE
    let callAudio = document.createElement('audio');
    callAudio.src = callerTone;
    callAudio.loop = true;
    callerToneRef.current = callAudio;

    // ping event sent to server
    socketRef.current?.emit(PING, { UserName: userName });

    if (socketRef.current && userDetails?.userrole === 3 && !reconnectTimeout) {
      reconnectTimeout = setTimeout(() => {
        socketRef.current?.emit(RECONNECT);
      }, 2000);
    }

    // IF USER ROLE IS 3 AND SOCKET IS CONNECTED THEN SEND PING TOM SERVER
    if (userDetails?.userrole === 3 && socketRef.current) {
      pingIntervalRef.current = setInterval((): void => {
        setFailedPong(failedPong => failedPong + 1);
        socketRef.current?.emit(PING, { UserName: userName });
      }, pingInterval);
    }

    // pong event getting from server
    socketRef.current?.on(PONG, () => {
      setFailedPong(0);
    });

    socketRef.current?.on(USER_STATUS, (data => { setOnlineUsers(data) }));

    socketRef.current?.on(SESSION_DETAILS, sessionDetails => {
      sessionIdRef.current = sessionDetails.sessionId
    })

    // incomming call from assistant socket event
    socketRef.current?.on(INCOMING_CALL, (data) => {
      sessionIdRef.current = data?.sessionId;
      setAssistantName(data?.assistantName);
      setInCommingCall(true);
      inCommingCallRef.current = true;
      ringtoneRef.current?.play();
    });

    socketRef.current?.on(INVITATION_SENT, (assistantUserName) => {
      sessionStorage.setItem("assistantUserName", assistantUserName)
    });

    // if call accepeted from any of assistant OR consultant
    socketRef.current?.on(ROOM_CREATED, (data) => {
      setRoomDetails({
        roomId: data?.room,
        turnServer: [{
          url: data?.turnServer[0].turnServerUrl,
          username: data?.turnServer[0].turnServerUsername,
          credential: data?.turnServer[0].turnServerCredential
        }]
      })
      setJanusURL(data?.url);
      clearTimeout(CallToAssistantTimeout);
      setIsConferenceCall(false)
      setIsNormalCall(true)
      sessionStorage.setItem('isConferenceCall', JSON.stringify(false));

      if (callToAssistantRef.current?.UserID) {
        navigate('/call', { state: { assistantName: callToAssistantRef.current?.AssistantName } });
        callerToneRef.current?.pause();
        if (callerToneRef.current) callerToneRef.current.currentTime = 0;
      }
    });

    // if call is rejected from assistant
    socketRef.current?.on(CALL_REJECTED, (data: any) => {
      if (callToAssistantRef.current?.open) {
        setCallToAssistant({ open: false, UserID: null, AssistantName: "" });
        callToAssistantRef.current = { open: false, UserID: null, AssistantName: '' };
        callerToneRef.current?.pause();
        if (callerToneRef.current) callerToneRef.current.currentTime = 0;
        clearTimeout(CallToAssistantTimeout);
        CallToAssistantTimeout = null;
      }

      if (inCommingCallRef.current) {
        setInCommingCall(false);
        inCommingCallRef.current = false;
        ringtoneRef.current?.pause();
        if (ringtoneRef.current) ringtoneRef.current.currentTime = 0;
        clearTimeout(inCommingCallTimeout);
        inCommingCallTimeout = null;
      }
      toast.info(data + t("lbl_rejectCall"));
    });

    //if call is End by consultant after initiating the call
    socketRef.current?.on(END_OUTGOING_CALL, () => {
      if (callToAssistantRef.current?.open) {
        setCallToAssistant({ open: false, UserID: null, AssistantName: "" });
        callToAssistantRef.current = { open: false, UserID: null, AssistantName: '' };
        callerToneRef.current?.pause();
        if (callerToneRef.current) callerToneRef.current.currentTime = 0;
        clearTimeout(CallToAssistantTimeout);
        CallToAssistantTimeout = null;
      }

      if (inCommingCallRef.current) {
        setInCommingCall(false);
        inCommingCallRef.current = false;
        ringtoneRef.current?.pause();
        if (ringtoneRef.current) ringtoneRef.current.currentTime = 0;
        clearTimeout(inCommingCallTimeout);
        inCommingCallTimeout = null;
      }
    });

    // if user is busy
    socketRef.current?.on(BUSY, () => {
      toast.warning(t('responseMsg.msg_userIsBusy'));
      setCallToAssistant({ open: false, UserID: null, AssistantName: "" });
      callToAssistantRef.current = { open: false, UserID: null, AssistantName: '' };
      callerToneRef.current?.pause();
      if (callerToneRef.current) callerToneRef.current.currentTime = 0;
      clearTimeout(CallToAssistantTimeout);
      CallToAssistantTimeout = null;
    });

    // COMENTED FOR RECONNECTING PART
    socketRef.current?.on(REJOIN_ROOM, (data) => {
      sessionIdRef.current = data?.room;
      setRoomDetails({
        roomId: data?.room,
        turnServer: [{
          url: data?.turnServer[0].turnServerUrl,
          username: data?.turnServer[0].turnServerUsername,
          credential: data?.turnServer[0].turnServerCredential
        }]
      })
      setJanusURL(data?.url);
      setAssistantName(data?.assistantName);
      reconnectTimeout = null;
      if (location.pathname === '/call') return
      setReconnectDialog(true);
      reconnectTimeout = null;
    })

    // Create private room
    socketRef.current?.on(PRIVATE_ROOM_CREATED, (data) => {
      sessionIdRef.current = data?.room;
      setRoomDetails({
        roomId: data?.room,
        turnServer: [{
          url: data?.turnServer[0].turnServerUrl,
          username: data?.turnServer[0].turnServerUsername,
          credential: data?.turnServer[0].turnServerCredential
        }]
      })
      setInviteDialog(false)
      setJanusURL(data?.url);
      navigate("/call");
      setIsConferenceCall(true)
      setIsNormalCall(false)
      sessionStorage.setItem('isConferenceCall', JSON.stringify(true));
    })

    //Setting State when consultant or Rokid is on mute
    socketRef.current?.on(USER_MUTE, () => {
      SetIsMuteRokid(true);
    });
    socketRef.current?.on(USER_CON_MUTE, () => {
      SetIsMuteConsultant(true);
    });
    socketRef.current?.on(WEB_ASSISTANT_MUTE, () => {
      SetIsWebAsstMuted(true);
    });

    //Setting state when consultant or Rokid is on unMute
    socketRef.current?.on(USER_UNMUTE, () => {
      SetIsMuteRokid(false);
    });
    socketRef.current?.on(USER_CON_UNMUTE, () => {
      SetIsMuteConsultant(false);
    });
    socketRef.current?.on(WEB_ASSISTANT_UNMUTE, () => {
      SetIsWebAsstMuted(false);
    });

    // on webAssistant join the room
    socketRef.current?.on(JOIN_ROOM, (data) => {
      if (data?.room != null) {
        setRoomData(data)
      }
      sessionIdRef.current = data?.room;
      setWebAsstRoomDetails({
        roomId: data?.room,
        turnServer: [{
          url: data?.turnServer[0].turnServerUrl,
          username: data?.turnServer[0].turnServerUsername,
          credential: data?.turnServer[0].turnServerCredential
        }]
      })
      setJanusURL(data?.url);
      sessionStorage.setItem("consultantName", data?.consultantName)
      sessionStorage.setItem("webAssistantName", data?.webAssistantName)
      if (data?.userMuteStatus === "muted") {
        SetIsMuteConsultant(true)
      }
    })

    socketRef.current?.on(SET_WEB_AST_NAME, (data) => {
      sessionStorage.setItem("webAssitName", data)
    })

    socketRef.current?.on(ROOM_DESTROYED, async () => {
      await sfutest?.hangup();
      setReconnectDialog(false);
    });

    socketRef.current?.on(CALL_END_BY_ASSISTANT, async (data: any) => {
      await sfutest?.hangup();
      setReconnectDialog(false);
      toast.info(data + t("lbl_hasEndCall"))
    });

    return () => {
      socketRef.current?.off();
    }

  }, [socketRef.current]);

  useEffect(() => {
    // close incoming call box after 30 sec 
    if (inCommingCall) {
      inCommingCallTimeout = setTimeout(() => {
        setInCommingCall(false);
        ringtoneRef.current?.pause();
        if (ringtoneRef.current) ringtoneRef.current.currentTime = 0;
        sessionIdRef.current = null;
        inCommingCallTimeout = null;
      }, ringtoneTimeout);
    }

    // close call to assistant box after 30 sec 
    if (CallToAssistant.open) {
      callToAssistantRef.current = CallToAssistant;
      CallToAssistantTimeout = setTimeout(() => {
        setCallToAssistant({ open: false, UserID: null, AssistantName: "", });
        callerToneRef.current?.pause();
        if (callerToneRef.current) callerToneRef.current.currentTime = 0;
        callToAssistantRef.current = { open: false, UserID: null, AssistantName: '' };
        socketRef.current?.emit(MISSED_CALL, sessionIdRef.current);
        sessionIdRef.current = null;
        CallToAssistantTimeout = null;
      }, ringtoneTimeout);
    }
  }, [inCommingCall, CallToAssistant]);

  // ANSWER INCOMING CALL
  const handleAnswerCall = () => {
    ringtoneRef.current?.pause();
    if (ringtoneRef.current) ringtoneRef.current.currentTime = 0;
    socketRef.current?.emit(ANSWER_SUCCESS, sessionIdRef.current);
    clearTimeout(inCommingCallTimeout);
    navigate("/call", { state: { assistantName } });
  };

  // REJWCT INCOMING CALL
  const handleRejectCall = () => {
    ringtoneRef.current?.pause();
    if (ringtoneRef.current) ringtoneRef.current.currentTime = 0;
    socketRef.current?.emit(ANSWER_REJECT, { sessionId: sessionIdRef.current, userName: user.username });
    sessionIdRef.current = null;
    setInCommingCall(false);
    clearTimeout(inCommingCallTimeout);
  };

  // END INITIATED CALL FROM CUNSULTANT
  // const handleCloseCallToAssistantBox = () => {
  //   if (!sessionIdRef.current) return;
  //   setCallToAssistant({ open: false, UserID: null, AssistantName: "" });
  //   clearTimeout(CallToAssistantTimeout);
  //   callToAssistantRef.current = { open: false, UserID: null, AssistantName: '' };
  //   socketRef.current?.emit(ANSWER_REJECT, sessionIdRef.current);
  //   callerToneRef.current?.pause();
  //   if (callerToneRef.current) callerToneRef.current.currentTime = 0;
  //   sessionIdRef.current = null;
  // };

  const handleCloseCallToAssistantBox = () => {
    if (!sessionIdRef.current) return;
    let assistantUserId = CallToAssistant.UserID;
    setCallToAssistant({ open: false, UserID: null, AssistantName: "" });
    clearTimeout(CallToAssistantTimeout);
    callToAssistantRef.current = { open: false, UserID: null, AssistantName: '' };
    socketRef.current?.emit(END_OUTGOING_CALL, { sessionId: sessionIdRef.current, emittedUserId: assistantUserId, emitterUserId: user.userId });
    callerToneRef.current?.pause();
    if (callerToneRef.current) callerToneRef.current.currentTime = 0;
    sessionIdRef.current = null;
  };

  const handleAcceptReconnectCall = () => {
    navigate('/call', { state: { assistantName } });
    socketRef.current?.emit(RESEND_ROKID_RESOLUTION, sessionIdRef.current);
    setReconnectDialog(false);
  }

  const handleDismissReconnectCall = () => {
    const assistantName = sessionStorage.getItem("assistantUserName");
    socketRef.current?.emit(END_CALL, { sessionData: sessionIdRef.current, assistantName, callEndUserRole: ROLE.Consultant });
    sessionStorage.removeItem('assistantUserName');
    setReconnectDialog(false);
  }

  // INCOMING CALL POP UP
  const openIncomingCallBox = () => {
    return (
      <CadisDialog
        open={inCommingCall}
        title={'Incoming call'}
        assistantName={assistantName}
        DialogActions={
          <>
            <Button variant="contained" color="error" onClick={handleRejectCall} >
              Reject
            </Button>
            <Button variant="contained" color='success' onClick={handleAnswerCall} >
              Accept
            </Button>
          </>
        } />
    );
  };

  // CALL TO ASSISTANT POP UP
  const openCallToAssistantBox = () => {
    return (
      <CadisDialog
        open={CallToAssistant.open}
        title={'Calling'}
        assistantName={CallToAssistant.AssistantName}
        DialogActions={
          <Button variant="contained" color="error" onClick={handleCloseCallToAssistantBox} >
            End Call
          </Button>
        } />
    )
  };

  const openReconnectDialog = () => {
    return (
      <CadisDialog
        open={reconnectDialog}
        title={t("lbl_wantToJoinCall")}
        assistantName={`${t("lbl_callInProgress")} ${assistantName}.`}
        DialogActions={
          <>
            <Button variant="contained" color="error" onClick={handleDismissReconnectCall} >
              {t("lbl_endCallBtn")}
            </Button>
            <Button variant="contained" color="success" onClick={handleAcceptReconnectCall} >
              {t("lbl_joinCall")}
            </Button>
          </>
        } />
    );
  }

  const handleCloseInviteDialog = () => {
    setEmailAddress('');
    setPhoneNumber('')
    setPhoneNumberErrorMsg('');
    setSelfEmailAddress('');
    setIsNumberValid(true);
    setIsValid(true);
    setIsEmailValid(true);
    setSelectedOption("SMS");
    setInviteDialog(false);
  };

  const handleEmailChange = (e: any) => {
    setEmailAddress(e.target.value);
    // Clear the validation error when the user types a new email
    setIsValid(true);
  };

  const handleSelfEmailChange = (e: any) => {
    setSelfEmailAddress(e.target.value);
    setIsEmailValid(true);
  }

  const handleEmailBlur = () => {
    setIsValid(isValidEmail(emailAddress));
  };

  const handleSelfEmailBlur = () => {
    setIsEmailValid(isValidEmail(selfEmailAddress));
  };


  const handlePhoneNumberBlur = () => {
    setIsNumberValid(ValidatePhoneNumber(phoneNumber))
  }

  const handlePhoneNumberChange = (e: any) => {
    setPhoneNumber(e.target.value);
    setIsNumberValid(true);
  }

  const ValidatePhoneNumber = (phoneNumber: string) => {
    let isPhoneNumberValid = true;
    setPhoneNumberErrorMsg('');
    const phoneNumberFirstChar = phoneNumber.charAt(0);
    const phoneNumberlastChar = phoneNumber.charAt(phoneNumber.length - 1);
    if (phoneNumber === '') {
      setPhoneNumberErrorMsg("validation.msg_phoneNumberRequired");
      isPhoneNumberValid = false;
    } else if (phoneNumberFirstChar === "-" || phoneNumberlastChar === "-") {
      setPhoneNumberErrorMsg(phoneNumberStartEndHyphenValidationMessage);
      isPhoneNumberValid = false;
    } else if (!numberAndDashRegex.test(phoneNumber)) {
      setPhoneNumberErrorMsg("validation.msg_invalidNumber");
      isPhoneNumberValid = false;
    } else {
      const phoneNumberTextWithoutHyphens = phoneNumber.replace(/-/g, "");
      if (phoneNumberTextWithoutHyphens.length < minPhoneLength || phoneNumberTextWithoutHyphens.length > maxPhoneLength) {
        setPhoneNumberErrorMsg(phoneNumberLengthValidationMessage);
        isPhoneNumberValid = false;
      }
    }
    return isPhoneNumberValid;
  }

  const handleSendInvite = async () => {
    if (selectedOption === 'SMS') {
      if (!ValidatePhoneNumber(phoneNumber)) {
        setIsNumberValid(false);
        return;
      }
    } else {
      if (selectedOption === 'Email') {
        if (!isValidEmail(emailAddress)) {
          setIsValid(false);
          return;
        }
      }
      else {
        if (!isValidEmail(selfEmailAddress)) {
          setIsEmailValid(false);
          return;
        }
      }
    }

    let roomLink = currentHost + "/webAssistantJoin/";

    setLoading(true);
    if (selectedOption === 'Email') {
      const data = {
        "wastEmail": emailAddress,
        "roomLink": roomLink,
        "sessionId": sessionIdRef.current
      }
      try {
        const response = await apis.instance.post(`${SendRoomInviteLink}`, data)
        if (response.data?.success) {
          toast.success("Invitation sent successfully.");
          setInviteDialog(false);
          setEmailAddress('');
          setSelfEmailAddress('');
          setLoading(false);
          setSelectedOption("SMS");
          setWastInviteSent(true);
        }
      } catch (error: any) {
        toast.error(error.message);
        setLoading(false);
      }
    } else if (selectedOption === 'Self') {
      const data = {
        "consultantEmail": selfEmailAddress,
        "consultantUserId": user?.userId,
        "roomLink": roomLink,
        "sessionId": sessionIdRef.current
      }
      try {
        const response = await apis.instance.post(`${SendRoomInviteLinkToSelf}`, data)
        if (response.data?.success) {
          toast.success("Invitation sent successfully.");
          setInviteDialog(false);
          setEmailAddress('');
          setLoading(false);
          setSelectedOption("SMS");
          setSelfEmailAddress('');
        }
      } catch (error: any) {
        toast.error(error.message);
        setLoading(false);
      }
    } else {
      const data = {
        "phoneNumber": phoneNumber,
        "roomLink": roomLink,
        "sessionId": sessionIdRef.current
      }
      try {
        const response = await apis.instance.post(`${SendRoomInviteLinkByMessage}`, data)
        if (response.data?.success) {
          toast.success("Invitation sent successfully.");
          setInviteDialog(false);
          setPhoneNumber('');
          setLoading(false);
          setSelectedOption("SMS");
          setWastInviteSent(true);
        }
      } catch (error: any) {
        toast.error(error.message);
        setLoading(false);
      }
    }
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleRadioChange = (event: any) => {
    setPhoneNumber('');
    setEmailAddress('');
    setIsNumberValid(true);
    setIsValid(true);
    setIsEmailValid(true);
    setSelectedOption(event.target.value);
  };

  const openInviteDialog = () => {
    return (
      <Dialog
        open={inviteDialog && isConferenceCall}
        onClose={() => { return null }}
        aria-labelledby="invite-dialog-title"
        aria-describedby="invite-dialog-description">
        <DialogTitle id="invite-dialog-title" >
          {t('lbl_invitePeople')}
        </DialogTitle>
        <DialogContent>
          <FormControl variant="outlined" fullWidth>
            <RadioGroup
              aaria-labelledby="Invite-radio-buttons-group-label"
              value={selectedOption}
              onChange={handleRadioChange}
            >
              <FormControlLabel value="SMS" control={<Radio />} label="Invite through SMS" sx={{ mt: 2 }} />
              {selectedOption === 'SMS' &&
                <CadisTextField
                  id="phoneNumber"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  onBlur={handlePhoneNumberBlur}
                  placeholder="Enter Phone Number"
                  error={!isNumberValid}
                  helperText={!isNumberValid ? t(phoneNumberErrorMsg) : ''}
                  sx={{ mb: 2 }}
                />
              }
              <FormControlLabel value="Email" control={<Radio />} label="Invite through Email" />
              {selectedOption === 'Email' &&
                <CadisTextField
                  id="inviteEmail"
                  name="inviteEmail"
                  value={emailAddress}
                  onChange={handleEmailChange}
                  onBlur={handleEmailBlur}
                  error={!isValid}
                  helperText={!isValid && selectedOption === 'Email' ? 'Invalid email address' : ''}
                  placeholder="Enter Email"
                  sx={{ mb: 2 }}
                />
              }
              <FormControlLabel value="Self" control={<Radio />} label="Send sharable link to yourself" />
              {selectedOption === 'Self' &&
                <CadisTextField
                  id="selfEmail"
                  name="selfEmailAddress"
                  value={selfEmailAddress}
                  onChange={handleSelfEmailChange}
                  onBlur={handleSelfEmailBlur}
                  error={!isEmailValid}
                  helperText={!isEmailValid && selectedOption === 'Self' ? 'Invalid email address' : ''}
                  placeholder="Self Email"
                  sx={{ mb: 2 }}
                />
              }
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <>
            <Button variant="contained" color="success" onClick={handleSendInvite} disabled={loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
              {t('lbl_invite')}
            </Button>
            <Button variant="contained" color="error" onClick={handleCloseInviteDialog}>
              {t('lbl_cancel')}
            </Button>
          </>
        </DialogActions>
      </Dialog>
    )
  }

  const openRokidUserInviteDialog = () => {
    return (
      <>
        {rokidInviteDialog && <AssistantListForCalling />}
      </>
    )
  }

  const openEndCallPopup = () => {
    return (
      <Dialog
        open={endCallDialog}>
        <DialogTitle id="endcall-dialog-title" >
          {t("lbl_meetingHasEnded")}
        </DialogTitle>
        <DialogContent>
          {t("lbl_meetingEndedByHost")}
        </DialogContent>
        {/* Added formated btn here in case of enhancement */}
        {/* <DialogActions>
          <Button variant="contained" color="primary">
            Ok
          </Button>
        </DialogActions> */}
      </Dialog>
    )
  }

  // CLOSE SOCKET CONNECTION
  const handleCloseSocket = () => {
    socketRef.current?.off();
    socketRef.current?.close();
    socketRef.current = null;
  }

  return (
    <SocketContext.Provider
      value={{
        roomDetails,
        webAsstRoomDetails,
        janusURL,
        CallToAssistant,
        callToAssistantRef,
        callerToneRef,
        ringtoneRef,
        onlineUsers,
        failedPong,
        pingIntervalRef,
        sessionIdRef,
        socketRef,
        isConferenceCall,
        rokidInviteDialog,
        roomData,
        isMuteConsultant,
        isMuteRokid,
        isWebAsstMuted,
        isNormalCall,
        wastInviteSent,
        rokidInviteSent,
        endCallDialog,
        setOnlineUsers,
        setCallToAssistant,
        openIncomingCallBox,
        openCallToAssistantBox,
        openReconnectDialog,
        setInCommingCall,
        setUserDetails,
        setJanusURL,
        setRoomDetails,
        setWebAsstRoomDetails,
        setInviteDialog,
        setIsConferenceCall,
        openInviteDialog,
        handleCloseSocket,
        openEndCallPopup,
        openRokidUserInviteDialog,
        setEndCallDialog,
        setRokidInviteDialog,
        setRoomData,
        SetIsMuteConsultant,
        SetIsMuteRokid,
        SetIsWebAsstMuted,
        setEmailAddress,
        setPhoneNumber,
        setIsNormalCall,
        setWastInviteSent,
        setSelfEmailAddress,
        setRokidInviteSent
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}

export { ContextProvider };
