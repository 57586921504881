import { Box, Checkbox, Container, Drawer, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { AssignAddonDataToPlan, GetAllAddonPackList } from '../../Common/api.routes';
import * as Helper from '../../Common/helper';
import { formateDecimalValues, isAuthenticated } from '../../Common/helperFunction';
import CadisButton from '../../Components/Input/CadisButton';
import CadisTextField from '../../Components/Input/CadisTextField';
import CadisLoader from '../../Components/Loading/CadisLoader';
import apis from '../../HttpConfig/Api';
import { IUpdateAddOnPack } from '../../Interfaces/ISubscription';

const AssignAddonPack = (
    { isAddonPackOpen, setIsAddonPackOpen, mode, selectedSubscriptionId, GetOrgSubscriptionPlanDetails }
        :
        {
            GetOrgSubscriptionPlanDetails: () => void,
            selectedSubscriptionId: any
            isAddonPackOpen: boolean,
            setIsAddonPackOpen: Dispatch<SetStateAction<boolean>>,
            mode?: string, addOnDetails?: IUpdateAddOnPack
        }) => {

    const [addOnPackList, setAddOnPackList] = useState<IUpdateAddOnPack[]>([{ addon_id: "Select Data Add-On", addon_data: "Select Data Add-On", addon_price: "" }]);
    const [loading, setLoading] = useState(false);
    const [isPromoSelected, setIsPromoSelected] = useState(false)
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        addonId: Yup.string().notOneOf(['Select Data Add-On'], (`${t("lbl_addOnData")} ${t("validation.msg_isRequired")}`))
            .required(`${t("lbl_addOnData")} ${t("validation.msg_isRequired")}`),
        paymentMode: Yup.string().notOneOf(['Select Payment Mode'], ('validation.msg_paymentMode'))
    });

    const { values, handleReset, handleSubmit, touched, errors, setFieldValue, resetForm, handleChange } = useFormik({
        initialValues: {
            addonId: "Select Data Add-On",
            addonPackPrice: "",
            paymentMode: Helper.paymentMode[0],
            isInvoiceSend: false
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values: any, props: any) => {
            setLoading(true);
            AssignAddonDataPack();
        }
    });


    const getallAddonPackList = async () => {
        try {
            const addonList = await apis.instance.get(`${GetAllAddonPackList}`)
            if (addonList.data.success) {
                setAddOnPackList([...addOnPackList, ...addonList.data.data])
            } else {
                if (addonList.data.error == "Subscription addon details do not exist.") {
                    setAddOnPackList([]);
                }
            }
        } catch (error) {

        }
    };

    const AssignAddonDataPack = async () => {
        try {
            const AssignDataDetails = {
                "userId": isAuthenticated().userId,
                "subscriptionId": selectedSubscriptionId,
                "addonId": values.addonId,
                "isInvoiceSend": isPromoSelected ? false : values.isInvoiceSend,
                "paymentMode": values.paymentMode
            }
            const assignData = await apis.instance.post(`${AssignAddonDataToPlan}`, AssignDataDetails)
            if (assignData.data.success) {
                toast.success(t("responseMsg.msg_assignAddonSuccess"))
                setIsAddonPackOpen(false);
                GetOrgSubscriptionPlanDetails();
            } else {
                setIsAddonPackOpen(false)
                toast.success(assignData.data.response)
            }
        } catch (error) {
            setIsAddonPackOpen(false)
        }
    };

    const handleFormReset = (e: any) => {
        handleReset(e);
    };

    useEffect(() => {
        getallAddonPackList()
    }, []);

    return (
        <>
            <Drawer
                anchor='right'
                open={isAddonPackOpen}
                onClose={() => { return null }}
            >
                <Box role="presentation">
                    <Box sx={{ pt: 1.5, position: 'sticky', top: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }} >
                        <Container sx={{ alignItems: 'center', display: 'flex' }} disableGutters>
                            <Box sx={{ width: '40px', height: '40px', flexGrow: 1, display: { xs: 'flex', pr: 2 } }}>
                                <IconButton onClick={() => {
                                    resetForm()
                                    setIsAddonPackOpen(false)
                                }} sx={{}} className='c-pointer'>
                                    <span className='cad-close'></span>
                                </IconButton>
                            </Box>
                        </Container>
                        <Box sx={{ mb: 2, pb: 2, mt: 2, display: 'flex' }}>
                            <Typography className="mt-0 mb-0 slide-right-title" sx={(theme) => theme.typography.h5}>{t('lbl_assignAddOnForm')}</Typography>
                        </Box>
                    </Box>
                    <Grid container width={'100%'}>
                        <form onSubmit={handleSubmit} onReset={handleFormReset} style={{ width: '100%' }}>
                            <Stack spacing={2} direction={'column'} paddingX={'20px'}>
                                <FormControl variant="outlined" size="small">
                                    <label id="userTitleLabelID" className='form-label'>
                                        {t('lbl_data')} Pack
                                        <span className='mandatory-star'></span></label>
                                    <Select fullWidth
                                        labelId='userTitleLabelID'
                                        id="addonId"
                                        name='addonId'
                                        value={values.addonId}
                                        placeholder='Select Add-On Pack'
                                        onChange={(e) => {
                                            const selectedPack = addOnPackList.find((i) => i.addon_id === e.target.value)
                                            setFieldValue("addonPackPrice", selectedPack?.addon_price)
                                            handleChange(e)
                                        }}>
                                        {addOnPackList.map((i) => {
                                            return <MenuItem key={i.addon_id} value={i.addon_id}>
                                                {!isNaN(parseFloat(i.addon_data)) ? formateDecimalValues(parseFloat(i.addon_data)) + " GB" : i.addon_data}
                                            </MenuItem>
                                        })}
                                    </Select>
                                    {errors.addonId && touched.addonId ? (
                                        <Typography variant="caption" color="error">
                                            {t(errors.addonId)}
                                        </Typography>) : null}
                                </FormControl>
                                <FormControl>
                                    <label id="userTitleLabelID" className='form-label '>
                                        {t('lbl_totalAmount')}</label>
                                    <CadisTextField
                                        id="addonPackPrice"
                                        name="addonPackPrice"
                                        value={values.addonId !== "Select Data Add-On" ? parseFloat(values.addonPackPrice) : values.addonPackPrice}
                                        disabled
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" sx={{ fontSize: '1.2rem' }}>
                                                    <span className='cad-dollar'></span>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>

                                <FormControl variant="outlined" size="small">
                                    <label className={mode === Helper.FormUpdateMode ? 'form-label mui-disabled' : 'form-label'}>
                                        {t('lbl_paymentMode')}
                                        <span className='mandatory-star'></span>
                                    </label>
                                    <Select fullWidth
                                        labelId='PaymentMode'
                                        id='paymentMode'
                                        name='paymentMode'
                                        value={values.paymentMode}
                                        onChange={(e) => {
                                            if (e.target.value === "Promo") {
                                                setIsPromoSelected(true)
                                            } else {
                                                setIsPromoSelected(false)
                                            }
                                            handleChange(e)
                                        }}
                                    >
                                        {Helper.paymentModeForAddon.map((item, index) => { return <MenuItem value={item} key={index}>{item}</MenuItem> })}
                                    </Select>
                                    {errors.paymentMode && touched.paymentMode ? (
                                        <Typography variant="caption" color="error">
                                            {t(errors.paymentMode)}
                                        </Typography>) : null}
                                </FormControl>

                                {!isPromoSelected ? <FormControl >
                                    <FormControlLabel control={
                                        <Checkbox
                                            id='Invoice'
                                            name='isInvoiceSend'
                                            value={values.isInvoiceSend}
                                            checked={values.isInvoiceSend}
                                            onChange={(event: any, checked: boolean) => {
                                                setFieldValue("isInvoiceSend", checked);
                                            }}
                                        />
                                    } label={t('lbl_sendInvoicebyEmail')}
                                    />
                                </FormControl> : ""}
                            </Stack>
                            <Box sx={{ pt: 2, pb: 2, position: 'sticky', bottom: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }}>
                                <Grid container spacing={0} alignItems="center">
                                    <Grid item xs="auto" >
                                        <CadisButton type='submit' variant="contained" color='primary' endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
                                            {t('lbl_assign')}
                                        </CadisButton>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Box sx={{ pl: 1 }}>
                                            <CadisButton type='reset' variant="contained" color='error'>
                                                {`${t('lbl_clear')}`}
                                            </CadisButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    </Grid>
                </Box>
            </Drawer >
        </>
    )
}

export default AssignAddonPack